import React, { useMemo, useState } from 'react';
import { GMUser } from './user';
import { User, useAuth0 } from '@auth0/auth0-react';
import { currentLocationHref } from './helpers';
import log from 'loglevel';
import { AuthContext, type AuthContextType } from './context';
import { userStore } from './store';
import { useNetworkStatus } from './useNetworkStatus';
import { useDispatcher } from '../ui';
import { UserAuthenticationCommand, UserTokenCommand } from './command';

function convertAuth0User(user: User): GMUser {
    if (!user.sub) {
        throw new Error('user.sub is missing');
    }
    if (!user.email) {
        throw new Error('user.sub is missing');
    }
    return {
        sub: user.sub!,
        email: user.email!,
        picture: user.picture,
        picture_base64: undefined,
        cached_at: new Date().toISOString(),
    };
}

export function AuthContextViaAuth0Provider(props: { children?: React.ReactNode }) {
    const { user, isLoading, loginWithRedirect, logout, error, getAccessTokenSilently } = useAuth0();
    const [gmUser, setGmUser] = useState<GMUser | undefined>(undefined);
    const [online, setOnline] = useState<boolean>(navigator.onLine);
    const dispatch = useDispatcher();

    useNetworkStatus(setOnline);

    useMemo(async () => {
        if (user) {
            try {
                log.debug('updating user based on auth0', user);
                const gmUser = convertAuth0User(user);
                userStore.store(gmUser);
                setGmUser(gmUser);
                dispatch(new UserAuthenticationCommand(gmUser));

                const accessToken = await getAccessTokenSilently();
                dispatch(new UserTokenCommand({ accessToken }));
                // download and encode user picture
                // THIS DOES NOT WORK DUE TO CORS on avatar service, we need our own proxy endpoint
                // const picURL = gmUser.picture
                // if (picURL) {
                //   downloadImageAndConvertToBase64(picURL).then((base64)=>{
                //     if (gmUser && gmUser.picture === picURL) {
                //       setGmUser({...gmUser,picture_base64: base64})
                //     }
                //   }).catch((e)=>{
                //     log.warn('cannot download image',e)
                //   })
                // }
            } catch (e) {
                log.warn('cannot convert user data', e);
            }
        } else if (!isLoading && !error && online) {
            loginWithRedirect({ appState: { returnTo: currentLocationHref() } });
        } else {
            const cachedUser = userStore.load();
            if (cachedUser) {
                log.debug('loading cached user', cachedUser);
                setGmUser(cachedUser);
                dispatch(new UserAuthenticationCommand(cachedUser));
                dispatch(new UserTokenCommand(null));
            } else {
                dispatch(new UserAuthenticationCommand(null));
                dispatch(new UserTokenCommand(null));
            }
        }
    }, [user, dispatch, isLoading, online, error]);

    const contextValue: AuthContextType = {
        user: gmUser,
        isAuthenticated: Boolean(gmUser),
        online: online,
        isLoading,
        loginWithRedirect: async (returnTo: string) => {
            return loginWithRedirect({ appState: { returnTo } });
        },
        logout: async () => {
            return logout({ logoutParams: { returnTo: currentLocationHref() } }).then(() => {
                userStore.remove();
                setGmUser(undefined);
                dispatch(new UserAuthenticationCommand(null));
            });
        },
        error,
    };

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}
