import { Button } from "@mui/material";
import { schema } from "@grenton/gm-common";
import { useState } from "react";
import { InlineEditPane } from "../misc/InlineEditPane";
import { MethodParamsForm } from "@grenton/gm/ui/components/methodParamsForm";

type Props = {
    spec:schema.Method
    onSubmit: (params: Record<string,schema.PropertyValue>) => void
    onClose: () => void
}

export function MethodParamsPane({spec,onSubmit,onClose}: Props) {
    const [values,setValues] = useState<Record<string,schema.PropertyValue>>({})

    return <InlineEditPane onClose={onClose}>
        <MethodParamsForm params={spec.params||[]} values={values} onValueChange={(id,v) => setValues({...values,[id]:v})} />
        <Button variant="contained" onClick={()=>onSubmit(values)}>Call</Button>
    </InlineEditPane>
}

