import { ProjectDeviceModuleInstanceImpl } from '@grenton/gm-logic';
import { HWModuleImpl, HWCluImpl, HWObjectImpl } from '@grenton/gm-logic';
import { GIconName } from '@grenton/gm/ui/icons';

export const CLU_ACTION_ADD_OR_REMOVE = 'addOrRemove';

export type HWTreeItem = {
    id: string;
    label: string;
    icon: GIconName | null;
    sortKey: string;
    children?: HWTreeItem[];
    disabled?: boolean;
    data: HWTreeItemData;
};

export enum HWTreeItemType {
    CLUSTER = 0,
    CLU = 1,
    MODULE = 2,
    OBJECT = 3,
}

export interface HWClusterItemData {
    type: HWTreeItemType.CLUSTER;
    projectRevision: string;
    projectId: string;
    local: boolean;
}

export interface HWCluItemData {
    type: HWTreeItemType.CLU;
    clu: HWCluImpl;
}

export interface HWModuleItemData {
    type: HWTreeItemType.MODULE;
    module: HWModuleImpl;
    projModule?: ProjectDeviceModuleInstanceImpl;
    linking?: boolean;
}

export interface HWObjectItemData {
    type: HWTreeItemType.OBJECT;
    entity: HWObjectImpl;
    linkedTo: { id: string; label: string } | null;
}

export type HWTreeItemData = HWCluItemData | HWModuleItemData | HWClusterItemData | HWObjectItemData;
