import {Grow, Box, Stack, Typography, Divider, Button} from "@mui/material";
import {ObjectMultiSelection} from "./utils";
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';

export default function (props: { selection: ObjectMultiSelection, onCancel: () => void, onMultiselectEdit: () => void }) {
    const {selection, onMultiselectEdit, onCancel} = props
    return (
        <Grow in={selection.mode}>
            <Box sx={{
                display: 'flex',
                position: 'absolute',
                width: '100%',
                height: '100%',
                flexGrow: 2,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Stack alignItems="center">
                    <LibraryAddCheckOutlinedIcon sx={{fontSize: '6rem', color: '#999'}}/>
                    <Typography variant="caption" sx={{color: '#999'}}>SELECTED
                        OBJECTS: {selection.selected.length}</Typography>
                    <Box mt={1}/>
                    <Divider flexItem={true}/>
                    <Stack direction="row" sx={{marginTop: 1}}>
                        <Button onClick={onCancel}>Cancel</Button>
                        <Divider flexItem={true} orientation="vertical" sx={{margin: 1}}/>
                        <Button color="secondary" disabled={selection.empty} onClick={onMultiselectEdit}>Edit</Button>
                    </Stack>
                </Stack>
            </Box>
        </Grow>)
}
