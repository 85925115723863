import { Auth0Provider } from '@auth0/auth0-react';
import { currentLocationHref } from './helpers';
import { AuthContextViaAuth0Provider } from './auth0';

type Props = {
    children?: React.ReactNode;
    domain: string;
    clientId: string;
    audience: string;
};

export function GrentonAuthProvider({ children, domain, clientId, audience }: Props) {
    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            authorizeTimeoutInSeconds={60}
            httpTimeoutInSeconds={10}
            authorizationParams={{
                redirect_uri: currentLocationHref(),
                audience: audience,
                scope: 'openid profile email read:current_user update:current_user_metadata',
            }}
        >
            <AuthContextViaAuth0Provider>{children}</AuthContextViaAuth0Provider>
        </Auth0Provider>
    );
}
