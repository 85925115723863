import { Lists } from '@grenton/gm-common';
import { useState } from 'react';

export function useExpandedItems(initialExpandedItems: string[] = []) {
    const [expandedItems, setExpandedItems] = useState<string[]>(initialExpandedItems);

    const toggleExpandedItem: (itemId: string, expanded: boolean) => void = (itemId, expanded) => {
        const newExpandedItems = Lists.toggle(expandedItems, itemId, expanded);
        if (newExpandedItems !== expandedItems) {
            setExpandedItems(newExpandedItems);
        }
    };

    const collapseAllItems = () => setExpandedItems([]);

    return { expandedItems, toggleExpandedItem, collapseAllItems };
}
