import { FormControlLabel, Stack, Switch } from "@mui/material";
import { GSwitchBox } from "../components/GSwitchBox";

export function SwitchesDemo() {
    return (
        <Stack gap={1}>

        <FormControlLabel
            control={<GSwitchBox><Switch /></GSwitchBox>}
            label="Switch"
            labelPlacement="end"
        />

        <FormControlLabel
            control={<GSwitchBox><Switch disabled={true}/></GSwitchBox>}
            label="Disabled"
            labelPlacement="end"
        />            
        </Stack>
    );
}