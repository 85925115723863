import React, {useEffect, useState, useCallback} from "react";
import {useForm} from "react-hook-form";
import {Stack, Typography, useTheme} from "@mui/material";
import {ModalFullScreenMobile} from "@grenton/design-system";
import GMTextField from "../../../../../ui/controls/GMTextField";
import {AddTagCommand} from "@grenton/gm-logic";
import {RemoveTagCommand} from "@grenton/gm-logic";
import type {AddTagProps, AddTagInputs} from "./types";
import { useDispatcher } from "@grenton/gm/ui";

export function ModalTag({isOpen, onClose, category, tag}: AddTagProps): React.ReactElement {
    const theme = useTheme();
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
    const dispatch = useDispatcher();
    const {register, setValue, handleSubmit, reset, formState: {errors}} = useForm<AddTagInputs>({
        defaultValues: { value: tag || '' }
    });

    useEffect(() => {
        if (tag) setValue('value', tag);
    }, [tag, setValue]);

    const closeAndReset = useCallback(() => {
        reset();
        onClose();
        setIsConfirmDeleteOpen(false);
    }, [onClose, reset]);

    const onSubmit = handleSubmit(({value}) => {
        if (!category) return
        const commandData = {
            category: category.name,
            value,
            single: !category.multiple
        };

        dispatch(new AddTagCommand({form: commandData}));
        closeAndReset();
    });

    const onDelete = useCallback(() => {
        if (isConfirmDeleteOpen) {
            if (tag && category?.name) {
                dispatch(new RemoveTagCommand({form: {tag, categoryName: category.name}}));
            }
            closeAndReset();
        } else {
            setIsConfirmDeleteOpen(true);
        }
    }, [isConfirmDeleteOpen, tag, category, dispatch, closeAndReset]);

    return (
        <ModalFullScreenMobile
            isOpen={isOpen}
            onClose={closeAndReset}
            onSave={!tag ? onSubmit : undefined}
            onDelete={tag ? onDelete : undefined}
            title={tag ? "Edit Tag" : "Create New Tag"}
        >
            {isConfirmDeleteOpen ? (
                <Typography variant="h6" style={{textAlign: "center", paddingBottom: theme.spacing(2)}}>
                    Do you want to delete this tag?
                </Typography>
            ) : (
                <form onSubmit={onSubmit} style={{minWidth: "25rem"}}>
                    <Stack spacing={2}>
                        <GMTextField
                            size="small"
                            label="Name"
                            variant="outlined"
                            disabled={!!tag}
                            {...register('value', {required: "Value is required"})}
                            error={!!errors.value}
                            helperText={errors.value?.message}
                        />
                    </Stack>
                </form>
            )}
        </ModalFullScreenMobile>
    );
}
