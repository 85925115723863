import * as Sentry from '@sentry/react';
import appEnv from './app-env';

Sentry.init({
    enabled: appEnv.MODE !== 'development',
    dsn: 'https://e3c138c9ab44253472c7da55bf83fb05@o4507921613520896.ingest.de.sentry.io/4507921619746896',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.grenton\.cloud/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
});
