import type {ReactElement} from "react";
import {DeleteLanguageProps} from "./types";
import {Typography} from "@mui/material";
import {ModalFullScreenMobile} from "@grenton/design-system";

export function DeleteLanguage({isOpen, onDelete, onClose}: DeleteLanguageProps): ReactElement {

    return (
        <ModalFullScreenMobile
            isOpen={isOpen}
            onClose={onClose}
            onDelete={onDelete}
            title="Confirm">
          <Typography>Do you want to delete this language?</Typography>
        </ModalFullScreenMobile>
    );
}
