import React, { useContext } from 'react';
import { GMUser } from './user';

export type AuthContextType = {
    user?: GMUser;
    online: boolean;

    // copied over from useAuth0
    error?: Error;
    isAuthenticated: boolean;
    isLoading: boolean;
    loginWithRedirect: (returnTo: string) => Promise<void>;
    logout: () => Promise<void>;
};

export const AuthContext = React.createContext<AuthContextType>({
    online: navigator.onLine,
    isAuthenticated: false,
    isLoading: true,
    loginWithRedirect: async () => {},
    logout: async () => {},
});

export function useGrentonAuth() {
    return useContext(AuthContext);
}
