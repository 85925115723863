import { DialogContent, Button, Stack, Typography, Alert } from "@mui/material";
import { useState } from "react";
import GMTextField from "../ui/controls/GMTextField";
import { ProjectUserCredentials } from "@grenton/gm-common";
import { GDialog, GDialogActions, GDialogTitle } from "@grenton/design-system";

type Props = {
    open: boolean,
    onClose: () => void,
    onAuthorize: (credentials: ProjectUserCredentials) => void,
    errorMessage?: string
}
export function AuthorizeCluDialog({open,onClose,onAuthorize,errorMessage} : Props ) {

    const [credentials, setCredentials] = useState({ username: 'admin', password: '' })

    return <GDialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
        <GDialogTitle onClose={onClose}>Authorization Required</GDialogTitle>
        <DialogContent sx={{ minHeight: 100 }}>
            <Stack sx={{ width: '100%' }} direction="column" spacing={1} padding={1}>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <GMTextField value={credentials.username} onChange={e => setCredentials({ ...credentials, username: e.target.value?.trim() })} size="small" placeholder="Username" />
                <GMTextField value={credentials.password} onChange={e => setCredentials({ ...credentials, password: e.target.value?.trim() })} size="small" placeholder="Password" />
                <Typography variant="caption">Use credentials of local user created for configuration you are trying to import from CLU</Typography>
            </Stack>
        </DialogContent>
        <GDialogActions end={<Button color="primary" disabled={credentials.username.length === 0 || credentials.password.length === 0} onClick={() => onAuthorize(credentials)}>Import</Button>}/>
    </GDialog>
}
