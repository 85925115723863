import { ProjectObjectImpl } from '@grenton/gm-logic';
import type { ProjectTreeItem, ProjectTreeItemObjectData } from '@grenton/gm/ui/components/projectComponentTree2';
import { objectNode } from './objectNode';
import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';
import { MainTreeContext } from './treeContext';

type Props = {
    ctx: MainTreeContext;
    parentId: string;
    objects: ProjectObjectImpl[];
};

export function objectNodes({ parentId, objects, ctx }: Props): ProjectTreeItem<ProjectTreeItemObjectData>[] {
    return objects
        .map((object) =>
            objectNode({
                ctx,
                parentId,
                path: [object.uuid],
                object,
                showProtocolDetails: true,
                shortenLabel: false,
            }),
        )
        .sort(sortTreeNodes);
}
