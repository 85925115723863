import {useCallback, useEffect} from 'react';
import type {ReactElement} from 'react';
import {Stack, FormControlLabel, Checkbox} from '@mui/material';
import {useForm} from 'react-hook-form';
import {I18nLang} from '@grenton/gm-common';
import {i18nLangNameMap} from '../../..';
import type {EditLanguageProps} from './types';
import GMTextField from '../../../../../ui/controls/GMTextField';
import {ModalFullScreenMobile} from '@grenton/design-system';


export function EditLanguage({isOpen, onClose, onSubmitLanguageUser, language}: EditLanguageProps): ReactElement {
    const formMethods = useForm<I18nLang>({
        defaultValues: {
            code: language.code,
            isDefault: language.isDefault
        }
    });


    useEffect(() => {
        formMethods.setValue('code', language.code);
        formMethods.setValue('isDefault', language.isDefault);
        formMethods.reset({
            code: language.code,
            isDefault: language.isDefault
        });
    }, [language, formMethods]);


    const {register, handleSubmit, reset, formState: {errors}} = formMethods;


    const onSubmit = useCallback((form: I18nLang) => {
        const updatedLanguage = {
            code: form.code,
            isDefault: form.isDefault
        };
        onSubmitLanguageUser({updatedLanguage});
    }, [language, onSubmitLanguageUser]);

    const handleCancel = useCallback(() => {
        onClose();
        reset();
    }, [onClose, reset]);

    return (
        <ModalFullScreenMobile
            isOpen={isOpen}
            onClose={handleCancel}
            onSave={handleSubmit(onSubmit)}
            title={`Edit language`}
        >
            <form onSubmit={handleSubmit(onSubmit)} style={{minWidth: '25rem'}}>
                <Stack spacing={2}>
                    <GMTextField
                        size='small'
                        label='name'
                        defaultValue={i18nLangNameMap.get(language.code)}
                        disabled
                    />
                    <GMTextField
                        size='small'
                        label='code'
                        {...register('code')}
                        error={!!errors.code}
                        helperText={errors.code?.message}
                        disabled
                    />
                    <FormControlLabel
                        control={<Checkbox {...register('isDefault')} defaultChecked={language.isDefault} />}
                        label='default'
                    />
                </Stack>
            </form>
        </ModalFullScreenMobile>
    );
}
