import { Box, SxProps, Theme } from "@mui/material"

type Props = {
    children?: React.ReactNode
    value: any
    selected: any
    sx?:SxProps<Theme>
    unmountOnHide?: boolean,
    fullHeight?: boolean
}


/**
 * GTabPanel is intended to be used with Tabs.
 * it can be visible or hidden, depending on selected tab.
 * use unmountOnHide to override default behavior when hidden tabs are kept mounted
 * @param param0 
 * @returns 
 */
export function GTabPanel({children,value, selected, sx, unmountOnHide, fullHeight}: Props) {
    const visible = value === selected
    const overrides = fullHeight ? {height:'100%'} : {}
    const mounted = visible || !unmountOnHide
    return mounted ? <Box
        className="GTabPanel"
        sx={{...sx, ...overrides}}
        role="tabpanel"
        hidden={!visible}
      >
        {/* always render, just hide with display:none */}
        {children}
      </Box> : null
  }
