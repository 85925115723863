import { PropsWithChildren, createContext, useContext } from "react";
import { DISCOVERY_EMPTY, NetworkView } from "../project";
import { useService } from "../providers";
import { useSubjectState } from "@grenton/utils";

const NetworkViewContext = createContext<NetworkView>({discovery:DISCOVERY_EMPTY, networks:[], selectedNetworkId:null});

export function useNetworkView() {
    return useContext(NetworkViewContext)
}

export function NetworkContextProvider({children}:PropsWithChildren<{}>) {
    const {networkViewStore} = useService()    
    const [networkView] = useSubjectState(networkViewStore.provider)

    return <NetworkViewContext.Provider value={networkView}>{children}</NetworkViewContext.Provider>
}