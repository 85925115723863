import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { ReactElement } from "react";
import { Box } from "@mui/material";
import { ComponentDeleteCommand, ObjectEditCommand, ObjectEditForm, ObjectEditFormId, ObjectEditFormTab, convertEventImplToForm, convertFeatureImplToForm, convertMethodImplToForm, convertOutletImplToForm } from "@grenton/gm-logic";
import { ScriptWithContext } from "@grenton/gm-logic";
import {
    MainObjectTreePane,
    objectFormValidator,
    ObjectsEditContext,
    ObjectsEditModel,
} from "./components";
import type { MainObjectTreePaneProps, ObjectPropertiesFormMeta } from "./components";
import { filterAllowedFunctionalTypes } from "@grenton/gm-logic";
import { useObservable } from "../utils";
import { ObjectMultiSelection } from "./utils";
import { IdMap, Maps, notEmpty } from "@grenton/gm-common";
import MultiselectionSummary from "./MultiselectionSummary";
import ScriptEditor from "./ScriptEditor";
import { EditorToolbar } from "./Toolbar";
import { useDispatcher, useProject } from "@grenton/gm/ui";
import { useService } from "@grenton/gm/providers";
import { GSplitter, grentonColors, useContainerRef } from "@grenton/design-system";
import { PROPERTY_MANAGER_HANDLE_HEIGHT, ObjectEditDrawer } from "./components/objectEdit/ObjectEditDrawer";
import { DeleteObjectsDialog, ObjectDeleteChain, findObjectsToDelete } from "./components/deleteObjectsDialog";
import { useLibraryContext } from "../ui/library";
import { EditScriptCommand } from "./backend/edit-script-command";
import { SystemModelProvider } from "../runtime/SystemModelProvider";
import { ProjectTreeItemData, ProjectTreeItemType } from "../ui/components/projectComponentTree2";
import { getProjectTitle } from "./utils/getProjectTitle";

const MainObjectTreePane_memo = memo(MainObjectTreePane)

export function EditorPage(): ReactElement {

    const { editorController } = useService()
    const dispatcher = useDispatcher()
    const project = useProject();
    
    const [editedScriptRef] = useObservable(editorController.scriptContextRef)
    
    const editedScript: ScriptWithContext | null = useMemo(() => editedScriptRef ? ScriptWithContext.from(project, editedScriptRef) : null, [project, editedScriptRef])

    const selectedTag = useRef<string | undefined>()
    const {openLibrary} = useLibraryContext()
    const [selection, setSelection] = useState(new ObjectMultiSelection(false, {}));
    const titleProject = getProjectTitle(editedScript);
    const editingCode = editedScript !== null && !selection.mode
    const [editorAreaRef,setEditorAreaRef] = useContainerRef()
    const [objectsToDelete, setObjectsToDelete] = useState<ObjectDeleteChain[]>([])
    const [objectsEditModel, setObjectsEditModel] = useState<ObjectsEditModel>(new ObjectsEditModel(false, -1, {}))
    
    const onMainObjectTreePrimaryAction: MainObjectTreePaneProps['onPrimaryAction'] = useCallback((action) => {
        switch (action.type) {
            case 'method':
            case 'event': {
                dispatcher(new EditScriptCommand(action.data))
                break
            }
            case 'object' : {
                selectedTag.current = action.data.rootTag
                break
            }
        }
    },[dispatcher])


    // add something to blockly
    // TBD could be commands, but these actions are UI only
    const onSelectTargetObjectNodeClick = useCallback((data : ProjectTreeItemData, opts?: { set?: boolean }) => {
        if (!editedScript) return
        switch (data.type) {
            case ProjectTreeItemType.OBJECT: 
            case ProjectTreeItemType.METHOD:
            case ProjectTreeItemType.FEATURE:
            case ProjectTreeItemType.OUTLET: {
                editorController.addScriptlet(data.path, opts)
            }
        }
    },[editedScript])


    const onSelectionModeToggle = useCallback(() => {
        const sel = selection.toggleMode();
        const objects: IdMap<boolean> = {};
        if (sel.mode) {
            // close props
            setObjectsEditModel(objectsEditModel.withOpen(false))

            // mark all object nodes as eligible for multiselection
            // todo doing it here means that new objects added to project during multi-select
            // won't be included
            Object.values(project.objects).forEach(obj => {
                objects[obj.uuid] = false
            })
        }
        setSelection(sel.withObjects(objects));
    },[selection])

    const editSingleObject = useCallback((objectId:string, focus?:{tab:ObjectEditFormTab, itemId?:string}):void => {

        let objectModel = objectsEditModel.getObjectModel([objectId])
        if (!objectModel) {

            const object = project.getObjectById(objectId)
            if (!object) return
  
            const isProtocolEditable = object.impl.type == "script" || object.impl.type == "external"

            const functionalTypes = (extending:string[])=>{
                const baseProtocols = isProtocolEditable ? extending.map(ref=>project.firmware.resolveApiRef({ref})).filter(notEmpty) : object.api.api
                const funcTypes = project.firmware.getFunctionalTypes(baseProtocols)
                return funcTypes.map(dt => dt.id)
                    .filter(filterAllowedFunctionalTypes(project, object.impl.componentRef))  
            }

            objectModel = {
                modified: false,
                meta: {
                    firmware: project.firmware,
                    protocol: {
                        id: object.api.api.id,
                        editable: isProtocolEditable
                    },
                    delete: object.impl.type === 'module' ? 'module' : 'object',
                    validator: objectFormValidator(label => (label !== object.label && project.hasObjectWithLabel(label))),
                    findFunctionalTypes: functionalTypes,
                    originName: object.label || object.uuid
                },
                form: {
                    disabled: { 
                        label: !object.top, 
                        tags: !object.top
                    },
                    id: [object.uuid],
                    selectedTab:'general',
                    tags: object.tags.selected,
                    name: object.label,
                    functionalType: object.userType,
                    // init: {
                    //     // ensure we have ALL init values
                    //     features: Maps.transform(object.api.api.flat.features, (_, f) => (new FeatureConfigImpl(f.id, firstDefined(object.init.features[f.id]?.value, f.default, null)))),
                    //     outlets: object.init.outlets
                    // },
                    extendingProtocols: object.api.api.extending.map(p => p.id),
                    apiItems: {
                        features: Maps.transform(object.api.features,(_,feature)=>convertFeatureImplToForm(isProtocolEditable, feature, false, object.init.features[feature.id])),
                        methods: Maps.transform(object.api.methods,(_,method)=>convertMethodImplToForm(isProtocolEditable, method)),
                        events: Maps.transform(object.api.events,(_,event)=>convertEventImplToForm(isProtocolEditable, event)),
                        outlets: Maps.transform(object.api.outlets, (_,outlet)=>convertOutletImplToForm(isProtocolEditable, outlet, false, object.init.outlets[outlet.id]))
                    }
                }
            }
        }

        if (focus) {
            let form = objectModel.form
            if (focus.itemId) {
                switch (focus.tab) {
                    case 'features': form = {...form, editedFeature: focus.itemId}; break
                    case 'methods': form = {...form, editedMethod: focus.itemId}; break
                    case 'events': form = {...form, editedEvent: focus.itemId}; break
                    case 'outlets': form = {...form, editedOutlet: focus.itemId}; break
                    case 'runtime': form = {...form, editedRuntime: focus.itemId}; break
                }
            }
            objectModel = {...objectModel, form:{...form, selectedTab:focus.tab}}
        }

        setObjectsEditModel(objectsEditModel.withObjectModel(objectModel).withOpen(true))
    },[project, objectsEditModel.objects])  // crazy optimization - objectEditModel changes whenever tab is changed, here we care only about list of edited objects

    function editMultipleObjects(ids:string[]): void {
        if (!ids.length) return
        if (ids.length === 1) {
            editSingleObject(ids[0]!)
            return
        }
        const selected = ids.toSorted()

        let objectModel = objectsEditModel.getObjectModel(selected)

        if (!objectModel) {
            const id = selected[0]!
            const object = project.getObjectById(id)
            if (!object) return
    

            const isProtocolEditable = object.impl.type == "script" || object.impl.type == "external"

            const functionalTypes = (extending:string[])=>{
                const baseProtocols = isProtocolEditable ? extending.map(ref=>project.firmware.resolveApiRef({ref})).filter(notEmpty) : object.api.api
                return project.firmware.getFunctionalTypes(baseProtocols)
                .map(dt => dt.id)
                .filter(filterAllowedFunctionalTypes(project, object.impl.componentRef))  
            }

            const meta:ObjectPropertiesFormMeta = {
                originName: `${selected.length} objects`,
                firmware: project.firmware,
                protocol: {
                    id: object.api.api.id,
                    editable: isProtocolEditable
                },
                delete: "none",
                validator: objectFormValidator(label => (label !== object.label && project.hasObjectWithLabel(label))),
                findFunctionalTypes: functionalTypes
            }
    
            const form:ObjectEditForm = {
                disabled: { 
                    label: true,
                    tags: !selected.some(id=>!project.getObjectById(id)!.top)
                },
                id: selected,
                selectedTab:'general',
                tags: [],
                name: '<multiselection>',
                functionalType: object.userType,
                extendingProtocols: object.api.api.extending.map(p => p.id),
                apiItems: {
                    features: Maps.transform(object.api.features,(_,feature)=>convertFeatureImplToForm(isProtocolEditable, feature, true)),
                    methods: Maps.transform(object.api.methods,(_,method)=>convertMethodImplToForm(isProtocolEditable, method)),
                    events: Maps.transform(object.api.events,(_,event)=>convertEventImplToForm(isProtocolEditable, event)),
                    outlets: Maps.transform(object.api.outlets, (_,outlet)=>convertOutletImplToForm(isProtocolEditable, outlet, true))
                }
            }
    
            objectModel = {
                modified: false,
                meta,
                form
            }
        }

        setObjectsEditModel(objectsEditModel.withObjectModel(objectModel).withOpen(true))

    }

    const onObjectUserChange = (form:ObjectEditForm, modified:boolean) => {
        setObjectsEditModel(objectsEditModel.withObjectForm(form).withFormState(form.id, modified))
    }

    const onObjectSaveChange = (id:ObjectEditFormId) => {
        const form = objectsEditModel.getObjectModel(id)?.form
        if (form) {
            dispatcher(new ObjectEditCommand({form}))
            setObjectsEditModel(objectsEditModel.withFormState(form.id,false).withModified(form.id,false))
        }
    }

    const onObjectDelete = (id:ObjectEditFormId) => {
        const toDelete = findObjectsToDelete(id,project)
        setObjectsToDelete(toDelete)
    }

    const doDeleteComponent = () => {
        if (objectsToDelete.length) {
            // ALL objects must have impl.componentRef.componentId (owner component) filled!
            const cmpId = objectsToDelete[0]!.root.impl.componentRef.componentId
            setObjectsToDelete([])
            dispatcher(new ComponentDeleteCommand(cmpId))
        }
    }

    // this is a harmful link between main tree and objectsEditModel
    // we need to memoize this callback, but objectsEditModel changes often (e.g. on tab change), so editSingleObject also changes
    const onMainTreeSecondaryAction: MainObjectTreePaneProps["onSecondaryAction"] = useCallback((action) => {
        switch (action.type) {
            case 'method': {
                // these nodes are only rendered for non-hardware objects
                editSingleObject(action.data.objectId, {tab:'methods', itemId:action.data.scriptRef.path})
                break;
            }
            case 'event': {
                editSingleObject(action.data.objectId, {tab:'events', itemId:action.data.scriptRef.path})
                break
            }
            case 'outlet' : {
                editSingleObject(action.data.objectId, {tab:'outlets', itemId:action.data.outletId})
                break
            }
            case 'object' : {
                editSingleObject(action.data.objectId)
                break
            }
        }
    },[editSingleObject])

    function toolbar(): ReactElement | null {
        if (!editedScript?.entity) return null
        return (
            <EditorToolbar
                resolver={project.objectResolver}
                editedScript={editedScript}
                onObjectEdit={editSingleObject}
                onOutletEdit={(objectId,outletId)=>editSingleObject(objectId, {tab:'outlets', itemId:outletId})}
                onMethodEdit={(objectId,methodId)=>editSingleObject(objectId, {tab:'methods', itemId:methodId})}
            />
        )
    }

    
    // filter out objects that were removed from the project
    useEffect(()=>{
        setObjectsEditModel(pm=>pm.ensureObjectsExist(id=>Boolean(project.getObjectById(id))))
    }, [project])

    const onOpenLibrary = useCallback(() => {
        openLibrary(selectedTag.current)
    },[selectedTag])

    return (
        <SystemModelProvider>
                <ObjectsEditContext.Provider value={objectsEditModel}>
                <DeleteObjectsDialog toDelete={objectsToDelete} onClose={doDeleteComponent} />

                <GSplitter minWidths={[220, 0]}
                    direction="horizontal"
                    initialSizes={[20,80]}
                >
                    <MainObjectTreePane_memo 
                        project={project}
                        selection={selection}
                        onSelectionChange={setSelection}
                        onSelectionModeToggle={onSelectionModeToggle}
                        onPrimaryAction={onMainObjectTreePrimaryAction}
                        onSecondaryAction={onMainTreeSecondaryAction}
                        onOpenLibrary={onOpenLibrary} />

                        <Box sx={{ height: '100%', position: 'relative' }}  ref={setEditorAreaRef}>

                        <Box sx={{
                                    background:grentonColors.backgrounds_menu,
                                    position:'absolute',
                                    top:0,left:0,right:0,
                                    bottom:objectsEditModel.hasObjects?PROPERTY_MANAGER_HANDLE_HEIGHT:0
                                }}>
                        <ScriptEditor
                            project={project}
                            selfObjectID={editedScript?.getSelfObjectID()}
                            hide={!editingCode}
                            onLogicNodeClick={onSelectTargetObjectNodeClick}
                            controller={editorController}
                            title={titleProject}
                            toolbar={
                                toolbar()
                            }
                        />

                        <MultiselectionSummary
                            selection={selection}
                            onMultiselectEdit={()=>{
                                editMultipleObjects(selection.selected)
                                setSelection(selection.withMode(false))
                            }}
                            onCancel={() => setSelection(selection.withMode(false))} />

                        <ObjectEditDrawer 
                            height={"80%"} 
                            withVisibleHandle={objectsEditModel.hasObjects}
                            container={editorAreaRef} 
                            selectedTab={objectsEditModel.selectedTab}
                            objectsEditModel={objectsEditModel}
                            onObjectFormChange={onObjectUserChange}
                            onObjectFormClose={oid=>setObjectsEditModel(pm=>pm.withoutObject(oid))}
                            onObjectFormDelete={onObjectDelete}
                            onTabsClick={()=>setObjectsEditModel(pm=>pm.hasSelectedTab ? pm.withOpen(true) : pm)}
                            onTabSelect={(tab)=>setObjectsEditModel(pm=>pm.withSelectedTab(tab).withOpen(true))}
                            onDrawerClose={()=>setObjectsEditModel(pm=>pm.withOpen(false))} 
                            onObjectFormSave={onObjectSaveChange}
                            />                    
                    </Box>
                    </Box>
                </GSplitter>
                </ObjectsEditContext.Provider>
            </SystemModelProvider>

    )
}
