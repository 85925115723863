import { I18nLangCodeEnum } from '@grenton/gm-common';

export const FORM_FIELD = {
    isDefault: {},
    code: {
        required: 'Code language is required',
        validate: (value: I18nLangCodeEnum) => {
            return Object.values(I18nLangCodeEnum).includes(value) || 'Invalid language code';
        },
    },
};
