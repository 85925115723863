export interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
    style?:any
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        style={{flexGrow:2,overflow:'auto'}}
        role="tabpanel"
        hidden={value !== index}
        className={value !== index ? 'hidden' : ''}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {/* always render, just hide with display:none */}
        {children}
      </div>
    );
  }

  export default TabPanel