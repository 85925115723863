import { createRoot } from 'react-dom/client';
import { ProviderWrapper } from './providers';
import { App } from './App';
import appEnv from './app-env';

import './sentry';

console.log(`Build ${appEnv.APP_VERSION} [${appEnv.MODE}]`);
const rootElement = document.getElementById('root');

if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
        <ProviderWrapper>
            <App />
        </ProviderWrapper>,
    );
} else {
    console.error('Root element not found');
}
