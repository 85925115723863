import {useState} from "react";
import type {ReactElement} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import GMTextField from "../../../../ui/controls/GMTextField";
import {connect} from "mqtt";
import log from "loglevel";
import { uuid } from "@grenton/gm-common";
import {formStore} from "./consts";
import type {MqttForm, ExportMqttDialogProps} from './types';
import { useService } from "@grenton/gm/providers";


/*
* this is only temporary and cannot be used in production UNLESS
* each node, not just cluster leader, runs mqtt broker.
* to form a new cluster we need to update each node in this cluster, potentially - new nodes
* that are not currently part of the cluster
*/

export function ExportMqttDialog({open, onClose}: ExportMqttDialogProps): ReactElement {

    const {projectExporter} = useService()
    const [form, setForm] = useState<MqttForm>(formStore.load())

    async function onPublish(): Promise<void> {
        formStore.save(form)


        const client = connect(form.url, {
            manualConnect: true,
            username: form.username,
            password: form.password,
            reconnectPeriod: 0,
            resubscribe: false,
            connectTimeout: 3000,

        })
        const timer = setTimeout(() => {
            end('timeout')
        }, 5000)

        function end(error?: string) {
            clearTimeout(timer)
            if (client.connected) {
                client.end()
            }
            if (error) {
                log.error('export failed', error)
            } else {
                log.info('export successful')
            }
        }

        client.on('connect', () => {
            log.info(`mqtt client connected to ${form.url}`)
            // do not put any 'on' handlers here - they will duplicate after reconnecting!
            projectExporter.exportProject({newVersion:true}).then(json => {
                const payload = {
                    type: 'rpc-request',
                    data: {
                        uuid: form.sn,
                        request: {
                            jsonrpc: "2.0",
                            id: uuid.v4(),
                            method: 'setConfiguration',
                            params: {
                                configuration: json
                            }
                        }
                    }
                }
                client.publish(form.topic, JSON.stringify(payload), {qos: 2}, (error?: Error, _packet?: unknown) => {
                    if (error) {
                        end(error.message || 'unknown mqtt error');
                    } else {
                        end();
                    }
                });
            })
        })
        client.on('message', (_msg, _payload) => {
        })

        try {
            client.connect()
        } catch (e) {
            end(e?.toString() || 'unknown connect error')
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Publish to mqtt</DialogTitle>
            <DialogContent>
                <Stack p={1} spacing={1}>
                    <GMTextField
                        size="small"
                        value={form.url} label="Broker URL"
                        onChange={e => setForm({...form, url: e.target.value})}
                    />
                    <GMTextField
                        size="small"
                        value={form.topic}
                        label="Topic"
                        onChange={e => setForm({...form, topic: e.target.value})}
                    />
                    <GMTextField
                        size="small"
                        value={form.username}
                        label="Username"
                        onChange={e => setForm({...form, username: e.target.value})}
                    />
                    <GMTextField
                        size="small"
                        value={form.password}
                        label="Password"
                        onChange={e => setForm({...form, password: e.target.value})}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onPublish()}>Publish</Button>
            </DialogActions>
        </Dialog>
    )
}
