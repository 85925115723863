import type {ReactElement} from "react";
import {useEffect} from 'react';
import {Dialog, DialogContent, DialogActions, Button, useMediaQuery, useTheme, Typography, Box} from "@mui/material";
import type {ModalFullScreenMobileProps} from "./types";

export function ModalFullScreenMobile({
                                          isOpen,
                                          onClose,
                                          onSave,
                                          onDelete,
                                          title,
                                          children
                                      }: ModalFullScreenMobileProps): ReactElement {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
    }, [onSave, onClose, isOpen]);

    return (
        <Dialog fullScreen={fullScreen} open={isOpen} onClose={onClose}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: theme.spacing(2)
            }}>
                <Typography variant="h5">{title}</Typography>
            </Box>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {onDelete && <Button onClick={onDelete}>Delete</Button>}
                {onSave && <Button onClick={onSave}>Save</Button>}
            </DialogActions>
        </Dialog>
    );
}
