import {PropsWithChildren, ReactNode, useEffect, useState, type ReactElement} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Button, DialogContent, Stack, SxProps, Typography} from "@mui/material";
import type {ExportPreviewDialogProps} from "./types";
import { useService } from "@grenton/gm/providers";
import { JsonPreview } from "./components";
import { GDialog, GDialogActions, GDialogTitle, grentonColors } from "@grenton/design-system";
import { ExportedProject, ProjectExportInfo, ProjectValidationResult } from "@grenton/gm-logic";
import { ExpandMore } from "@mui/icons-material";

export function ExportPreviewDialog({ open, onClose }: ExportPreviewDialogProps): ReactElement {

    const {projectExporter} = useService()

    const [exportedProject,setExportedProject] = useState<ExportedProject|null>(null)
    const [expandAll, setExpandAll] = useState(false)

    useEffect(()=>{
        if (open) {
            projectExporter.exportProject({validate:true, optimize:true}).then(setExportedProject)
        }
    }, [open])

    async function copyToClipboard(): Promise<void> {
        const projectJson = JSON.stringify(exportedProject?.project,null,2)
        navigator.clipboard.writeText(projectJson)
    }

    return (
        <GDialog fullHeight={true} maxWidth={"lg"} fullWidth={true} open={open} onClose={onClose}>
            <GDialogTitle onClose={onClose}>Configuration Preview</GDialogTitle>
            <DialogContent>
                {exportedProject && <ProjectValidationResultView info={exportedProject.info} result={exportedProject.validation}/>}
                {exportedProject && <JsonPreview expandAll={expandAll} json={exportedProject.project}/>}
            </DialogContent>
            <GDialogActions 
                    start={
                        <Button onClick={()=>setExpandAll(!expandAll)}>
                            Toggle expand
                        </Button>
                    }
                    end={
                    <Button autoFocus color="secondary" onClick={() => {
                        copyToClipboard();
                        onClose()
                    }}>
                        Copy to clipboard
                    </Button>}/>
        </GDialog>
    )
}

const sxMsg = {p:1, background:grentonColors.backgrounds_menu, borderRadius:'4px'}



function ProjectValidationResultView({result, info}:{result:ProjectValidationResult, info:ProjectExportInfo[]}) {
    if (!info.length && !result.errors.length && !result.warnings.length) return null
    return <CollapsiblePanel sx={{mb:1}} title={`info: ${info.length} errors: ${result.errors.length} warnings: ${result.warnings.length}`}>
        <Stack sx={{gap:1}}>
            {info.slice(0, 100).map((info, i) => <Typography key={i} color="" sx={sxMsg}>{info.message}</Typography>)}
            {result.errors.slice(0, 100).map((error, i) => <Typography key={i} color="red" sx={sxMsg}>{error.message}</Typography>)}
            {result.warnings.slice(0, 100).map((warning, i) => <Typography key={i} color="orange" sx={sxMsg}>{warning.message}</Typography>)}
        </Stack>
    </CollapsiblePanel>
}

function CollapsiblePanel({ title, children, sx }: PropsWithChildren<{ title: ReactNode, sx?:SxProps }>): ReactElement {
    return (
      <Accordion sx={sx}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    );
  }