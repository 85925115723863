import {isApiExtending} from "@grenton/gm-common";
import {objectNodes} from "./objectNodes";
import { ProjectTreeItemData, ProjectTreeItem, ProjectTreeItemType, ProjectTreeItemComponentData } from "@grenton/gm/ui/components/projectComponentTree2";
import { ProjectImpl, ProjectObjectImpl, sortTags } from "@grenton/gm-logic";
import { objectIconResolver } from "@grenton/gm/ui/icon-resolver/objectIconResolver";
import { encodeTreeItemId } from "@grenton/gm/editor/components/mainObjectTreePane/components/mainObjectTree/utils/id";
import { GTreeItemCheckboxState } from "@grenton/design-system";

type Props = {
    project: ProjectImpl;
    allowedApis: string[];
    tagCategory: string|null
};


export function objectSelectorTreeModel2({project, tagCategory, allowedApis}: Props): ProjectTreeItem<ProjectTreeItemData>[] {

    const iconResolver = objectIconResolver(project.firmware)

    const filterByAllowedApi = (obj: ProjectObjectImpl) => {
        for (const allowedApi of allowedApis) {
            if (isApiExtending(obj.api.api, allowedApi)) return true
        }

        return false
    }

    if (!tagCategory) {

        const byMod = project.topObjects
            .filter(o=>o.impl.type==='module')
            .reduce((acc, object) => {
            const id = object.impl.componentRef.componentId || ''
            return {...acc, [id]: (acc[id] || []).concat([object])}
        }, {} as { [mid: string]: ProjectObjectImpl[] })

        const modNodes = project.modules.filter(mod=>mod.type === 'module').map((mod) => {
            const sortKey = `${mod.ref} (${mod.uuid.substring(0, 8)})`
            const id = encodeTreeItemId(`mod:${mod.uuid}`)
            const item:ProjectTreeItem<ProjectTreeItemComponentData,ProjectTreeItemData>= {
                id,
                label: id,
                icon: null,
                sortKey,
                data: {
                    type: ProjectTreeItemType.MODULE,
                    module: {
                        id: mod.uuid,
                        ref: mod.ref,
                        type: mod.type,
                    }
                },
                children: objectNodes(id, byMod[mod.uuid] || [], filterByAllowedApi, iconResolver, project.objectResolver)
            }
            return item
        })
        const nonMods = objectNodes(encodeTreeItemId('mod:*'), 
            project.topObjects
            .filter(o=>o.impl.type!=='module'),
            filterByAllowedApi
            ,iconResolver,
        project.objectResolver)

        return [...modNodes, ...nonMods]
    } else {
        const tags = [...project.tags.byCategoryName(tagCategory), ""].sort(sortTags);

        return tags.map((tag: string) => {
            const id = encodeTreeItemId(`tag:${tag}`);
            const filteredObjects = project.topObjects.filter(object => 
                (tag && object.tags.includes(tag)) ||
                (!tag && object.tags.empty) || 
                Object.values(object.init.outlets || {}).some(outlet =>
                        outlet.staticRefs && outlet.staticRefs.some(staticRef =>
                            project.objects[staticRef]?.tags.selected.includes(tag)
                        )
                )
            )

            const children = objectNodes(id, filteredObjects, filterByAllowedApi, iconResolver, project.objectResolver)

            const tagValue = tag.split(':')[1] || `\u2217`
            return {
                id,
                label: tagValue,
                icon: null,
                sortKey: tagValue,
                data: {
                    type: ProjectTreeItemType.SPACE,
                    tag: tagValue
                },
                children,
                checkbox: GTreeItemCheckboxState.HIDDEN
            };
        });
    }
}

