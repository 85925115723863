import {ExpandMore, ExpandLess, AddCircleOutline} from "@mui/icons-material";
import { ProjectTreeItemType, ProjectTreeItemData, ProjectTreeItem } from "@grenton/gm/ui/components/projectComponentTree2";
import { GTreeItemRendererProps, ModuleLabel, TagLabel } from "@grenton/design-system";
import { IconConstructor, icons } from "@grenton/gm/ui/icons";
import React from "react";
import { ActionIconButton, CenterTreeItem, ObjectTreeItemContent, ObjectTreeItemRoot } from "../../../../../ui/components/objectTreeItem";


export function targetObjectTreeRenderer({item, onClick}: GTreeItemRendererProps<ProjectTreeItem<ProjectTreeItemData>>): JSX.Element {
    
    function content() {

        const data = item.data

        switch (data.type) {
            case ProjectTreeItemType.MODULE:
                return <ObjectTreeItemContent label={<ModuleLabel module={data.module}/>} hint={data.module.id}/>
            case ProjectTreeItemType.SPACE:
                return <CenterTreeItem align="start"><TagLabel label={data.tag} blank/></CenterTreeItem>
            case ProjectTreeItemType.MORE:
                return <CenterTreeItem align="center"><ExpandMore sx={{fontSize: 'inherit'}}/></CenterTreeItem>
            case ProjectTreeItemType.LESS:
                return <CenterTreeItem align="center"><ExpandLess sx={{fontSize: 'inherit'}}/></CenterTreeItem>              
            case ProjectTreeItemType.METHOD:
            case ProjectTreeItemType.FEATURE:
            case ProjectTreeItemType.OUTLET:
                return <ObjectTreeItemContent 
                            label={item.label} 
                            action={<ActionIconButton icon={<AddCircleOutline/>} onClick={e=>onClick(e, 'action')}/>}/>
            case ProjectTreeItemType.OBJECT:
                return <ObjectTreeItemContent 
                            label={item.label} 
                            greyedOut={!data.assignedToRootTag} 
                            action={<ActionIconButton icon={<AddCircleOutline/>} onClick={e=>onClick(e, 'action')}/>}
                            />
            default:
                return <ObjectTreeItemContent label={item.label}/>
        }
    }


    let icon:React.ReactNode = null
    if (item.icon) {
        const ResolvedIcon:IconConstructor = icons[item.icon]
        icon = <ResolvedIcon fontSize="inherit"/>
    }

    return <ObjectTreeItemRoot icon={icon} onClick={onClick}>{content()}</ObjectTreeItemRoot>
}

