import { ReactElement } from 'react';
import { LayoutMain } from './layout';
import appEnv from './app-env';
import { GrentonAuthProvider } from './auth';

export function App(): ReactElement {
    return (
        <GrentonAuthProvider domain={appEnv.AUTH0_DOMAIN} clientId={appEnv.AUTH0_GM_CLIENT_ID} audience={appEnv.AUTH0_AUDIENCE}>
            <LayoutMain />
        </GrentonAuthProvider>
    );
}
