
import { Stack, Theme, Typography, useTheme } from "@mui/material";
import { grentonTypographyVariants } from "@grenton/design-system";
import { Variant } from "@mui/material/styles/createTypography";
import { useEffect, useRef, useState } from "react";
export function Palette() {
    const theme:Theme = useTheme()
    
    // show PaletteColors (light/main/dark) & Text (primary, secondary, disabled)
    return <Stack>
           { 
            Object.entries(theme.palette).filter(([_,value])=>(value.main !== undefined)).map(([key, value]) => {
               return <Stack key={key} direction={"row"} alignItems={"center"} gap={1}>
                   <div style={{width: "20px", height: "20px", backgroundColor: value.main}}></div>
                   <div>{key}</div>
                   <div>{value.main}</div>
               </Stack>
           })}

{ 
            Object.entries(theme.palette.text).map(([key, value]) => {
               return <Stack key={key} direction={"row"} alignItems={"center"} gap={1}>
                   <div style={{width: "20px", height: "20px", backgroundColor: value}}></div>
                   <div>text.{key}</div>
                   <div>{value}</div>
               </Stack>
           })}
    </Stack>
}

//const Variants:Variant[] = ["button" , "caption" , "h1" , "h2" , "h3" , "h4" , "h5" , "h6" , "subtitle1" , "subtitle2" , "body1" , "body2" , "overline"];
const Variants = grentonTypographyVariants

export function TypographyStyles() {
    

    return <Stack>

        
           { Variants.map(v=>(<TypographyVariant key={v} variant={v}/> ))}
    </Stack>
}

export function TypographyVariant({variant}:{variant:Variant}) {
    const theme:Theme = useTheme()
    const ref = useRef<HTMLSpanElement>(null)
    const [fontSize, setFontSize] = useState<number>(0)

    useEffect(()=>{
        if (ref.current) {
            const style = window.getComputedStyle(ref.current, null).getPropertyValue('font-size');
            setFontSize(parseFloat(style))
        }
    }, [ref.current])

    return <Stack>
        <Typography ref={ref} sx={{border:'1px solid #f0f0f0'}} variant={variant}>{variant}</Typography>
        <div style={{fontSize:11}}>
            <span>real font size: {fontSize}px</span>

            {Object.entries(theme.typography[variant]).map((style=>{return `${style[0]}: ${style[1]}`})).join(' ;')}
        </div>
    </Stack>
}