import './css/grenton-icons-v1.2.css';
import React from 'react';

interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
  name: string;
  size?: number | string;
  color?: string;
}

export const GrentonIcon: React.FC<IconProps> = ({ 
  name, 
  size = 'inherit', 
  color = 'inherit', 
  style, 
  ...props 
}) => {
  const iconStyle: React.CSSProperties = {
    ...style,
    fontSize: size,
    color: color,
    fontWeight:400
  };

  return (
    <span 
      className={`ico ${name}`} 
      style={iconStyle} 
      {...props} 
    />
  );
};

export default GrentonIcon;
