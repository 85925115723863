import { useEffect, useState } from "react";
import { CertificateInstallWizardDialog } from "./CertificateInstallWizardDialog";
import { useService } from "../../providers";
import { filter } from "rxjs";

export function CertificateInstallWizard() {
    const [certificateWizardOpen, setCertificateWizardOpen] = useState(false)
    const {certificateInstallerStore} = useService()
    useEffect(()=>{
        const sub = certificateInstallerStore.provider.pipe(filter(s=>s!==null)).subscribe(()=>setCertificateWizardOpen(true))
        return ()=>sub.unsubscribe()
    },[])

    return <CertificateInstallWizardDialog open={certificateWizardOpen} 
        onClose={
            ()=>setCertificateWizardOpen(false)
        }/>
}