import { Box, Stack } from "@mui/material"
import { grentonColors } from "../../../theme2"
import { sizeRem, sizes } from "../../../theme2/size"

export function ModuleLabel(props: { module: { ref: string, id?: string } }) {
    const { module } = props

    return (
        <Stack direction={"row"} sx={{
            gap: 1,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: sizeRem(sizes.S),
        }}>
            <Box sx={{
                background: '#fff', //grentonColors.LabelGreyBackground,
                border:`1px solid ${grentonColors.Line_ButtonQuaternary}`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: grentonColors.Font_Black,
                minWidth: '4em',
                borderRadius: '0.33em',

                padding: '0.33em 1em',
                textAlign: 'center'
            }}>
                {module.ref}
            </Box>
            {/* rendered as 'hint' by tree item {module.id ? <Box sx={{ color: grentonColors.Font_40 }}>
                {module.id}
            </Box> : null} */}
        </Stack>
    )
}
