import { GSideDialog, GDialogTitle } from "@grenton/design-system";
import { DialogContent, MenuItem, MenuList } from "@mui/material";

type Props = {
    open: boolean;
    onClose: () => void;
    onSelect: (protocol: string) => void;
    protocols:string[]
    selected?: string|null;
    title:string
}

export function SideDialogProtocolSelection({open,onClose,onSelect,protocols,selected,title}:Props) {
    return <GSideDialog open={open} onClose={onClose}>
        <GDialogTitle onClose={onClose}>{title}</GDialogTitle>
        <DialogContent>
            <MenuList variant="selectedMenu" autoFocusItem>
                {protocols.map((protocol) => (<MenuItem autoFocus={protocol === selected} key={protocol} value={protocol} onClick={() => onSelect(protocol)}>{protocol}</MenuItem>))}
            </MenuList>
        </DialogContent>
    </GSideDialog>
}