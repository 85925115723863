import { ICollapsibleToolboxItem, IToolbox, registry, ToolboxCategory } from 'blockly';
import { CategoryInfo } from 'blockly/core/utils/toolbox';

/**
 * overrides standard category button to allow for filled background.
 * this may not be necessary with a recent version of Blockly which has enhanced css support
 */
class CustomToolboxCategoryItem extends ToolboxCategory {
    /**
     * Constructor for a custom category.
     * @override
     */
    constructor(categoryDef: CategoryInfo, parentToolbox: IToolbox, opt_parent?: ICollapsibleToolboxItem) {
        super(categoryDef, parentToolbox, opt_parent);
    }

    /** @override */
    addColourBorder_(color: string) {
        if (this.rowContents_) {
            this.rowContents_.style.backgroundColor = color;
        }
    }
}

registry.register(registry.Type.TOOLBOX_ITEM, ToolboxCategory.registrationName, CustomToolboxCategoryItem, true);
