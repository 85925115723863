import { AddCircleOutline, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Stack, List, ListItem, IconButton, ListItemButton, Box, Button } from "@mui/material";
import React from "react";

type Item = { id: string, selectable?: boolean, removeable?: boolean}

export type EditableListItemRendererProps<T extends Item> = {
    item: T;
    shrunk: boolean;
};

export type EditableListItemRenderer<T extends Item, P> = (props: EditableListItemRendererProps<T> & P) => React.ReactNode;


type Props<T extends Item, P={}> = {
    items: T[]
    onSelectItem: (id: string) => void
    onDeleteItem?: (id: string) => void
    selectedItem?: string
    renderer: EditableListItemRenderer<T,P>
    rendererProps? : P,
    shrunkWidth: number
    children?: React.ReactNode
    onNewItem?: () => void
}


export function EditableList<T extends Item,P={}>({ items, selectedItem, onSelectItem, onDeleteItem, onNewItem, renderer, rendererProps, shrunkWidth,children }: Props<T,P>) {

    const hasSelectedItem = Boolean(items.find(item => item.id === selectedItem && item.selectable))

    return <Stack sx={{ flexDirection: 'row', height: '100%', overflow: 'hidden', p: 1 }}>
        <List sx={{
            minWidth: shrunkWidth, 
            maxWidth: !hasSelectedItem ? 'auto' : shrunkWidth,
            flexGrow: hasSelectedItem ? 0 : 1,
            height: '100%',
            overflow: 'auto',
            "& .MuiListItemButton-root.Mui-selected": {
                backgroundColor: '#eee',
                //border:'1px solid #eee',
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                borderRight: 'none',
            }
        }}>
            {items.map((item) => (
                <ListItem disablePadding key={item.id} secondaryAction={
                    (item.selectable && (!hasSelectedItem || (selectedItem===item.id && item.removeable))) &&
                    <IconButton size="small" edge="end" onClick={() => selectedItem===item.id && item.removeable && onDeleteItem ? onDeleteItem(item.id) : onSelectItem(item.id)}>
                        {selectedItem===item.id && item.removeable ? <DeleteOutline /> : <EditOutlined />}
                    </IconButton>
                }>
                    <ListItemButton selected={item.selectable && item.id === selectedItem} onClick={() => item.selectable && onSelectItem(item.id)}>
                        {React.createElement(renderer,{...rendererProps, item, shrunk: hasSelectedItem} as any)}
                    </ListItemButton>
                </ListItem>))}
                {onNewItem && <ListItem sx={{justifyContent:'start',paddingRight:0.8}} >
                        <Button size="small" onClick={()=>onNewItem()} endIcon={<AddCircleOutline/>}>Add</Button>              
                </ListItem>}
        </List>
        {hasSelectedItem && <Box sx={{ 
            height: '100%',
            overflow: 'auto',
            display:'flex',
            p:1,
            flexGrow: 1,
            borderLeft: '1px solid #eee' }}>
            {children}
        </Box>}
    </Stack>
}