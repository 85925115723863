import { DialogActions } from "@mui/material";
import { GButtonBar, GButtonBarProps } from "./GButtonBar";

type Props = Omit<GButtonBarProps, 'fullWidth'>

/**
 * equivalent of GButtonBar for Dialog windows
 * @returns 
 */
export function GDialogActions({...props}: Props) {
    return <DialogActions sx={{p:0}}>
        <GButtonBar fullWidth {...props}/>
    </DialogActions>
}