// this works for Vite (but won't work for CRA or other bundlers)
// TBD we may have multiple certificates (dev/prod) and choose in runtime which one to download
// or bundle only one certificate
import certificateFile from '/certs/grenton-clu-dev-20240731.crt?url';


// TODO Windows+Edge - sometimes it doesn't work ('problems with network'),
// but copying a link to other tab works fine. is it because 'click' is not a user action?
// we can try to use href directly
export function downloadCertificateFile()  {
    // Create a link element
    const link = document.createElement('a');
    
    // Set the href to the path of your certificate file
    link.href = certificateFile;
    
    // Set the download attribute to suggest a filename
    link.download = 'grenton-clu-dev-20240731.crt';
    
    // Append the link to the body
    document.body.appendChild(link);
    
    // Programmatically click the link to trigger the download
    link.click();
    
    // Remove the link from the body
    document.body.removeChild(link);
};

export const certificateHref = certificateFile