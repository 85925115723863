import { Backdrop, Typography } from "@mui/material"
import { useContext } from "react"
import { SelectionContext } from "../selectionContext"
import { SelectedModule } from "../types"
import { grentonColors } from "@grenton/design-system"

type Props = {
    onClick: () => void,
    belongsTo: SelectedModule["belongsTo"]
}

export const PaneBackdropZIndex = 10

export function PaneBackdrop({onClick, belongsTo}: Props) {
    const selectedEntity = useContext(SelectionContext)
    return <Backdrop onClick={onClick} open={selectedEntity?.selection === 'module' && selectedEntity.belongsTo===belongsTo} 
    sx={{position:'absolute', zIndex:PaneBackdropZIndex}}>

        <Typography variant="l" color={grentonColors.Font_White}>Select a compatible module from the other panel</Typography>
    </Backdrop>
}