import { ProjectImpl } from '@grenton/gm-logic';
import { SelectedModule } from '../types';
import { Cluster, HWCluImpl, HWModuleImpl } from '@grenton/gm-logic';
import { HWTreeItemType, HWTreeItem } from './types';
import { Lists, Maps, ModuleObjectRef } from '@grenton/gm-common';

// TODO now the model is rebuilt on any selection change
// we could use context to keep selection and only activate renderer on selection change
// not sure if it is cleaner, but should be faster (#performance)
export function hardwareTreeModel(clusters: Cluster[], project: ProjectImpl, selectedModule?: SelectedModule, allowedType?: string): HWTreeItem[] {
    return clusters
        .filter((cluster) => Boolean(cluster.configuration.clus.length))
        .map((cluster) => ({
            highlight: true,
            id: `project-${cluster.clusterId}`,
            label: cluster.projectId,
            icon: 'project',
            sortKey: cluster.projectId,
            data: {
                type: HWTreeItemType.CLUSTER,
                projectId: cluster.projectId,
                projectRevision: cluster.projectRevision,
                local: cluster.local,
            },
            children: cluster.configuration.clus.map((clu) => cluNode(clu, project, selectedModule, allowedType)),
        }));
}

function cluNode(clu: HWCluImpl, project: ProjectImpl, selectedModule?: SelectedModule, allowedType?: string): HWTreeItem {
    return {
        id: `clu:${clu.id}`,
        label: clu.id,
        icon: 'clu',
        sortKey: clu.id,
        data: {
            type: HWTreeItemType.CLU,
            clu,
        },
        children: clu.imported ? [...moduleNodes(clu.modules, project, selectedModule, allowedType)] : [],
    };
}

function moduleNodes(modules: HWModuleImpl[], project: ProjectImpl, selectedModule?: SelectedModule, allowedType?: string): HWTreeItem[] {
    return modules.map((mod) => {
        const disabled = !!allowedType && allowedType !== mod.ref;
        const projModuleId = project.hardware.findVirtualMappedToHardware(mod.id);
        const projModule = projModuleId ? project.getModuleById(projModuleId) : undefined;
        const projObjects = projModuleId
            ? Lists.reduce(
                  project.getAllObjectsByComponent(projModuleId).filter((o) => o.top && o.impl.type === 'module'),
                  (o) => [(o.impl.componentRef as ModuleObjectRef).objectId!, o],
              )
            : {};
        const children: HWTreeItem[] = Maps.values(mod.objects).map((object) => {
            const projObject = projObjects[object.port];
            return {
                id: `${mod.id}${object.port}`,
                label: `${object.port}`,
                icon: 'connector',
                sortKey: object.port,
                disabled,
                data: {
                    type: HWTreeItemType.OBJECT,
                    entity: object,
                    linkedTo: projObject ? { id: projObject.uuid, label: projObject.label } : null,
                },
                children: [],
            };
        });

        const item: HWTreeItem = {
            id: mod.id,
            label: mod.id,
            icon: null, //'unknown',
            sortKey: `${mod.ref}/${mod.id}`,
            data: {
                type: HWTreeItemType.MODULE,
                module: mod,
                projModule,
                linking: selectedModule?.uuid == mod.id,
            },
            disabled,
            children,
        };
        return item;
    });
}
