import { grentonColors } from "@grenton/design-system";
import { EditModeActions, EditModeCode, EditModeVisual } from "@grenton/gm/ui/icons";
import { ScriptFormat } from "@grenton/gm-common";
import { ToggleButtonGroup, ToggleButton, } from "@mui/material";

type Props = {
    onChange: (mode: ScriptFormat) => void,
    active: ScriptFormat
    viewed: ScriptFormat
}

export function EditModeSelector({ viewed, active, onChange }: Props) {

    const btnSx = {
        //color: grentonColors.black['50%'],
        minWidth:50
    }

    const color = (mode: ScriptFormat) => mode === active ? {
        color:'#fff',
        background:grentonColors.accent['30%']
    } : {
        color:grentonColors.Font_Placeholder,
        //background:'red'
    }

    return <ToggleButtonGroup 
        color="white"
        size="small"
        exclusive
        value={viewed}
        onChange={(_, index) => onChange(index)}>
        <ToggleButton value="actions" sx={{...btnSx, ...color('actions')}}><EditModeActions/></ToggleButton>
        <ToggleButton value="visual" sx={{...btnSx, ...color('visual')}}><EditModeVisual/></ToggleButton>
        <ToggleButton value="lua" sx={{...btnSx, ...color('lua')}}><EditModeCode/></ToggleButton>
    </ToggleButtonGroup>
}