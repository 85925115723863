import { useSubjectState } from "@grenton/utils";
import { createContext, useContext } from "react";
import { useService } from "../providers";
import { NotificationState } from "../project";

export const NotificationContext = createContext<NotificationState|null>(null)

export function useNotifications() {
    return useContext(NotificationContext)
}

export function NotificationContextProvider({children}: {children: React.ReactNode}) {
    const {notificationStore} = useService()

    const [notifications] = useSubjectState(notificationStore.provider)

    return <NotificationContext.Provider value={notifications}>{children}</NotificationContext.Provider>
}