import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useCallback, useState } from "react";

export function CheckboxesDemo() {

    const [checkboxState,setCheckboxState] = useState(2)

    const onCheckboxClick = useCallback(()=>{
        setCheckboxState(v=>(v+1)%3)
    },[])

    return <Stack gap={1} alignItems={"start"}>

        <FormControlLabel
          control={<Checkbox />}
          label="Label"
          labelPlacement="end"
        />

        <FormControlLabel
          control={<Checkbox checked={true} disabled={true}/>}
          label="Disabled"
          labelPlacement="end"
        />

        <FormControlLabel
          control={<Checkbox onClick={onCheckboxClick} checked={checkboxState === 1} indeterminate={checkboxState === 2}/>}
          label="Indeterminate"
          labelPlacement="end"
        />
    </Stack>
}