import { Box } from "@mui/material";

type Props = {
    icon:React.ReactNode,
    children:React.ReactNode,
    onClick:(e:React.MouseEvent)=>void
}

export function ObjectTreeItemRoot({children, onClick, icon}: Props) {
    return <Box onClick={onClick} sx={{minWidth: 0, display: 'flex', gap:1, flexGrow: 2, alignItems: 'center'}}>
        {icon}
        {children}
    </Box>
}