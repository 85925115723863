import {ProjectImpl, ProjectObjectImpl, sortTags} from "@grenton/gm-logic";
import {objectNodes, objectNode} from './utils/';
import { ProjectTreeItemData, ProjectTreeItem, ProjectTreeItemType, ProjectTreeItemComponentData } from "@grenton/gm/ui/components/projectComponentTree2";
import { ObjectID } from "@grenton/gm-common";
import { objectIconResolver } from "@grenton/gm/ui/icon-resolver/objectIconResolver";

type Props = {
    project: ProjectImpl;
    editedObjectId?: ObjectID;
    actionsMode: boolean;
    tagCategory?: string;
};

export function targetObjectTreeModel({ project, editedObjectId, actionsMode, tagCategory }: Props): ProjectTreeItem<ProjectTreeItemData>[] {

    const iconResolver = objectIconResolver(project.firmware)
    const path: string[] = []
    let nodesByTag: ProjectTreeItem<ProjectTreeItemData>[]
    if (!tagCategory) {
        // for "module view", we display devices grouped by module they belong to, not tags
        // all standalone devices must land under single parent "<none>"
        // what about scripts???

        const byMod = project.topObjects
        .filter(o=>o.impl.type==='module')
        .reduce((acc, object) => {
            const id = object.impl.componentRef.componentId || ''
            return {...acc, [id]: (acc[id] || []).concat([object])}
        }, {} as { [mid: string]: ProjectObjectImpl[] })

        const modNodes = project.modules.filter(mod=>mod.type==='module').map((mod) => {
            const sortKey = `${mod.ref} (${mod.uuid})`
            const id = `mod:${mod.uuid}`
            const item:ProjectTreeItem<ProjectTreeItemComponentData,ProjectTreeItemData>= {
                id,
                label: mod.uuid,
                icon: null,
                sortKey,
                data: {
                    type: ProjectTreeItemType.MODULE,
                    module: {
                        id: mod.uuid,
                        ref: mod.ref,
                        type: mod.type,
                    }
                },
                children: objectNodes(id, [], byMod[mod.uuid] || [], actionsMode, iconResolver, project.objectResolver)
            }
            return item
        })
        const nonMods = objectNodes("mod:*", [], 
            project.topObjects.filter(o=>o.impl.type!=='module'),
            actionsMode, iconResolver, project.objectResolver)

        nodesByTag = [...modNodes, ...nonMods]
    } else {
        const tags = [...project.tags.byCategoryName(tagCategory), ""].sort(sortTags);

        return tags.map((tag: string) => {
            const id = `tag:${tag}`;
            const filteredObjects = project.topObjects.filter(object => 
                (tag &&object.tags.includes(tag)) || 
                (!tag && object.tags.empty) || 
                Object.values(object.init.outlets || {}).some(outlet =>
                        outlet.staticRefs && outlet.staticRefs.some(staticRef => {
                            const childObject = project.objects[staticRef];
                            return childObject?.tags.selected.includes(tag)
                        })
                )
            )

            const children = objectNodes(id, path, filteredObjects, actionsMode, iconResolver, project.objectResolver, tag);

            const tagValue = tag.split(':')[1] || `\u2217`
            return {
                id,
                label: tagValue,
                sortKey: tagValue,
                icon:null,
                data: {
                    type: ProjectTreeItemType.SPACE,
                    tag:tagValue
                },
                children
            };
        });
    }

    if (editedObjectId) {
        const self = project.getObjectById(editedObjectId)
        if (self) {
            const selfNode = objectNode("self",[editedObjectId], self, actionsMode, iconResolver, project.objectResolver)
            selfNode.id = 'self'
            selfNode.label = `${selfNode.label} (self)`
            nodesByTag.unshift(selfNode as any)
        }
    }
    return nodesByTag
}

