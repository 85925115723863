import type {ReactElement} from "react";
import {ArrowForward} from "@mui/icons-material";
import {Stack, Typography} from "@mui/material";
import {useObservable} from "../../utils";
import {ActionModeController} from "./action-editor-controller";
import Mask from "../../ui/components/Mask";
import {ActionListEditor} from "./ActionListEditor";


export function ActionModeEditor(props: {
    active: boolean,
    controller: ActionModeController
}): ReactElement {

    const [actionRows] = useObservable(props.controller.actionRows)
    return actionRows?.length || !props.active ?
        <ActionListEditor rows={actionRows || []}
                          iconResolver={props.controller.actionTargetIconResolver()}
                          onChangeType={(index, value) => props.controller.onChangeType(index, value)}
                          onDeleteAction={(index) => props.controller.onDeleteAction(index)}
                          onUpdateSpecificAction={(index, type, model) => props.controller.onUpdateSpecificAction(index, type, model)}/>
        : <Mask content={<Stack spacing={1} sx={{color: '#aaa'}} direction="row" alignItems="center"><Typography
            variant="h6">Choose objects</Typography><ArrowForward/> </Stack>}/>
}

