import { GIconName } from '@grenton/gm/ui/icons';

export type CFGTreeItem = {
    id: string;
    label: string;
    icon: GIconName | null;
    sortKey: string;
    children?: CFGTreeItem[];
    disabled?: boolean;
    data: CFGTreeItemData;
};

export enum CFGTreeItemType {
    COMPONENT = 1,
    OBJECT = 2,
}

export type CFGTreeItemComponentData = {
    type: CFGTreeItemType.COMPONENT;
    component: {
        id: string;
        ref: string;
    };
    linkedTo: string | null;
    linking: boolean;
};

export type CFGTreeItemObjectData = {
    type: CFGTreeItemType.OBJECT;
    objectId: string;
    port: string;
    moving: boolean;
};

export type CFGTreeItemData = CFGTreeItemObjectData | CFGTreeItemComponentData;
