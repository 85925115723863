import {objectSelectorTreeModel2} from './model';
import { filterProjectTreeItems, ProjectTreeFilterFunction, ProjectTreeItem, ProjectTreeItemData, ProjectTreeItemType, useExpandedItems } from "@grenton/gm/ui/components/projectComponentTree2";
import { KeyMap } from "@grenton/gm-common";
import { ProjectImpl } from "@grenton/gm-logic";
import { ProjectComponentTree2 } from "@grenton/gm/ui/components/projectComponentTree2";
import { outletObjectSelectorRenderer } from "./renderer";
import { GTreeItemCheckboxState } from '@grenton/design-system';
import { useMemo } from 'react';
import { parseTreeItemId } from '@grenton/gm/editor/components/mainObjectTreePane/components/mainObjectTree/utils/id';


export type Props = {
    project: ProjectImpl;
    selected: KeyMap;
    onItemSelectionToggle: (id: string, selected:boolean) => void;
    allowedApis: string[];  
    filter: ProjectTreeFilterFunction<ProjectTreeItemData>;
    tagCategory: string|null;
};

export function OutletObjectSelectorTree({project, selected, tagCategory, allowedApis, filter, onItemSelectionToggle}: Props) {

    const baseItems = useMemo(()=>objectSelectorTreeModel2({project, tagCategory, allowedApis}), [project,tagCategory,allowedApis])
    
    const filteredItems = useMemo(()=> filterProjectTreeItems(baseItems, filter), [baseItems, filter])

    const {expandedItems, toggleExpandedItem} = useExpandedItems(['tag:$$root', 'type:$$root'])

    const itemsSelection: {selectable:Record<string,GTreeItemCheckboxState>,selected:string[]} = useMemo(()=>{
        return findSelectedAndSelectables(filteredItems, selected)
    }, [filteredItems, selected])

    return (
        <ProjectComponentTree2
            items={filteredItems}
            selectionEnabled={true}
            expandedItems={expandedItems}
            onItemExpansionToggle={toggleExpandedItem}
            selectableItems={itemsSelection.selectable}
            selectedItems={itemsSelection.selected}
            itemRenderer={outletObjectSelectorRenderer} 
            onItemClick={()=>{}}
            onItemSelectionToggle={(itemId, selected) => {
                // due to way we encode itemIds, the last token is always the object id for OBJECT item
                const objectId = parseTreeItemId(itemId).pop()
                if (objectId) {
                    onItemSelectionToggle(objectId, selected)
                }
            }}
            />
    )

}

function findSelectedAndSelectables(
    items: ProjectTreeItem<ProjectTreeItemData>[], 
    multiSelection: KeyMap
) : {selectable:Record<string,GTreeItemCheckboxState>,selected:string[]} {

    let selectable:Record<string,GTreeItemCheckboxState> = {}
    const selected:string[] = []
    items.forEach(item=>{
        if (item.data.type === ProjectTreeItemType.OBJECT) {
            selectable[item.id] = item.data.active ? GTreeItemCheckboxState.ENABLED : GTreeItemCheckboxState.HIDDEN
            if (multiSelection[item.data.objectId]===true) {
                selected.push(item.id)
            }
        }

        if (item.children) {
            const r = findSelectedAndSelectables(item.children, multiSelection)
            selectable = {...selectable, ...r.selectable}
            selected.push(...r.selected)
        }
    })

    return {selectable,selected}
}