import { styled, Box, List as Li, ListItemButton } from "@mui/material";


export const Wrapper = styled(Box)(({  }) => ({
    height: "100%",
    display: 'grid',
    gridTemplateColumns: "15rem 1fr",
}));


export const List = styled(Li)(({ theme }) => ({
    width: "100%",
    borderRight: `1px solid ${theme.palette.grey.A400}`
}));

export const Button = styled(ListItemButton)(({ theme }) => ({
    width: "100%",
    gap: theme.spacing(2),
    justifyContent: 'flex-start',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));
