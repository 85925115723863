import {ReactElement, useEffect} from "react";
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Stack,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import {LanguageAccordionProps} from "./types";
import { TagLabel } from '@grenton/design-system';
import {i18nLangNameMap} from '../../..';
import { UserInfoField } from './components';
const stackStyle = { padding: 0, gap: 3 };

export function LanguageAccordion({ language, handleDeleteLanguage, handleEditLanguage }: LanguageAccordionProps): ReactElement | null {
    useEffect(( ) => {}, [language])
    const { code, isDefault } = language ?? {};

    if (!language) return null

    return (
        <Accordion square>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={code}
                id={code}
            >
                <TagLabel label={i18nLangNameMap.get(code)||'noname'} />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 4 }}>
                <Stack direction="column" alignItems="end" {...stackStyle}>
                    <form style={{ width: "100%" }}>
                        <Stack direction="column" alignItems="start" {...stackStyle}>
                            <UserInfoField label="code" value={code} />
                            <FormControlLabel
                                control={<Checkbox checked={isDefault} disabled />}
                                label="Default"
                                labelPlacement="end"
                                disabled
                            />
                        </Stack>
                    </form>
                    <Stack direction="row" alignItems="end" {...stackStyle}>
                        {handleDeleteLanguage && <IconButton onClick={() => handleDeleteLanguage(language)}>
                            <Delete/>
                        </IconButton>}
                        {handleEditLanguage && <IconButton onClick={() => handleEditLanguage(language)}>
                            <Edit/>
                        </IconButton>}
                    </Stack>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
