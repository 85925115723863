import {ReactElement} from "react";
import { Box } from "@mui/material";
import { grentonColors } from "@grenton/design-system";
import ReactJson from '@microlink/react-json-view'

export function JsonPreview({json, userSelect=true, expandAll}:{userSelect?:boolean,json:object,expandAll?:boolean}): ReactElement {
    return (
        <Box sx={{
            "& *" : userSelect ? {userSelect: 'text',
            MozUserSelect:'text',
            WebkitUserSelect:'text'
            } : {},
            background:grentonColors.backgrounds_menu,
            p:1,
            borderRadius:'4px'
        }}><ReactJson 
            src={json} 
            displayDataTypes={false}
            collapseStringsAfterLength={100}
            collapsed={expandAll ? false : 1} 
            enableClipboard={false}/>
        </Box>
    )
}