import { GSideDialog, GDialogTitle } from "@grenton/design-system";
import { Checkbox, DialogContent, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

type Props = {
    open: boolean;
    onClose: () => void;
    onToggle: (protocol:string, selected:boolean) => void;
    protocols:string[]
    selected?: string[];
    title:string
}

export function SideDialogMultiProtocolSelection({open,onClose,onToggle,protocols,selected,title}:Props) {
    return <GSideDialog open={open} onClose={onClose}>
        <GDialogTitle onClose={onClose}>{title}</GDialogTitle>
        <DialogContent>
            {/* <MenuList variant="selectedMenu" autoFocusItem>
                {protocols.map((protocol) => (<MenuItem autoFocus={protocol === selected} key={protocol} value={protocol} onClick={() => onSelect(protocol)}>{protocol}</MenuItem>))}
            </MenuList> */}

            <List sx={{maxWidth: 360}}>
            {protocols.map((protocol) => {
                const checked = Boolean(selected?.includes(protocol))
                const labelId = `checkbox-list-label-${protocol}`;
                return (
                    <ListItem
                        key={protocol}
                        disablePadding
                    >
                        <ListItemButton onClick={
                            () => onToggle(protocol, !checked)
                        } dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checked}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={protocol}/>
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
        </DialogContent>
    </GSideDialog>
}