import React, { Profiler, ProfilerOnRenderCallback } from "react";

const handleRender:ProfilerOnRenderCallback = (id, phase, actualDuration) => {
    console.log(
      `profiler: "${id}" took ${actualDuration}ms to render (${phase})`,
    );
  };

export function GProfiler({id,children}: {id: string,children:React.ReactNode}) {
    return <Profiler id={id} onRender={handleRender}>{children}</Profiler>
}