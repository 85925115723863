import { useContext } from 'react';
import { ProjectContext } from '@grenton/gm/providers/ProjectContext';

export function useProject() {
    const project = useContext(ProjectContext);
    if (!project) {
        throw new Error('project not loaded');
    } else {
        return project;
    }
}

/**
 * return derived state from project or default value if project is not loaded
 * @param fn
 * @param defaultValue
 * @returns
 */
export function useProjectLoaded(): boolean {
    const project = useContext(ProjectContext);
    return Boolean(project);
}
