import { Breakpoint, Dialog, DialogProps, useMediaQuery, useTheme } from "@mui/material";

/**
 * by default dialog will switch into full screen at given breakpoint (or 'md' if not specified)
 * specify fullScreen prop to disable responsiveness.
 */
type Props = {breakpoint?:number|Breakpoint, fullHeight?:boolean} &  DialogProps

export function GDialog({breakpoint, fullHeight, children, ...other}: Props) {
    const theme = useTheme();
    
    const fullScreen = other.fullScreen !== undefined ? other.fullScreen :
    useMediaQuery(theme.breakpoints.down(breakpoint || 'md'));

    return <Dialog {...other} fullScreen={fullScreen} sx={{...other.sx, maxHeight:'100vw', '& .MuiDialog-paper' : { height:fullHeight?'100%':'auto'}}}>
        {children}
    </Dialog>
}


