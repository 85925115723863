import {Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import type {OutletTypeSelectorProps} from './types';



export function OutletTypeSelector({selected, allowedTypes, onChange}: OutletTypeSelectorProps) {
    return (
        <List sx={{maxWidth: 360}}>
            {allowedTypes.map((value) => {
                const labelId = `checkbox-list-label-${value.id}`;
                return (
                    <ListItem
                        key={value.id}
                        disablePadding
                    >
                        <ListItemButton onClick={
                            () => onChange({...selected, [value.id]: !selected[value.id]})
                        } dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={!!selected[value.id]}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.id}/>
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}
