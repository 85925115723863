import { Button, DialogContent, DialogContentText, FormControl, InputLabel, MenuItem, MenuList, Select, Stack } from "@mui/material";
import { useState } from "react";
import { GSideDialog, GSideDialogProps } from "../components/GSideDialog";
import { GDialogTitle } from "../components/GDialogTitle";
import { GDialog } from "../components/GDialog";
import { LOREM_IPSUM_P, LOREM_IPSUM_SHORT } from "./common";
import { GDialogActions } from "../components";


const selectOptions: [number, string][] = [
    [1, 'Option 1'],
    [2, 'Option 2'],
    [3, 'Option 3'],
    [4, 'Option 4']
]


export function DialogsDemo() {

    const [largeDialogOpen, setLargeDialogOpen] = useState(false);
    const [smallDialogOpen, setSmallDialogOpen] = useState(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    
    const [sideDialogOpen, setSideDialogOpen] = useState<{open:boolean, align?:GSideDialogProps['align']}>({open:false});
    const [selectOpen, setSelectOpen] = useState(false);

    const [selectedValue, setSelectedValue] = useState(1);

    const closeLargeDialog = () => setLargeDialogOpen(false);
    const closeSmallDialog = () => setSmallDialogOpen(false);
    const closeAlertDialog = () => setAlertDialogOpen(false);
    
    
    const closeSideDialog = () => setSideDialogOpen({...sideDialogOpen, open:false});
    const closeSelectDialog = () => setSelectOpen(false);
    return (
        <Stack sx={{ gap: 2, direction: 'column', alignItems: 'start' }}>

            <GDialog open={largeDialogOpen} onClose={closeLargeDialog}>
                <GDialogTitle onClose={closeLargeDialog}>Dialog title</GDialogTitle>
                <DialogContent>
                    <DialogContentText>{Array(20).fill(LOREM_IPSUM_P).join('\n')}</DialogContentText>
                </DialogContent>
                <GDialogActions 
                        start={<Button color="white" onClick={closeLargeDialog}>Close</Button>} 
                        end={<>
                            <Button color="secondary" onClick={closeLargeDialog}>Save</Button>
                            <Button color="primary" onClick={closeLargeDialog}>Save & Close</Button>                        
                        </>}/>
            </GDialog>
            <Button sx={{ minWidth: 200 }} variant="contained" color="white" onClick={() => setLargeDialogOpen(true)}>Open large dialog</Button>


            <GDialog open={smallDialogOpen} onClose={closeSmallDialog}>
                <GDialogTitle onClose={closeSmallDialog}>Dialog title</GDialogTitle>
                <DialogContent>
                    <DialogContentText>{LOREM_IPSUM_SHORT}</DialogContentText>
                </DialogContent>
                <GDialogActions 
                        start={<Button color="white" onClick={closeSmallDialog}>Close</Button>} 
                        end={<>
                            <Button color="secondary" onClick={closeSmallDialog}>Save</Button>
                            <Button color="primary" onClick={closeSmallDialog}>Save & Close</Button>                        
                        </>}/>
            </GDialog>
            <Button sx={{ minWidth: 200 }} variant="contained" color="white" onClick={() => setSmallDialogOpen(true)}>Open small dialog</Button>

            <GDialog open={alertDialogOpen} onClose={closeAlertDialog}>
                <DialogContent>
                    <DialogContentText>{LOREM_IPSUM_SHORT}</DialogContentText>
                </DialogContent>
                <GDialogActions 
                        start={<Button color="white" onClick={closeAlertDialog}>No</Button>} 
                        end={<>
                            <Button color="secondary" onClick={closeAlertDialog}>Later</Button>
                            <Button color="primary" onClick={closeAlertDialog}>Yes</Button>                        
                        </>}/>
            </GDialog>
            <Button sx={{ minWidth: 200 }} variant="contained" color="white" onClick={() => setAlertDialogOpen(true)}>Open alert dialog</Button>


            <GSideDialog open={sideDialogOpen.open} align={sideDialogOpen.align} onClose={closeSideDialog}>
                <GDialogTitle onClose={closeSideDialog}>Dialog title</GDialogTitle>
                <DialogContent>
                    <DialogContentText>{Array(20).fill(LOREM_IPSUM_P).join('\n')}</DialogContentText>
                </DialogContent>
                <GDialogActions
                    start={<Button color="white" onClick={closeSideDialog}>Close</Button>} 
                    end={<>
                        <Button color="secondary" onClick={closeSideDialog}>Save</Button>
                        <Button color="primary" onClick={closeSideDialog}>Save & Close</Button>                        
                    </>}/>
            </GSideDialog>
            <Button sx={{ minWidth: 200 }} variant="contained" color="white" onClick={() => setSideDialogOpen({open:true, align:'start'})}>Open side dialog (left)</Button>
            <Button sx={{ minWidth: 200 }} variant="contained" color="white" onClick={() => setSideDialogOpen({open:true, align:'center'})}>Open side dialog (center)</Button>
            <Button sx={{ minWidth: 200 }} variant="contained" color="white" onClick={() => setSideDialogOpen({open:true, align:'end'})}>Open side dialog (right)</Button>

            <GSideDialog open={selectOpen} onClose={closeSelectDialog}>
                <GDialogTitle onClose={closeSelectDialog}>Selectable Option</GDialogTitle>
                <DialogContent>
                    {/* <List>
                    { selectOptions.map(([value, label]) => <ListItemButton autoFocus={value===selectedValue} key={value} onClick={()=>{setSelectedValue(value); closeSelectDialog()}}>{label}</ListItemButton>) }
                </List> */}
                    <MenuList variant="selectedMenu" autoFocusItem>
                        {selectOptions.map(([value, label]) => <MenuItem autoFocus={value === selectedValue} key={value} value={value} onClick={() => { setSelectedValue(value); closeSelectDialog() }}>{label}</MenuItem>)}
                    </MenuList>
                </DialogContent>
            </GSideDialog>

            <FormControl>
                <InputLabel id="select-label">Selectable Option</InputLabel>
                <Select label="Selectable Option" open={false} value={selectedValue} size="small" sx={{ minWidth: 200 }} onOpen={(e) => {
                    e.stopPropagation();
                    setSelectOpen(true);
                }}>
                    {selectOptions.map(([value, label]) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
                </Select>
            </FormControl>
        </Stack>
    );
}