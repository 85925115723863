import { GInlineDrawer, GTabLabel, GTabPanel, drawerHandleStyles, grentonColors } from "@grenton/design-system";
import {ObjectsEditModel} from "./ObjectsEditContext";
import { ObjectEditTab } from "./ObjectEditTab";
import { GOutlinedTabs } from "@grenton/design-system/src/theme2/components/GOutlinedTabs";
import {Tab,Stack,Badge} from "@mui/material";
import { ObjectEditForm, ObjectEditFormId } from "@grenton/gm-logic";
import { ValidatorResult } from "../../../ui/form-validation";

type Props = {
    container:Element|null,
    height?:string|number
    selectedTab: number,
    withVisibleHandle:boolean
    objectsEditModel: ObjectsEditModel,

    onTabsClick:()=>void
    onDrawerClose:()=>void
    onTabSelect:(tab:number)=>void

    onObjectFormClose:(id:ObjectEditFormId)=>void
    onObjectFormSave: (id:ObjectEditFormId) => void
    onObjectFormDelete: (id:ObjectEditFormId) => void
    onObjectFormChange:(form:ObjectEditForm, modified:boolean)=>void
}

export const PROPERTY_MANAGER_HANDLE_HEIGHT = 45

export function ObjectEditDrawer({
    withVisibleHandle,
    height,
    container,
    selectedTab, 
    objectsEditModel,
    onTabSelect,
    onTabsClick,
    onDrawerClose,
    onObjectFormChange,
    onObjectFormClose,
    onObjectFormSave,
    onObjectFormDelete
    }:Props) {


    return <GInlineDrawer 
                withHandle={withVisibleHandle}
                height={height}
                container={container}
                open={objectsEditModel.open}
                onClose={onDrawerClose}>

        <Stack sx={{flexDirection:'column',background:grentonColors.backgrounds_menu,borderTop:`1px solid ${grentonColors.grey[300]}`, ...drawerHandleStyles(PROPERTY_MANAGER_HANDLE_HEIGHT,true)}}>
          <GOutlinedTabs variant="scrollable" scrollButtons={"auto"} value={selectedTab} onClick={onTabsClick} onChange={(_,tab)=>onTabSelect(tab)} >
            {Object.values(objectsEditModel.objects).map((objectFormModel)=>
                <Tab key={objectFormModel.form.id.join('|')} label={objectFormModel.modified ? <Badge variant="dot">
                        <GTabLabel onClose={()=>onObjectFormClose(objectFormModel.form.id)}>{objectFormModel.meta.originName}</GTabLabel>
                    </Badge> : 
                        <GTabLabel onClose={()=>onObjectFormClose(objectFormModel.form.id)}>{objectFormModel.meta.originName}</GTabLabel>}
            />)}
          </GOutlinedTabs>
        </Stack>

        {
            Object.values(objectsEditModel.objects).map((objectFormModel,index)=>
            <GTabPanel key={objectFormModel.form.id.join('|')} value={index} selected={selectedTab} fullHeight={true}>
                <ObjectEditTab 
                    meta={objectFormModel.meta} 
                    form={objectFormModel.form} 
                    errors={ValidatorResult.validResult}

                    onClose={()=>onObjectFormClose(objectFormModel.form.id)}
                    onDelete={()=>onObjectFormDelete(objectFormModel.form.id)} 
                    onSave={()=>onObjectFormSave(objectFormModel.form.id)}
                    onChange={onObjectFormChange}/>
            </GTabPanel>)
        }
    </GInlineDrawer>
}
