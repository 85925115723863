import { ValidatorResult } from '../../../../../../../ui/form-validation';
import { checkLuaName } from '@grenton/gm-logic';
import { ObjectEventForm } from '@grenton/gm-logic';

export function eventEditFormValidator(hasLabel: (label: string) => boolean) {
    return (form: ObjectEventForm) => {
        let result = ValidatorResult.validResult;

        const label = form.spec.label?.trim();
        if (!label?.length) {
            result = result.withError('label', 'name is required');
        } else {
            const luacheck = checkLuaName(label);
            if (luacheck !== 'ok') {
                result = result.withError(
                    'label',
                    luacheck === 'reserved' ? 'this name is reserved' : 'name is invalid (only letters, numbers and underscores are allowed)',
                );
            } else if (hasLabel(label)) {
                result = result.withError('label', 'script with this name already exists in this namespace');
            }
        }
        return result;
    };
}
