import { Stack, Typography } from "@mui/material";
import GrentonIcon from "../../icons/GrentonIcon";
import all from '../../icons/grenton-icons-v1.2.json';

export function IconsDemo() {
    return <Stack sx={{flexDirection:'row', gap:1, flexWrap:'wrap'}}>
        {Object.entries(all.icons).map((ico) =>(
            <Stack key={ico[0]} sx={{alignItems:'center', padding:'2px', flex: "0 0 calc(20%)", border:'1px solid #eee'}}>
                <GrentonIcon name={ico[0]} size={32} />
                <Typography variant="caption">{ico[0]}</Typography>
            </Stack>
            ))}
    </Stack>
}