import { FormControlLabel, Radio, Stack } from "@mui/material";
import { useCallback, useState } from "react";

export function RadiosDemo() {

    const [value, setValue] = useState(true)

    const onRadioClick = useCallback(() =>
        setValue(v => !v)
        , [])

    return <Stack gap={1} alignItems={"start"}>
        <FormControlLabel
            control={<Radio checked={value} onClick={onRadioClick} />}
            label="Radio Group (1)"
            labelPlacement="end"
        />
        <FormControlLabel
            control={<Radio checked={!value} onClick={onRadioClick} />}
            label="Radio Group (2)"
            labelPlacement="end"
        />
        <FormControlLabel
            control={<Radio checked={true} disabled={true} />}
            label="Disabled"
            labelPlacement="end"
        />
    </Stack>
}