import { Slider, Stack } from "@mui/material";
import { useState } from "react";

export function SlidersDemo() {

    const [values, setValues] = useState([30, 50])

    return <Stack gap={1} alignItems={"start"} maxWidth={500}>
        <Slider valueLabelDisplay="auto" defaultValue={50} min={0} max={100} />
        <Slider valueLabelDisplay="auto" min={0} max={100} step={10} marks={true} />
        <Slider valueLabelDisplay="auto" disableSwap value={values} onChange={(_, v) => setValues(v as [number, number])} min={0} max={100} step={10} marks={true} />
        <Slider valueLabelDisplay="auto" defaultValue={50} min={0} max={100} step={10} marks={true} disabled />
    </Stack>
}