import { Sync } from "@mui/icons-material";
import { Badge, IconButton, Stack } from "@mui/material";

export function IconButtonsDemo() {
    return <Stack gap={1} alignItems={"start"} direction={"row"}>
        <Stack gap={1} alignItems={"center"}>
            <IconButton size="small"><Sync /></IconButton>
            <IconButton><Sync /></IconButton>
            <IconButton size="large"><Sync /></IconButton>
        </Stack>
        <Stack gap={1} alignItems={"center"} >
            <Badge badgeContent={23}>
                <IconButton size="small"><Sync /></IconButton>
            </Badge>

            <Badge badgeContent={23}>
                <IconButton><Sync /></IconButton>
            </Badge>

            <Badge badgeContent={23}>
                <IconButton size="large"><Sync /></IconButton>
            </Badge>
        </Stack>
        <Stack gap={1} alignItems={"center"} >

            <Badge variant="dot">
                <IconButton size="small"><Sync /></IconButton>
            </Badge>

            <Badge variant="dot">
                <IconButton><Sync /></IconButton>
            </Badge>

            <Badge variant="dot">
                <IconButton size="large"><Sync /></IconButton>
            </Badge>
        </Stack>
    </Stack>
}