/**
 * this is an ordered palette colors for tags, blocks, categories etc.
 */
export const toolsPalette = {
    // this is slightly less saturated than original accent color
    GRENTON_RED: '#DB5768', //'#DB4155';
    // same here, lighter grey
    GRENTON_GREY: '#707070',
    PURPLE: '#6E7FF5',
    BLUE: '#51A2E3',
    DIRTY_PINK: '#D48992',
    DARK_ORANGE: '#D48A43',
    ORANGE: '#E4B32D',
    YELLOW: '#D9DA37',
    DARK_GREEN: '#359B8D',
    LIGHT_GREEN: '#78C79C',
};
