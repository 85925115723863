import { TreeDemoItem } from './types';

/*
 * typically 3 levels:
 * - tag
 *   - button
 *     - event
 *
 * max 4 levels:
 * - tag
 *   - smartpanel
 *      - button
 *         -event
 *   - controller
 *      - method
 *      - outlet
 *          - event
 */

// const Renderer = function({ item }: { item:TreeDemoItem }) {
//     return <div>{item.data.type}</div>;
// }


function generateNode(index: number, childrenCount: number[], path = ''): TreeDemoItem {
    const c = childrenCount[0];
    const newChildrenCount = childrenCount.toSpliced(0, 1);

    const id = path ? `${path}/${index}` : `${index}`;
    const item: TreeDemoItem = {
        id,
        label: `node ${id}`,
        children: c ? [...Array(c).keys()].map((i) => generateNode(i + 1, newChildrenCount, id)) : [],
        data : {
            type: 'something'
        },
        //checkbox: path ? GTreeItemCheckboxState.HIDDEN : (index>5 ? GTreeItemCheckboxState.DISABLED : GTreeItemCheckboxState.ENABLED)
    };
    return item;
}

export const generateNodes = () => {
    console.time('generateNodes');
    const nodes = [...Array(10).keys()].map((i) => generateNode(i + 1, [10, 10, 10]));
    console.timeEnd('generateNodes');
    return nodes;
};
