import { Checkbox, FormControlLabel, TextField } from "@mui/material";

import type { FeatureEditProps } from './types';
import { ObjectFeatureForm } from "@grenton/gm-logic";
import { EnumSelector } from "@grenton/gm/ui/components/enumSelector";
import { InlineEditPane } from "../../misc/InlineEditPane";
import { FieldErrorMessages } from "@grenton/gm/ui/components/FieldErrorMessages";


export function FeatureEdit({ meta, form, errors, onChange: onEdit, onClose }: FeatureEditProps) {

    const applyChanges = (changes: Partial<ObjectFeatureForm>) => {
        onEdit({ ...form, ...changes })
    }

    return (
        <InlineEditPane onClose={onClose}>
            <TextField
                error={!errors.field('label')}
                helperText={<FieldErrorMessages errors={errors.field('label')} />}
                size="small"
                value={form.spec.label || ""}
                label="Name"
                variant="outlined"
                onChange={(e) => applyChanges({ spec: { ...form.spec, label: e.target.value } })} />

            <TextField
                fullWidth
                error={!errors.field('description')}
                helperText={<FieldErrorMessages errors={errors.field('description')} />}
                size="small"
                value={form.spec.description || ""}
                label="Description"
                variant="outlined"
                onChange={(e) => applyChanges({ spec: { ...form.spec, description: e.target.value } })} />

            <EnumSelector
                label="Type"
                value={form.spec.type || ''}
                options={meta.types.map(t => ({ name: t, value: t }))}
                onChange={val => applyChanges({ spec: { ...form.spec, type: val } })} />

            <FormControlLabel control={
                <Checkbox
                    checked={form.spec.readOnly}
                    onChange={(_, checked) => applyChanges({ spec: { ...form.spec, readOnly: checked } })} />
            } label="Read Only" />
        </InlineEditPane>
    )
}
