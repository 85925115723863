import { Button, Card, CardActions, CardContent, Stack } from "@mui/material";

export function CardsDemo() {
    return <Stack gap={1}>
        <Card>
            <CardContent>
                Card Content
            </CardContent>
            <CardActions>
                <Button size="small">Action</Button>
            </CardActions>
        </Card>

        <Card variant="elevation">
            <CardContent>
                Card Content
            </CardContent>
            <CardActions>
                <Button size="small">Action</Button>
            </CardActions>
        </Card>
    </Stack>
}