import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "./NotificationContextProvider";
import { Snackbar } from "@mui/material";
import { NotificationWrapper } from "../project";
import { NotificationContent } from "./NotificationContent";


export function NotificationSnackbar() {
  const notificationContext = useContext(NotificationContext);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<NotificationWrapper|null>(null);

  useEffect(() => {
    if (notificationContext && notificationContext.notifications.length > 0) {
      setMessage(notificationContext.notifications[notificationContext.notifications.length - 1]||null);
      setOpen(true);
    }
  }, [notificationContext]);


  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setMessage(null);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={message?.autoHideDuration}
      onClose={handleClose} 
    >
      <div>
      {message && <NotificationContent notification={message} onClose={()=>handleClose()} />}
      </div>
    </Snackbar>
  );
};

