export function encodeTreeItemId(id: string) {
    return encodeURIComponent(id);
}

export function concatTreeItemId(encodedParentId: string, itemId: string) {
    return `${encodedParentId}/${encodeTreeItemId(itemId)}`;
}

export function parseTreeItemId(id: string): string[] {
    return id.split('/').map((p) => decodeURIComponent(p));
}
