import type {ReactElement} from 'react';
import {Section, Content} from "./styles";
import {Typography, useTheme} from '@mui/material'
import type {SectionContentProps} from "./types";

export function SectionContent({children, title, description}: SectionContentProps): ReactElement {
    const {spacing} = useTheme()
    return (
        <Section style={{ backgroundColor: '#efeeee', height: "100%"}}>
            {title && <Typography variant="h4" component="h1" style={{
                textAlign: "center",
                marginBottom: 0,
                width: "100%",
                display: "block"
            }}>{title}</Typography>}
            {description && <Typography variant="body1" style={{
                textAlign: "center",
                width: "100%",
                display: "block",
                paddingBottom: spacing(3)
            }}>{description}</Typography>}
            <Content>{children}</Content>
        </Section>
    )
}
