import { EditableList } from "../../editableList";
import { ObjectMethodForm } from "@grenton/gm-logic";
import { ApiItemListProps, ApiListItem, ApiListItemRenderer } from "../../common";

export function MethodList({ items, editedItemId, onAdd, onEdit, onDelete, children }: ApiItemListProps<ObjectMethodForm>) {

    // can be moved up
    const onEditItem = (id: string) => {
        onEdit && onEdit(id)
    }

    const listItems : ApiListItem<ObjectMethodForm>[] = Object.entries(items).map(([id,form]) => ({ 
        id, 
        selectable: form.editable,
        removeable: form.editable,
        form, 
        callable: !form.spec.internal, 
        onCall: () => {}
    }))


    return <EditableList
        shrunkWidth={250}
        selectedItem={editedItemId||undefined}
        onSelectItem={onEditItem}
        onDeleteItem={onDelete}
        onNewItem={onAdd}
        items={listItems}
        renderer={ApiListItemRenderer}>
        {children}
    </EditableList>

}