import { useEffect, useState } from 'react';
import { BehaviorSubject, Observable, Subscription, map, distinctUntilChanged } from 'rxjs';

export function unsubOnDestroy(sub: Subscription) {
    return () => {
        sub.unsubscribe();
    };
}

export function useSubscribeEffect<T>(subject: Observable<T>, setState: (value: T) => void) {
    useEffect(() => {
        const sub = subject.subscribe(setState);
        return () => {
            sub.unsubscribe();
        };
    }, []);
}

export function useSubjectState<T>(subject: Observable<T> & { value: T }): [value: T, setter: (value: T) => void] {
    const [getter, setter] = useState<T>(subject.value);
    useSubscribeEffect(subject, setter);
    return [getter, setter];
}

export function useDerivedState<T, V>(subject: BehaviorSubject<T>, fn: (source: T) => V): [value: V] {
    const [getter, setter] = useState<V>(fn(subject.value));
    useSubscribeEffect(subject.pipe(map(fn), distinctUntilChanged()), setter);
    return [getter];
}

// TODO undefined->null ?
export function useObservable<T>(subject: Observable<T>): [value: T | undefined] {
    const [getter, setter] = useState<T>();
    useSubscribeEffect(subject, setter);
    return [getter];
}

export function useObservableOr<T>(subject: Observable<T>, defaultValue: T): [value: T] {
    const [getter, setter] = useState<T>(defaultValue);
    useSubscribeEffect(subject, setter);
    return [getter];
}

export function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}

export function roundToRange(value: number, range: [number, number]) {
    if (value < range[0]) {
        return range[0];
    }
    if (value > range[1]) {
        return range[1];
    }
    return value;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function firstDefined<T>(...values: (T | undefined)[]): T | undefined {
    for (const v of values) {
        if (v !== undefined) return v;
    }
}
