import { grentonColors } from "@grenton/design-system"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"

type Props = {
    label?: string
    icon: React.ReactNode
    start?: React.ReactNode
    end?: React.ReactNode
}

export function PaneTopbar({label, icon, start, end}:Props) {
    return <Stack sx={{
        overflow:'hidden',
        whiteSpace:'nowrap',
        paddingLeft:1,
        paddingRight:1,
        minHeight:58, 
        width:'100%',
        flexDirection:'row',
        alignItems:'center',gap:1, padding:1,
        borderBottomWidth:1,
        borderBottomStyle:'solid',
        borderBottomColor:grentonColors.Line_Menu}}>
    {icon}
    {label && <Typography variant="body2">{label}</Typography> }
    {start}
    <Box flexGrow={2}/>
    {end}
    </Stack>
}