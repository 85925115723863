import type { ReactElement } from "react";
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import {Accordion, AccordionSummary, AccordionDetails, IconButton, Stack} from "@mui/material";
import { UserAccordionProps } from "./types";
import { TagLabel } from '@grenton/design-system';
import { UserInfoField } from './components';
const stackStyle = { padding: 0, gap: 3 };

export function UserAccordion({ user, handleEditUser, handleDeleteUser }: UserAccordionProps): ReactElement | null {
    const { name, disabled, roles } = user ?? {};

    if (!user) return null

    return (
        <Accordion square>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={name}
                id={name}
            >
                <TagLabel label={name} />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 4 }}>
                <Stack direction="column" alignItems="end" {...stackStyle}>
                    <form style={{ width: "100%" }}>
                        <Stack direction="column" alignItems="start" {...stackStyle}>
                            <UserInfoField label="roles" value={roles?.join(', ')} />
                            <UserInfoField label="disabled" value={`${Boolean(disabled)}`} />
                        </Stack>
                    </form>
                    <Stack direction="row" alignItems="end" {...stackStyle}>
                        <IconButton onClick={() => handleDeleteUser(user)}>
                            <Delete />
                        </IconButton>
                        <IconButton onClick={() => handleEditUser(user)}>
                            <Edit />
                        </IconButton>
                    </Stack>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
