import { SvgIconProps, SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export function ConnectorDisconnected(props: SvgIconProps): ReactElement {

    return <SvgIcon viewBox="0 0 24 24" {...props} fontSize="inherit">
    <g id="disconnected">
        <path d="M2.71,22.71L5.11,20.31C6.596,21.179 8.496,20.931 9.71,19.71L12.41,17L11.41,16L13.7,13.71L12.29,12.3L10,14.59L9.41,14L11.7,11.71L10.29,10.3L8,12.59L7,11.59L4.29,14.29C3.079,15.509 2.832,17.401 3.69,18.89L1.29,21.29L2.71,22.71ZM5.71,15.71L7,14.41L9.59,17L8.29,18.29C7.947,18.633 7.481,18.826 6.995,18.826C5.99,18.826 5.164,18 5.164,16.995C5.164,16.509 5.357,16.043 5.7,15.7L5.71,15.71Z"/>
        <path d="M18.89,3.69C17.401,2.832 15.509,3.079 14.29,4.29L11.59,7L17,12.41L19.71,9.7C20.921,8.481 21.168,6.589 20.31,5.1L22.71,2.7L21.29,1.29L18.89,3.69ZM18.29,8.29L17,9.59L14.41,7L15.7,5.71C16.043,5.367 16.509,5.174 16.995,5.174C18,5.174 18.826,6 18.826,7.005C18.826,7.491 18.633,7.957 18.29,8.3L18.29,8.29Z"/>
    </g>
    </SvgIcon>
}