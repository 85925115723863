import { Clear } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
    onClose?: () => void;
}>;

export function InlineEditPane({onClose, children}:Props) {
    return <Stack alignItems="start" flexDirection={"row"} sx={{flexGrow:1, overflow:'hidden', height:'100%'}}>
        <Stack alignItems="start" gap={2} padding={1} sx={{flexGrow:1, overflow:'auto', height:'100%'}}>
            {children}
        </Stack>
        {onClose && <IconButton onClick={onClose}><Clear/></IconButton>}
    </Stack>
}