import type {MqttForm} from "./types";

export const formStore = {
    load : function() {
        try {
            return JSON.parse(localStorage.getItem('export.mqtt')||'')
        } catch (e:any) {
        }
        return {
            sn:'',
            url:'',
            topic:'',
            username:'',
            password:''
        }
    },
    save: function(form:MqttForm) {
        localStorage.setItem('export.mqtt', JSON.stringify(form))
    }
}
