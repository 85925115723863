import { PropsWithChildren, useEffect, useState } from "react"
import { SystemModelContext } from "./system-model-context"
import { SystemModel, SystemObject } from "../project"
import { useService } from "../providers"

export function SystemModelProvider({children}: PropsWithChildren<{}>) {

    const [systemModel, setSystemModel] = useState<SystemModel<SystemObject> | undefined>()
    const {localFabric} = useService()
    
    useEffect(() => {
        const systemClient = localFabric.localSystemClient
        setSystemModel(systemClient.system.clone())

        const sub = systemClient.events.subscribe(() => {
            setSystemModel(systemClient.system.clone())
        })
        return () => sub.unsubscribe()
    }, [])

    return <SystemModelContext.Provider value={systemModel!}>{children}</SystemModelContext.Provider>
}