import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Stack } from '@mui/material';

function SquareShadowBox(props:{boxShadow:number}) {
  return <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',width:60, height:60, boxShadow:props.boxShadow}}>{props.boxShadow}</Box>
}

export function MenuDemo() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack sx={{gap:2, alignItems:'start'}}>

      <Stack sx={{gap:2,flexDirection:'row',flexWrap:'wrap'}}>
        {[...Array(8).keys()].map((_,i)=><SquareShadowBox key={i} boxShadow={i}/>)}

      </Stack>

      <Button
        onClick={handleClick}
      >
        Click Me
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </Stack>
  );
}
