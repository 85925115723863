import { Maps, schema } from "@grenton/gm-common"
import { EditableList, EditableListItemRendererProps } from "../editableList"
import { useEffect, useState } from "react"
import { Stack, Typography } from "@mui/material"
import { ObjectFeatureForm, ObjectMethodForm } from "@grenton/gm-logic"
import { FeatureValuePane } from "./FeatureValuePane"
import { MethodParamsPane } from "./MethodParamsPane"

// TODO this is incorrect because it should not rely on 'edited' forms, 
// but rather on currently deployed object
type Props = {
    features : Record<string,ObjectFeatureForm>
    methods: Record<string,ObjectMethodForm>
}

type RuntimeItem = {
    id:string
    selectable: boolean
    data : {
        type:'feature',
        feature:ObjectFeatureForm
    } | {
        type:'method',
        method:ObjectMethodForm
    }
}

function RuntimeItemRenderer({item, shrunk}:EditableListItemRendererProps<RuntimeItem>) {
    return (
        <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center', height: 44, overflow: 'hidden' }}>
            <Typography variant="l" sx={{ flexGrow: 1, width: shrunk ? 'auto' : 250, minWidth: shrunk ? 0 : 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.data.type==='feature'?(item.data.feature.spec.label||item.data.feature.spec.id):`${item.data.method.spec.label||item.data.method.spec.id}()`}</Typography>
            {!shrunk && <Typography variant="s" sx={{ flexGrow: 1 }}>{item.data.type==='feature'?'<current-value>':''}</Typography>}
        </Stack>
    )
}

export function RuntimeData({features,methods}:Props) {

    const items = { 
        ...Maps.transform(features,(_,feature)=>({type:'feature',feature} as const)), 
        ...Maps.transform(methods,(_,method)=>({type:'method',method} as const)) 
    }

    const listItems:RuntimeItem[] = Object.entries(items).map(([id,data]) => ({ id,data,selectable:true }))

    const [editedItemId, setEditedItemId] = useState<string | null>(null)

    useEffect(()=>{
        if (editedItemId !== null && !items[editedItemId]) {
            setEditedItemId(null)
        }
    }, [items])

    const onEditItem = (id: string | null) => {
        if (editedItemId === id) {
            setEditedItemId(null)
        } else {
            setEditedItemId(id)
            // if (id && onEdit) {
            //     onEdit(id)
            // }
        }
    }

    const close = () => setEditedItemId(null)

    const editPane = () => {
        if (!editedItemId) return null
        const feature = features[editedItemId]
        if (feature) {
            return <FeatureValuePane spec={feature.spec} onSubmit={function (value: schema.PropertyValue): void {
                console.log('send value',value)
            } } onClose={close} />
        }
        const method = methods[editedItemId]
        if (method) {
            return <MethodParamsPane spec={method.spec} onSubmit={params=>{
                console.log('call method',params)
            }} onClose={close} />
        }
        return null
    }   

    return <EditableList
        shrunkWidth={250}
        selectedItem={editedItemId||undefined}
        onSelectItem={onEditItem}
        items={listItems}
        renderer={RuntimeItemRenderer}>
        {editPane()}
    </EditableList>
}