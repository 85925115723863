import {Slider} from "@mui/material";
import type {RangeSliderProps} from './types';

export function RangeSlider({max, value, onChange}: RangeSliderProps) {
    return (
        <Slider
            min={0}
            max={max}
            value={value}
            onChange={(_, values) => onChange(values as any)}
            valueLabelDisplay="auto"
        />
    );
}