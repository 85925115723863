import { Box, Stack } from '@mui/material';
import { GSplitter } from '../components';

function Area({children,backgroundColor}:{children:React.ReactNode, backgroundColor?:string}) {
    return <Box sx={{width:'100%',backgroundColor:backgroundColor,  height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>{children}</Box>
}

export function SplitterDemo() {

   // const [horizontalSplit, setHorizontalSplit] = useState<Array<number>>([20, 80]) // percentage
   // const [verticalSplit, setVerticalSplit] = useState<Array<number>>([50, 50]) // percentage

    return <Stack sx={{gap:1}}>
        <Box sx={{width:'100%', minHeight:300, border:'1px solid #f0f0f0'}}>
                    <GSplitter minWidths={[100, 100]}
                        initialSizes={[20,80]}
                        direction={"horizontal"}
                        onResizeFinished={(_pairIdx, _newSizes) => {
                            //setHorizontalSplit(newSizes);
                        }}
                    >
                        <Area backgroundColor='#ffffff'>Area 1</Area>
                        <Area backgroundColor='#f5f5f5'>Area 2</Area>
                    </GSplitter>
        </Box>
        <Box sx={{width:'100%', minHeight:300, border:'1px solid #f0f0f0'}}>
                    <GSplitter minHeights={[20, 20]}
                        initialSizes={[50,50]}
                        direction={"vertical"}
                        onResizeFinished={(_pairIdx, _newSizes) => {
                          //  setVerticalSplit(newSizes);
                        }}
                    >
                        <Area backgroundColor='#ffffff'>Area 1</Area>
                        <Area backgroundColor='#f5f5f5'>Area 2</Area>
                    </GSplitter>
        </Box>        

    </Stack>
}