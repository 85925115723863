import { ProjectTreeItemMethodData, ProjectTreeItem, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';
import { MethodImpl, ProjectObjectImpl } from '@grenton/gm-logic';
import { concatTreeItemId } from './id';

type Props = {
    parentId: string;
    path: string[];
    object: ProjectObjectImpl;
    method: MethodImpl;
};

export function methodNode({ parentId, path, object, method }: Props): ProjectTreeItem<ProjectTreeItemMethodData> {
    const id = concatTreeItemId(parentId, method.id);
    return {
        id,
        label: method.name,
        icon: method.spec.scene ? 'scene' : 'method',
        sortKey: method.name,
        data: {
            type: ProjectTreeItemType.METHOD,
            path: path,
            objectId: object.uuid,
            methodId: method.id,
            scene: !!method.spec.scene, // needed?
            hasCode: object.scripts?.scripts[method.spec.id]?.hasContent === true,
        },
        children: [],
    };
}
