import { createContext, useEffect, useState } from "react";
import { ProjectImpl } from "../project";
import { useService } from "./useService";

type ProjectContextType = ProjectImpl | null

export const ProjectContext = createContext<ProjectContextType>(null)

export function ProjectContextProvider({children}: {children: React.ReactNode}) {

    const {projectHolder} = useService()
    const [project,setProject] = useState<ProjectImpl|null>(null)

    useEffect(()=>{
        const sub = projectHolder.project.subscribe(project=>{
            setProject(project)
        })
        return ()=>sub.unsubscribe()
    }, [projectHolder])

    return (
        <ProjectContext.Provider value={project}>
            {children}
        </ProjectContext.Provider>
    )

}