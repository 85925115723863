import { GDialog } from "@grenton/design-system"
import { CluAction } from "./types"
import { Alert, DialogContent } from "@mui/material"
import { ImportOptionsDialog } from "./ImportOptionsDialog"
import { AuthorizeCluDialog } from "./AuthorizeCluDialog"
import { ProjectUserCredentials } from "@grenton/gm-common"

export type ImportStatus = 
    // because currently edited project it not empty, user has to choose with/without modules option
    { status: 'selectoptions', error?:string, cluId: string } |
    // connecting to CLU, validating configuration
    { status: 'inprogress' } | 
    // authorization required
    {status: 'authrequired', error?:string, action: CluAction} | 
    // error during import (besides auth error)
    {status: 'error', error?:string} | 
    // import successful
    {status: 'success'} |
    // no import procedure started 
    null

type Props = {
    open: boolean
    status: ImportStatus
    onClose: ()=>void
    onContinueImport: (action: CluAction)=>void
    onAuthorize: (credentials: ProjectUserCredentials, action:CluAction) => void
}

export function ImportStatusDialog({open,status, onClose, onContinueImport, onAuthorize}:Props) {

    switch (status?.status) {
        case 'selectoptions':
            return <ImportOptionsDialog open={true} onClose={onClose} cluId={status.cluId} onExecuteAction={onContinueImport} />
        case 'inprogress':
            return <GDialog open={open}>
                <DialogContent>
                    Import in progress...
                </DialogContent>
            </GDialog>
        case 'authrequired':
            return <AuthorizeCluDialog 
                open={true} 
                onClose={onClose} 
                onAuthorize={credentials=>onAuthorize(credentials, status.action)} 
                errorMessage={status.error} />
        case 'error':
            return <GDialog open={open} onClose={onClose}>
                <Alert severity="error"  onClose={onClose}>{status.error}</Alert>
            </GDialog>
        case 'success':
            return <GDialog open={open} onClose={onClose}>
                <Alert severity="success" onClose={onClose}>Import Successful</Alert>
            </GDialog>
        default :
            return null
    }
}