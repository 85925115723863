import { ComponentObjectRef, ModuleObjectRef } from '@grenton/gm-common';
import { ProjectImpl } from './project';

type FuncTypeFilter = (funcType: string) => boolean;

/**
 * returns a function that filters narrows down the list of functional types
 * based on the restricted functional types of the object
 *
 * some of objects are very specific regardless of the implemented protocol, and can take only one of functional types
 * defined in their spec. E.g. bus-voltage built into a module, despite implementing generic analog-in, cannot be a "temperature sensor",
 * and the only functional type that makes sense for it is "voltage sensor".
 *
 * by default we allow choosing from all functional types that matches one of inherited protocols, but for such a case
 * we want to show "voltage sensor" only
 *
 * @param project
 * @param object
 * @returns
 */
export function filterAllowedFunctionalTypes(project: ProjectImpl, moduleRef?: ComponentObjectRef): FuncTypeFilter {
    const component = moduleRef ? project.getModuleById(moduleRef.componentId) : null;
    let restrictedFuncTypes: string[] | undefined;
    if (component) {
        const cmp = project.firmware.getComponent(component.ref);
        switch (cmp?.spec.type) {
            case 'module': {
                const objId = (moduleRef as ModuleObjectRef)?.objectId?.split('_')[0]; // TODO ugly. drop numeric suffix
                restrictedFuncTypes = cmp.spec.provides.objects.find((o) => o.id === objId)?.deviceTypes;
                break;
            }
            case 'script':
            case 'system': {
                restrictedFuncTypes = cmp.spec.provides.object.deviceTypes;
            }
        }
    }
    return !restrictedFuncTypes || restrictedFuncTypes.length === 0 ? () => true : (funcType: string) => restrictedFuncTypes!.includes(funcType);
}
