import { Component, notEmpty } from '@grenton/gm-common';
import { ProjectDeviceModuleInstanceImpl, ProjectImpl, ProjectObjectImpl } from '@grenton/gm-logic';

/**
 * we make an assumption here that objects w/o component (atm we have such)
 * cannot have children. this may change in the future.
 */
export type ObjectDeleteChain = {
    root: ProjectObjectImpl;
    linked?: {
        component: Component;
        cmpRef: ProjectDeviceModuleInstanceImpl;
        siblings: {
            object: ProjectObjectImpl;
            children: ProjectObjectImpl[];
        }[];
    };
};

/**
 * given list of objects to be explicitly deleted, find all related objects that also will be deleted
 */
export function findObjectsToDelete(explicitlyDeletedObjectsIds: string[], project: ProjectImpl): ObjectDeleteChain[] {
    const sourceObjects = explicitlyDeletedObjectsIds.map((uuid) => project.getObjectById(uuid)).filter(notEmpty);

    const chains = sourceObjects
        .map((root) => {
            const cmpRef = project.getModuleById(root.impl.componentRef.componentId);
            return cmpRef ? { root, linked: { cmpRef } } : { root };
        })
        // imo this applies to all types of components, not only modules
        // however, only modules atm can contain more than one object
        .map(({ root, linked }) => ({ root, linked: linked && { ...linked, component: project.firmware.getComponent(linked.cmpRef.ref) } }))
        .map(({ root, linked }) => {
            return {
                root,
                linked: linked &&
                    linked.component && {
                        ...linked,
                        component: linked.component!,
                        siblings: project.getAllObjectsByComponent(linked.cmpRef.uuid).filter((o) => o.top),
                    },
            };
        })
        .map(({ root, linked }) => {
            if (linked) {
                const siblings = linked.siblings.map((topObject) => ({
                    object: topObject,
                    children: project.getAllObjectsByComponent(topObject.uuid).filter((o) => !o.top),
                }));
                return { root, linked: { ...linked, siblings } };
            } else {
                return { root };
            }
        });

    /**
     * let's focus on top-objects to be deleted first
     * besides those that are explicitly deleted, we also need to find their siblings that are part of their modules
   
    const chains = sourceObjects
        .map((root) => {
            const cmpRef = root.moduleRef ? project.getModuleById(root.moduleRef.componentId) : null;
            return cmpRef ? { root, linked: { cmpRef } } : { root };
        })
        // imo this applies to all types of components, not only modules
        // however, only modules atm can contain more than one object
        .map(({ root, linked }) => ({ root, linked: linked && { ...linked, component: project.firmware.getComponent(linked.cmpRef.ref) } }))
        .map(({ root, linked }) => {
            return {
                root,
                linked: linked && linked.component && { ...linked, component: linked.component!, siblings: project.getObjectsByModule(linked.cmpRef.uuid) },
            };
        })
        // now, all siblings are top-objects, each of them may have children that also will be deleted
        .map(({ root, linked }) => {
            if (linked) {
                const siblings = linked.siblings.map((topObject) => ({
                    object: topObject,
                    children:
                        // theoretically, this should be recursive, but we don't have more than one level of children
                        Object.entries(topObject.init.outlets)
                            .filter(([_, outlet]) => outlet.isStatic)
                            .map(([outletId, _]) => topObject.getChild(outletId, project.objectResolver))
                            .filter(notEmpty),
                }));
                return { root, linked: { ...linked, siblings } };
            } else {
                return { root };
            }
        });
  */
    return chains;
}
