import { ReactElement } from "react";
import { GRichTreeView } from "@grenton/design-system";
import { hardwareTreeRenderer } from "./hardwareTreeRenderer";
import { HWTreeItem } from "./types";

type Props = {
    items: HWTreeItem[];
    onItemClick: (e:React.MouseEvent, item: HWTreeItem, data:any) => void;
    selected?: string;
    onNodeSelected?: (selected: Array<string> | string) => void;
    defaultExpanded?: string[];
};

export function HardwareTree(props: Props): ReactElement {
    const {
        items,
        onItemClick,
        onNodeSelected,
    } = props;


    return <GRichTreeView
    sx={{"& .GTreeItem-Actions": {minWidth:60}}}
        onSelectedItemsChange={(_e, itemIds)=>{
            onNodeSelected && onNodeSelected(itemIds||[])
        }}        
        onItemClick={onItemClick}
        renderer={hardwareTreeRenderer}
        items={items}
        disableSelection={true}  
    />
}