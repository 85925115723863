import { GDialogTitle, GSideDialog } from "@grenton/design-system";
import { Accordion, AccordionDetails, AccordionSummary, Button, DialogContent, Stack, Typography } from "@mui/material";
import { useNotifications } from "./NotificationContextProvider";
import { NotificationContent } from "./NotificationContent";
import { ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import { useDispatcher } from "../ui";

type Props = {
    open: boolean;
    onClose: () => void;
}

export function NotificationDialog({open,onClose}:Props) {

    const notifications = useNotifications()

    const [expanded,setExpanded] = useState<Record<string,boolean>>({});
    const dispatcher = useDispatcher()

    return <GSideDialog align="start" open={open} onClose={onClose}>
        <GDialogTitle onClose={onClose}>Notifications</GDialogTitle>
        <DialogContent sx={{width:400}}>
            <Stack sx={{gap:1}}>
            {notifications?.notifications.map((notification) => 
                (
                    <Accordion key={notification.id} expanded={expanded[notification.id]} onChange={()=>{setExpanded({...expanded,[notification.id]:!Boolean(expanded[notification.id])})}}>
                    <AccordionSummary
                   
                      sx={{p:0,m:0,minHeight:0, 
                        '&.Mui-expanded': {
                            margin: 0,
                            minHeight: 0
                          },
                          '& .MuiAccordionSummary-content': {
                            margin: 0,
                          },
                          '& .MuiAccordionSummary-content.Mui-expanded': {
                            margin: 0,
                            minHeight: 0
                          },
                          '& .MuiAccordionSummary-expandIconWrapper': {
                            position: 'absolute',
                            right: '10px',
                            color: '#fff'
                          } 
                      }}
                      expandIcon={<ExpandMore />}
                      
                    >
                      <NotificationContent key={notification.id} notification={notification}/>
                    </AccordionSummary>
                    <AccordionDetails sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                      <Typography variant="m" >
                        {notification.data.details}
                      </Typography>
                     {notification.data.commands?.length && <Stack sx={{mt:2, alignItems:'center', direction:'row'}}>
                        {notification.data.commands?.map((command) => 
                            (
                                <Button size="small" key={command.title} onClick={()=>dispatcher(command.command)}>
                                    {command.title}
                                </Button>
                            )
                            )}
                      </Stack> }
                    </AccordionDetails>
                  </Accordion>
                )

            )
                }
            </Stack>
        </DialogContent>
    </GSideDialog>
}