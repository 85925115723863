import { DialogContent, DialogContentText, Button, Stack, MenuItem, Select, Typography, ButtonBase } from "@mui/material";
import { useEffect, useState } from "react";
import { ProjectTagsCategory } from "@grenton/gm-logic";
import GMTextField from "../controls/GMTextField";
import { EditTagForm } from "@grenton/gm-logic";
import { GDialog, GDialogActions, GDialogTitle, TagLabel } from "@grenton/design-system";

export interface EditTagFormMeta {
  categories: ProjectTagsCategory[]
}

type Props = {open:boolean,
  meta:EditTagFormMeta,
  form:EditTagForm,
  onAddCategory:()=>void,
  onClose:(form?:EditTagForm)=>void}

export function AddTagDialog(props:Props) {

    const [form,setForm] = useState<EditTagForm>(props.form)

    useEffect(()=>{
      setForm(props.form)
    }, [props.form])

    return <GDialog
        open={props.open}
        onClose={()=>props.onClose()}
      >
        <GDialogTitle onClose={props.onClose}>Create new tags</GDialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            sx={{whiteSpace:'pre'}}
            tabIndex={-1}
          >
            <Stack direction="row" alignItems="center">
              <Select
                
                autoWidth={true}
                sx={{minWidth:'5em',marginRight:2,marginTop:2}}
                variant="standard"
                placeholder="Category"
                displayEmpty={true}
                disableUnderline={true}
                value={form.category}
                onChange={e=>{setForm({...form, category:e.target.value||''})}}
                label="Category"
              >
                <MenuItem disabled value="">
                  <Typography variant="body2">Category</Typography>
                </MenuItem>
                { props.meta.categories.map(c=>(<MenuItem key={c.name} value={c.name}><TagLabel label={c.name} color={c.color}/></MenuItem>))}
                
                <MenuItem onClick={e=>e.stopPropagation()}>
                  <ButtonBase sx={{justifyContent:'start',width:'100%',fontSize:'1rem'}} onClick={props.onAddCategory}><TagLabel label="+"/></ButtonBase>
                </MenuItem>
              </Select>
              <GMTextField multiline={true} label="Tag" variant="standard" value={form.value} onChange={(e)=>setForm({...form, value:e.target.value})} />
            </Stack>
          </DialogContentText>
        </DialogContent>
        <GDialogActions start={
          <Button onClick={()=>props.onClose()}>Cancel</Button>
          } end={
          <Button color="primary" disabled={!form.value.trim().length || !form.category.trim().length} onClick={()=>props.onClose(form)}>Add</Button>
          }>
          
          </GDialogActions>
      </GDialog>
}

