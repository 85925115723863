import { ANONYMOUS_CONTROLLER_SUFFIX, fabric, notEmpty, OUTLET_PARENT } from '@grenton/gm-common';
import { OutletImpl, ProjectObjectImpl } from '@grenton/gm-logic';
import {
    ProjectTreeItem,
    ProjectTreeItemDataExpand,
    ProjectTreeItemEventData,
    ProjectTreeItemObjectData,
    ProjectTreeItemOutletData,
    ProjectTreeItemType,
} from '@grenton/gm/ui/components/projectComponentTree2';
import { eventNodes } from './eventNodes';
import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';
import { concatTreeItemId } from './id';
import { objectNode } from './objectNode';
import { MainTreeContext } from './treeContext';

type Props = {
    ctx: MainTreeContext;
    parentId: string;
    path: string[];
    object: ProjectObjectImpl;
    outlet: OutletImpl;
    outletRefs?: fabric.OutletRefs;
};

export function outletNode({
    ctx,
    parentId,
    path,
    object,
    outlet,
    outletRefs,
}: Props): ProjectTreeItem<
    ProjectTreeItemOutletData,
    ProjectTreeItemOutletData | ProjectTreeItemEventData | ProjectTreeItemDataExpand | ProjectTreeItemObjectData
> {
    const id = concatTreeItemId(parentId, outlet.id);

    // in main tree we're not interested in methods defined by outlet's protocol - they are not "scriptable" / "callable" from this tree
    // instead, we show them in target object's tree.

    const scriptableObject = object.impl.type === 'script' ? object : ctx.objectResolver(`${object.uuid}${ANONYMOUS_CONTROLLER_SUFFIX}`);

    const protocolEvents: ProjectTreeItem<ProjectTreeItemEventData | ProjectTreeItemDataExpand>[] =
        !ctx.multiSelectMode && scriptableObject
            ? eventNodes({
                  ctx,
                  parentId: id,
                  path,
                  scriptableObject: scriptableObject,
                  sourceObject: object,
                  protocol: outlet.api,
              })
            : [];

    const protocolOutlets = Object.values(outlet.api.outlets)
        .filter((o) => o.id !== OUTLET_PARENT)
        .map((o) => outletNode({ ctx, parentId: id, path: [...path, o.id], object, outlet: o }))
        .sort(sortTreeNodes);

    const outletReferences = ctx.showReferencedObjects
        ? outletRefs?.refs
              .map(ctx.objectResolver)
              .filter(notEmpty)
              .map((obj) =>
                  objectNode({
                      ctx,
                      path: [obj.uuid],
                      object: obj,
                      parentId: id,
                      // for dynamic references, we hide protocol details.
                      // for statically referenced objects, that are visible only in this single place, we need to show protocols
                      showProtocolDetails: ctx.showProtocolDetailsForStaticChildren && Boolean(outletRefs.static),
                      shortenLabel: Boolean(outletRefs.static),
                  }),
              ) ?? []
        : [];

    return {
        id,
        label: outlet.name,
        sortKey: outlet.name,
        icon: 'outlet',
        data: {
            type: ProjectTreeItemType.OUTLET,
            path,
            outletId: outlet.id,
            objectId: object.uuid,
            refs: outletRefs?.refs.length || 0,
        },
        // render "onEvent" nodes only for scriptable objects, for others are read only
        children: [...protocolEvents, ...protocolOutlets, ...outletReferences],
    } satisfies ProjectTreeItem<
        ProjectTreeItemOutletData,
        ProjectTreeItemOutletData | ProjectTreeItemEventData | ProjectTreeItemDataExpand | ProjectTreeItemObjectData
    >;
}
