import {TextField} from "@mui/material";
import type {EventEditFormUIProps} from './types';
import { ObjectEventForm } from "@grenton/gm-logic";
import { InlineEditPane } from "../../misc/InlineEditPane";
import { FieldErrorMessages } from "@grenton/gm/ui/components/FieldErrorMessages";


export function EventEdit({errors, form, onChange, onClose}: EventEditFormUIProps) {
    const applyChanges = (changes:Partial<ObjectEventForm>) => {
        onChange({...form, ...changes})
    }

    return (
            <InlineEditPane onClose={onClose}>
                <TextField
                    error={!errors.field('label')}
                    helperText={<FieldErrorMessages errors={errors.field('label')}/>}
                    value={form.spec.label||''}
                    label="Name" 
                    variant="outlined" 
                    onChange={(e) => applyChanges({spec: {...form.spec, label: e.target.value}})}/>

                <TextField
                    fullWidth
                    error={!errors.field('description')}
                    helperText={<FieldErrorMessages errors={errors.field('description')} />}
                    value={form.spec.description || ""}
                    label="Description" 
                    variant="outlined"
                    onChange={(e) => applyChanges({ spec: { ...form.spec, description: e.target.value } })} />
            </InlineEditPane>
    )
}
