import { ButtonBase, Stack } from "@mui/material";
import { TagLabel, TagLabelProps } from "../../components";
import { ReactNode, useState } from "react";
import { useTheme } from "@mui/material";
import { toolsPalette } from "../toolsPalette";

const tagColors = [
    toolsPalette.PURPLE, 
    toolsPalette.BLUE,
    toolsPalette.DIRTY_PINK,
    toolsPalette.DARK_ORANGE,
    toolsPalette.ORANGE,
    toolsPalette.YELLOW,
    toolsPalette.DARK_GREEN,
    toolsPalette.LIGHT_GREEN,
    toolsPalette.GRENTON_GREY];

function TagLabelCheckable(props:TagLabelProps) : ReactNode {
    const [checked, setChecked] = useState(props.checked)
    return <ButtonBase sx={{borderRadius:'1em'}} onClick={()=>setChecked(!checked)}><TagLabel {...props} checked={checked}/></ButtonBase>
}

export function TagsDemo() {

    const theme = useTheme()
    const LOC_COLOR = theme.palette.primary.main

    return <Stack sx={{flexDirection:'column', gap:1}}>
        <Stack sx={{flexDirection:'row', gap:'1em', flexWrap:'wrap'}}>
            <TagLabel label="loc" color={LOC_COLOR}/>
            <TagLabel label="loc:floor1.kitchen" color={LOC_COLOR}/>
            <TagLabel label="floor1.kitchen" color={LOC_COLOR}/>
            <TagLabelCheckable checked={true} label="loc" color={LOC_COLOR}/>
            <TagLabelCheckable checked={false} label="loc" color={LOC_COLOR}/>
        </Stack>

        <Stack sx={{flexDirection:'row', gap:'1em', flexWrap:'wrap'}}>
        <TagLabel label="lights" color={tagColors[0]}/>
        <TagLabel label="lights:primary" color={tagColors[0]}/>
        <TagLabel label="primary" color={tagColors[0]}/>
        <TagLabelCheckable checked={true} label="lights" color={tagColors[0]}/>
        <TagLabelCheckable checked={false} label="lights" color={tagColors[0]}/>
        </Stack>
        
        <Stack sx={{flexDirection:'row', gap:'1em', flexWrap:'wrap'}}>
        <TagLabel label="custom" color={tagColors[1]}/>
        <TagLabel label="custom:favs" color={tagColors[1]}/>
        <TagLabel label="favs" color={tagColors[1]}/>
        <TagLabelCheckable checked={true} label="favs" color={tagColors[1]}/>
        <TagLabelCheckable checked={false} label="favs" color={tagColors[1]}/>
        </Stack>
        
        <Stack sx={{flexDirection:'row', gap:'1em', flexWrap:'wrap'}}>
        <TagLabel label="nocolor"/>
        <TagLabel label="nocolor:value"/>
        <TagLabelCheckable checked={true} label="nocolor"/>
        <TagLabelCheckable checked={false} label="nocolor"/>
        </Stack>
    </Stack>
}