import {ReactElement} from "react";
import {Outlet, useNavigate, useLocation} from 'react-router-dom';
import {Wrapper, List} from "./styles";
import {menuItems} from "./menuItems";
import type {MenuItem} from './types';
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

export function SettingsMenu(): ReactElement {
    const navigate = useNavigate();
    const pathname = useLocation().pathname;

    const renderMenuButton = (item: MenuItem) => (
        <ListItemButton
            key={item.path}
            selected={pathname === item.path}
            onClick={() => navigate(item.path)}
        >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label}/>
        </ListItemButton>
    );

    return (
        <Wrapper>
            <List>
                {menuItems.map(renderMenuButton)}
            </List>
            <Outlet/>
        </Wrapper>
    );
}
