import { grentonColors } from "../colors"
import { CSSProperties } from "react"
import { sizeRem, sizes } from "../size"

// TODO use styled component and theme
const labelStyles = {
    fontFamily: 'Manrope',
    fontSize: sizeRem(sizes.M*0.75),
    //transform: 'scale(0.75)',
    fontWeight: 600,
    paddingLeft: '0.3em',
    paddingRight: '0.3em',
    marginLeft:'-4px',
    background: '#fff',
    lineHeight:'1em'
}

export function GFieldset(props:{styles?:CSSProperties,label?:string,children?:React.ReactNode}) {
  return <fieldset style={{borderColor:grentonColors.grey[400],borderStyle:'solid',borderWidth:1,...props.styles}}>
    <legend style={labelStyles}>{props.label}</legend>
    {props.children}
  </fieldset>
}
