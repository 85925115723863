import {Box, Slider, Stack} from "@mui/material";
import {useState} from "react";
import {preventHorizontalKeyboardNavigation} from './utils';
import {RangeSlider} from './components';
import type {SchedulerObjectControlProps} from './types';

export function SchedulerObjectControl({}: SchedulerObjectControlProps) {
    const [range, setRange] = useState<[number,number]>([0, 0]);
    const [values, setValues] = useState([50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50]);

    return (
        <Stack
            padding={2}
            direction="column"
        >
            <Stack
                spacing={0}
                direction="row"
                sx={{height: 300}}
            >
                {values.map((val, h) => (
                    <Slider
                        key={h}
                        sx={{
                            '& input[type="range"]': {
                                WebkitAppearance: 'slider-vertical',
                            },
                            opacity: h >= range[0] && h <= range[1] ? 1 : 0.6,
                        }}
                        size="small"
                        orientation="vertical"
                        min={0}
                        max={100}
                        value={val}
                        onChange={(_, v) => {
                            const _values = [...values]
                            _values[h] = v as number
                            const inrange = h >= range[0] && h <= range[1]
                            if (inrange) {
                                for (let i = range[0]; i <= range[1]; i++) {
                                    _values[i] = _values[h]!
                                }
                            }
                            setValues(_values)
                        }}
                        valueLabelDisplay="auto"
                        onKeyDown={preventHorizontalKeyboardNavigation}
                    />
                ))}
            </Stack>
            <Box padding={2}> <RangeSlider max={values.length - 1} value={range} onChange={(value)=>setRange([value[0]!,value[1]!])}/></Box>
        </Stack>
    )
}
