import {
    ObjectImplementation
} from "@grenton/gm-common";
import {ProjectObjectImpl, ProjectFirmwareImpl} from "@grenton/gm-logic";
import { GIconName } from "@grenton/gm/ui/icons/icons";
import { protocolIconResolver } from "./protocolIconResolver";
import { functionalTypeIconResolver } from "./functionalTypeIconResolver";

export type ObjectIconResolver = (object:ProjectObjectImpl)=>GIconName|null

export function objectIconResolver(firmware: ProjectFirmwareImpl) : ObjectIconResolver {
    const functionalTypeIcon = functionalTypeIconResolver(firmware)
    return (object:ProjectObjectImpl) => objectIcon(object, functionalTypeIcon)
}

function objectIcon(target: ProjectObjectImpl, getFunctionalTypeIcon:(id:string)=>GIconName|null) : GIconName|null {
    return (target.userType ? getFunctionalTypeIcon(target.userType) : null) || 
        implIcon(target.impl) || 
        protocolIconResolver(target.api.api)
}

function implIcon(impl: Pick<ObjectImplementation, "type">) : GIconName|null {
    switch (impl.type) {
        case "script" :
            return "logic"
        default:
            return null
    }
}


