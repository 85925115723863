import { Box, Grid, Typography, Stack, ButtonBase, Tab, List, ListSubheader, ListItemButton, ListItemIcon, ListItemText, IconButton } from "@mui/material";
import { Lists, Component } from "@grenton/gm-common";
import React from "react";
import TabPanel from "../components/TabPanel";
import { GTabs, ModuleLabel ,icons as themeIcons} from "@grenton/design-system";
import { ProjectFirmwareImpl } from "@grenton/gm-logic";
import { icons } from "../icons";



function ObjectButton(props: { onClick: () => void, icon: React.ReactNode, label: string }) {
    return <ButtonBase sx={{ margin: 1, minWidth: 120, height: '100%' }} onClick={props.onClick}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {props.icon}
            <Typography mt={1} variant="caption">{props.label}</Typography>
        </Box>
    </ButtonBase>
}

type ObjectLibraryProps = {
    tab: number,
    firmware: ProjectFirmwareImpl,
    setTab: (index: number) => void,
    onSelectComponent: (c: Component) => void,
    onSelectMisc: (type: string) => void
    onClose: () => void
}

export function ObjectLibrary({tab, setTab, firmware, onSelectComponent, onSelectMisc, onClose}: ObjectLibraryProps) {

    const nonModulesByGroup = Lists.group(firmware.components.filter(cmp=>cmp.spec.type !== 'module'), mod => [mod.spec.meta?.group||'?', mod])
    const modulesByGroup = Lists.group(firmware.components.filter(cmp=>cmp.spec.type === 'module'), mod => [mod.spec.meta?.group||'?', mod])

    return <Stack sx={{height:'100%',width:'100%', position:'relative'}}>
        <GTabs sx={{px:1}} value={tab} onChange={(_, val) => setTab(val)}>
            <Tab label="Modules" />
            <Tab label="Logic" />
            <Tab label="Integration" />
            <Tab label="Misc" />
        </GTabs>
        <IconButton sx={{position:'absolute',top:4,right:4}} onClick={onClose}><themeIcons.Close/></IconButton>
        <Box p={0} sx={{ overflow: 'auto' }}>
            <TabPanel value={tab} index={0}>
                <List>
                    {Object.entries(modulesByGroup).map(entry => <React.Fragment key={entry[0]}>
                        <ListSubheader sx={{ background: '#eee', lineHeight: '1.7em', textTransform: 'uppercase' }}>{entry[0]}</ListSubheader>
                        {entry[1].map(cmp => (
                            <ListItemButton onClick={() => onSelectComponent(cmp)} key={cmp.id} >
                                <ListItemIcon><img style={{ maxWidth: '100px' }} src={cmp.spec.meta?.images?.th} /></ListItemIcon>
                                <ListItemText primary={
                                    <Stack direction={"row"} sx={{ justifyContent: 'space-between', marginBottom: 1 }}>
                                        <div>{cmp.spec.meta?.name || cmp.spec.name}</div>
                                        <div><ModuleLabel module={{ ref: cmp.id }} /></div>
                                    </Stack>
                                } secondary={cmp.spec.meta?.summary} />
                            </ListItemButton>
                        ))}
                    </React.Fragment>)}
                </List>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <List>
                    {Object.entries(nonModulesByGroup)
                        .filter(entry => entry[0] !== "integration")
                        .map((entry, i) => <React.Fragment key={i}>
                            <ListSubheader sx={{ background: '#eee', lineHeight: '1.7em', textTransform: 'uppercase' }}>{entry[0]}</ListSubheader>
                            {entry[1].map(cmp => (
                                <ListItemButton key={cmp.id} onClick={() => onSelectComponent(cmp)} >
                                    <ListItemIcon><icons.logic/></ListItemIcon>
                                    <ListItemText primary={cmp.spec.meta?.name || cmp.spec.name} secondary={cmp.spec.meta?.summary} />
                                </ListItemButton>

                            ))}
                        </React.Fragment>)}
                </List>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <List>
                    {Object.entries(nonModulesByGroup)
                        .filter(entry => entry[0] === "integration")
                        .map(entry => <React.Fragment key={entry[0]}>
                            <ListSubheader sx={{ background: '#eee', lineHeight: '1.7em', textTransform: 'uppercase' }}>{entry[0]}</ListSubheader>
                            {entry[1].map(cmp => (
                                <ListItemButton onClick={() => onSelectComponent(cmp)} >
                                    <ListItemIcon><icons.logic/></ListItemIcon>
                                    <ListItemText primary={cmp.spec.meta?.name || cmp.spec.name} secondary={cmp.spec.meta?.summary} />
                                </ListItemButton>

                            ))}
                        </React.Fragment>)}
                </List>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <ObjectButton onClick={() => { onSelectMisc('tag') }} label="Tag" icon={<icons.tag/>} />
                    </Grid>
                </Grid>
            </TabPanel>
        </Box>
    </Stack>
}
