import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {Stack, Typography } from '@mui/material';
import { Web } from '@mui/icons-material';

export function ToggleButtonsDemo() {

  const [alignment, setAlignment] = React.useState('web');

  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  const [selected, setSelected] = React.useState(false);

  return (
    <Stack  gap={1}>

        <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
        <Typography variant="xs">Regular toggle buttons</Typography>
          <ToggleButton sx={{minWidth:200}} value="web" selected={selected} onClick={()=>setSelected(!selected)}>Web</ToggleButton>
          <ToggleButton value="web" selected={selected} onClick={()=>setSelected(!selected)}>
            <Web/>
          </ToggleButton>
        </Stack>
        <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
          <Typography variant="xs">Small toggle buttons</Typography>
          <ToggleButton size='small' sx={{minWidth:200}} value="web" selected={selected} onClick={()=>setSelected(!selected)}>Web</ToggleButton>
          <ToggleButton size='small' value="web" selected={selected} onClick={()=>setSelected(!selected)}>
            <Web/>
          </ToggleButton>
        </Stack>
        
        <ToggleButtonGroup
          color="secondary"
          value={alignment}
          exclusive
          onChange={handleChange}
        >
            <ToggleButton value="web">Web</ToggleButton>
            <ToggleButton value="android">Android</ToggleButton>
            <ToggleButton value="ios">iOS</ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
        color="primary"
        size='small'
        value={alignment}
        exclusive
        onChange={handleChange}
        >
            <ToggleButton value="web">Web</ToggleButton>
            <ToggleButton value="android">Android</ToggleButton>
            <ToggleButton value="ios">iOS</ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
        color="greyish"
        size='small'
        value={alignment}
        exclusive
        onChange={handleChange}
        >
            <ToggleButton value="web">Web</ToggleButton>
            <ToggleButton value="android">Android</ToggleButton>
            <ToggleButton value="ios">iOS</ToggleButton>
        </ToggleButtonGroup>
    </Stack>
  );
}