import log from 'loglevel';
import { GMUser, GMUserData } from './user';

const LAST_USER_KEY = 'gm-last-user';

export const userStore = {
    store(user: GMUser) {
        localStorage.setItem(LAST_USER_KEY, JSON.stringify(user));
    },
    load(): GMUser | undefined {
        const data = localStorage.getItem(LAST_USER_KEY);
        if (!data) return;
        try {
            return GMUserData.parse(JSON.parse(data));
        } catch (e) {
            log.warn('cannot parse last user data', e);
            return undefined;
        }
    },
    remove() {
        localStorage.removeItem(LAST_USER_KEY);
    },
};
