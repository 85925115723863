import { Box } from "@mui/material";

/**
 * with current switch style, paddings must be set to 0 
 * (see "android" switch here: https://mui.com/material-ui/react-switch/#system-CustomizedSwitches.tsx)
 * however we usually require some padding, hence this simple wrapper
 * @returns 
 */
export function GSwitchBox({children}:{children:React.ReactNode}) {
    return <Box margin={1}>{children}</Box>
}