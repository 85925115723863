import { Stack, ThemeProvider } from '@mui/material';
import { grentonColors, theme2, useContainerRef } from '@grenton/design-system';
import { Palette, TypographyStyles } from './Palette';

import { ButtonsDemo } from './ButtonsDemo';
import { IconButtonsDemo } from './IconButtonsDemo';
import { SwitchesDemo } from './SwitchesDemo';
import { CheckboxesDemo } from './CheckboxesDemo';
import { TextFieldsDemo } from './TextFieldsDemo';
import { RadiosDemo } from './RadiosDemo';
import { SlidersDemo } from './SlidersDemo';
import { ToggleButtonsDemo } from './ToggleButtonsDemo';
import { useState } from 'react';
import { DrawersDemo, SampleInlineDrawer } from './DrawersDemo';
import { TabsDemo } from './TabsDemo';
import { Header } from './common';
import { TagsDemo } from './TagsDemo';
import { MenuDemo } from './MenuDemo';
import { SplitterDemo } from './SplitterDemo';
import { GCustomStyles } from '../GCustomStyles';
import { TreeDemo } from './trees/TreeDemo';
import { DialogsDemo } from './DialogsDemo';
import { CardsDemo } from './CardsDemo';
import AccordionDemo from './AccordionDemo';
import { DebugDemo } from './DebugDemo';
import { IconsDemo } from './IconsDemo';

const handleHeight = 46;

export function Showcase() {
    const [inlineDrawerOpen, setInlineDrawerOpen] = useState(false);
    const [innerDrawerHasHandle, setInnerDrawerHasHandle] = useState(true);
    const toggleInlineDrawer = () => setInlineDrawerOpen(!inlineDrawerOpen);

    // this will cause re-render, useRef will not...
    const [containerRef, setContainerRef] = useContainerRef();

    return (
        <ThemeProvider theme={theme2}>
            <GCustomStyles />

            <Stack
                ref={setContainerRef}
                className="showcase"
                sx={{
                    padding: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    position: 'absolute',
                    fontFamily: 'Manrope',
                    color: grentonColors.black['100%'],
                    background: grentonColors.white[100],
                }}
            >
                <Stack
                    sx={{
                        flexDirection: 'column',
                        padding: 2,
                        gap: 2,
                        overflow: 'auto',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: innerDrawerHasHandle ? handleHeight : 0,
                        position: 'absolute',
                    }}
                >
                    
                    <Header>Debug</Header>
                    <DebugDemo />

                    <Header>Dialogs</Header>
                    <DialogsDemo />

                    <Header>Trees</Header>
                    <TreeDemo />

                    <Header>Splitter</Header>
                    <SplitterDemo />

                    <Header>Shadows & Menus</Header>
                    <MenuDemo />

                    <Header>Cards</Header>
                    <CardsDemo />

                    <Header>Accordions</Header>
                    <AccordionDemo />

                    <Header>Tabs</Header>
                    <TabsDemo />

                    <Header>Drawers</Header>
                    <DrawersDemo
                        toggleInlineDrawer={toggleInlineDrawer}
                        inlineDrawerHasHandle={innerDrawerHasHandle}
                        setInlineDrawerHasHandle={setInnerDrawerHasHandle}
                    />

                    <Header>Buttons</Header>
                    <ButtonsDemo />

                    <Header>Toggle Buttons</Header>
                    <ToggleButtonsDemo />

                    <Header>Tags</Header>
                    <TagsDemo />

                    <Header>Icon Buttons</Header>
                    <IconButtonsDemo />

                    <Header>Switches</Header>
                    <SwitchesDemo />

                    <Header>Checkboxes & Radios</Header>
                    <CheckboxesDemo />
                    <RadiosDemo />

                    <Header>Text Fields</Header>
                    <TextFieldsDemo />

                    <Header>Sliders</Header>
                    <SlidersDemo />

                    <Header>Colors</Header>
                    <Palette />

                    <Header>Typography</Header>
                    <TypographyStyles />

                    <Header>Icons</Header>
                    <IconsDemo/>
                </Stack>
            </Stack>
            {/* it has to be placed AFTER container! */}

            <SampleInlineDrawer
                container={containerRef}
                hasHandle={innerDrawerHasHandle}
                open={inlineDrawerOpen}
                setOpen={setInlineDrawerOpen}
                handleHeight={handleHeight}
            />
        </ThemeProvider>
    );
}
