import { Add } from "@mui/icons-material";
import { Breadcrumbs, IconButton, Link, Tab } from "@mui/material";
import React from "react";
import { ObjectResolver } from "@grenton/gm-logic";
import { ScriptWithContext } from "@grenton/gm-logic";
import { OUTLET_OBJECT } from "@grenton/gm-common";
import { GTabs } from "@grenton/design-system";

export function EditorToolbar(props: {
  editedScript: ScriptWithContext,
  resolver: ObjectResolver,
  onObjectEdit: (objectId: string) => void,
  onOutletEdit: (objectId: string, outletId: string) => void,
  onMethodEdit: (objectId: string, methodId: string) => void
}) {
  const nav = []
  const { editedScript, onObjectEdit: onEditObject, onOutletEdit, onMethodEdit } = props

  const object = editedScript.entity.getFirstChild(OUTLET_OBJECT, props.resolver) || editedScript.entity

  nav.push({
    name: object.label, onClick: () => {
      onEditObject(object.uuid)
    }
  })
  const outlet = editedScript.getOutlet()
  if (outlet && outlet.id !== OUTLET_OBJECT) {
    nav.push({
      name: outlet.id, onClick: () => {
        onOutletEdit(editedScript.entity.uuid, outlet.id)
      }
    })
  }
  if (editedScript.scriptRef.type === 'event') {
    nav.push({ name: editedScript.scriptRef.path })
  } else if (editedScript.scriptRef.type === 'method') {
    const methodId = editedScript.scriptRef.path
    nav.push({
      name: editedScript.getMethod()?.name,
      onClick: () => onMethodEdit(editedScript.entity.uuid, methodId)
    })
  }

  const [segment, setSegment] = React.useState(0)

  return <React.Fragment>
    <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: 14 }} >
      {
        nav.map((n, i) =>
          <Link key={i} sx={{ cursor: 'pointer' }}
            underline="hover" onClick={n.onClick} color="inherit">
            {n.name}
          </Link>

      )}
      <div/>
    </Breadcrumbs>
    {/* <Link underline="hover">[1]</Link>
    <Link underline="hover" color="inherit">[2]</Link>
    <Link underline="hover" color="inherit">[3]</Link> */}
    {/* <ToggleButtonGroup exclusive={true} value={segment} size="small" sx={{ml:3}} onChange={(_,s)=>setSegment(s)}>
      <ToggleButton sx={{minWidth:40}} value={1}>1</ToggleButton>
      <ToggleButton sx={{minWidth:40}} value={2}>2</ToggleButton>
      <ToggleButton sx={{minWidth:40}} value={3}>3</ToggleButton>
      <ToggleButton sx={{minWidth:40}} value={4}>4</ToggleButton>
      
    </ToggleButtonGroup> */}

    <GTabs value={segment} sx={{fontSize:10,mb:1,mr:1, gap:0, '& .MuiTabs-flexContainer': {gap:0}}} onChange={(_,s)=>setSegment(s)}>
      <Tab value={0} label="script 1"/>
      {/* <Icon sx={{marginTop: '0.4em', fontSize: '1.75em'}}><KeyboardArrowRight/></Icon>
      <Tab value={1} label="visual"/>
      <Icon sx={{marginTop: '0.4em', fontSize: '1.75em'}}><KeyboardArrowRight/></Icon>
      <Tab value={2} label="visual"/>
      <Icon sx={{marginTop: '0.4em', fontSize: '1.75em'}}><KeyboardArrowRight/></Icon>
      <Tab value={3} label="code"/> */}
    </GTabs>
    
    <IconButton><Add/></IconButton>


    {/* <Box ml={2} mr={2} sx={{ color: grentonColors.Line_Menu }}>|</Box>
    <Tooltip title="Undo"><IconButton><Icon><Undo /></Icon></IconButton></Tooltip>
    <Tooltip title="Redo"><IconButton><Icon><Redo /></Icon></IconButton></Tooltip>
    <Tooltip title="Copy code"><IconButton><Icon><ContentCopy /></Icon></IconButton></Tooltip>
    <Tooltip title="Paste code"><IconButton><Icon><ContentPaste /></Icon></IconButton></Tooltip> */}
  </React.Fragment>
}
