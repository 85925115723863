import { ReactElement } from "react";
import { ProjectTreeItem } from "./projectTreeItem";
import { GRichTreeView, GTreeItemCheckboxState, GTreeItemRenderer, grentonColors } from "@grenton/design-system";

type ProjectComponentTree2Props<T> = {
    items: ProjectTreeItem<T>[];
    itemRenderer: GTreeItemRenderer<ProjectTreeItem<T>>,
    onItemClick: (e:React.MouseEvent, item: ProjectTreeItem<T>, data:any) => void;

    // multi selection props
    selectionEnabled?: boolean;
    selectableItems?: Record<string,GTreeItemCheckboxState>
    selectedItems?: string[]
    onItemSelectionToggle?: (itemId:string, selected:boolean) => void;

    expandedItems: string[]
    onItemExpansionToggle: (itemId:string, expanded:boolean) => void;
};

export function ProjectComponentTree2<T>(props: ProjectComponentTree2Props<T>): ReactElement {
    const {
        items,
        itemRenderer,
        onItemClick,
        onItemSelectionToggle,
        onItemExpansionToggle,
        selectionEnabled,
        expandedItems,
        selectedItems,
        selectableItems
    } = props;

    // const checkboxProps = useMemo(()=>
    //     selectableItems ? Lists.reduce(selectableItems, (id) => ([id,GTreeItemCheckboxState.ENABLED])) : {},
    // [selectableItems])

    return <GRichTreeView       
        onItemClick={onItemClick}
        renderer={itemRenderer}
        items={items}
        sx={{
            '& .object-node[aria-expanded=true]':{background:grentonColors.LabelGreyBackground,borderRadius:'4px'},
            '& li' : { margin:'4px 0px' },
            '& li>div' : { padding:'0 0.25rem'}

            // doing 'active' selection in a different way than mui does it is hard!
            // '& li[aria-selected=true]>div':{backgroundColor:'transparent'},
            // '& li[aria-selected=true] .GTreeItem-Label':{backgroundColor:'red'}
            
        }}
        slotProps={
            {
                "item":{
                    sx:{padding:'0px'}, 
                }
            }
        }
        
        

        /*
        * IMO we should have selection enabled for mainTree and disabled for targetTree.
        * selected item in mainTree should reflect currently edited node.
        * however, since we the same item may appear multiple times in the tree, 
        * we need to select all items that points to the same method/event
        * this is tricky due to different ids.
        */
        disableSelection={false}  
        multiSelect={selectionEnabled}  
        selectedItems={selectedItems}
        expandedItems={expandedItems}
        onItemExpansionToggle={(_,itemId,isExpanded)=>{onItemExpansionToggle(itemId,isExpanded)}}
        checkboxProps={selectableItems}
        checkboxSelection={selectionEnabled}
        onItemSelectionToggle={(_,itemId,selected)=>{
            // due to bug in RichTextView, sometimes itemId is an array (empty?) 
            typeof itemId === 'string' && onItemSelectionToggle && onItemSelectionToggle(itemId,selected)
        }}
    />
}

