import { OUTLET_OBJECT, isParentOrObject, ANONYMOUS_CONTROLLER_SUFFIX } from '@grenton/gm-common';
import { notEmpty } from '@grenton/utils';
import { ProjectObjectImpl, OutletConfigImpl, OutletImpl } from '@grenton/gm-logic';
import { eventNodes } from './eventNodes';
import { methodNode } from './methodNode';
import { outletNode } from './outletNode';
import { ProjectTreeItem, ProjectTreeItemObjectData, ProjectTreeItemData, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';
import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';
import { concatTreeItemId } from './id';
import { MainTreeContext } from './treeContext';

/**
 * for each object we
 * - if object is scriptable, render method nodes,
 * - render its outlets (except "parent" and "object" outlets)
 * -    if outlet has static references, resolve them and render as objectNode
 * -    if outlet has no static references, render them as outletNode
 * - if this object has an anonymous controller, render controller's "object" outlet as outletNode
 */

type Props = {
    ctx: MainTreeContext;
    parentId: string;
    path: string[]; // path can be [objectId] or [parentObjectId,childObjectId] etc
    object: ProjectObjectImpl;
    showProtocolDetails: boolean;
    shortenLabel: boolean;
};

export function objectNode({
    ctx,
    parentId,
    path, // path can be [objectId] or [parentObjectId,childObjectId] etc
    object,
    showProtocolDetails,
    shortenLabel,
}: Props): ProjectTreeItem<ProjectTreeItemObjectData> {
    const id = concatTreeItemId(parentId, object.uuid);

    const objectRefs = ctx.references[object.uuid];
    const objectProtocolMethods = ctx.multiSelectMode
        ? []
        : showProtocolDetails && object.impl.type === 'script'
          ? Object.values(object.api.methods)
                .map((method) =>
                    methodNode({
                        parentId: id,
                        path: [...path, method.id],
                        object,
                        method,
                    }),
                )
                .sort(sortTreeNodes)
          : [];

    const objectProtocolOutlets: ProjectTreeItem<ProjectTreeItemData>[] = Object.values(object.api.outlets)
        .filter((outlet) => !isParentOrObject(outlet.id))
        .map((outlet) => {
            const outletRefs = objectRefs?.[outlet.id];
            const outletConfig = object.init.outlets[outlet.id];

            // here we treat all static refs as 'subobjects'.
            // it is compatible with 2.0, but I do not like the fact that we do not show
            // regular outlet event handlers. with current approach, I need to program each smartpanel button
            // separately, because I do not have access to smartpanel.buttons.onClick handler on UI.
            if (!ctx.showStaticOutlets && outletConfig?.isStatic) {
                // static references render as objects
                return (
                    outletConfig.staticRefs
                        .map(ctx.objectResolver)
                        .filter(notEmpty)
                        // here is additional check for parent-child relation
                        .filter((child) => child.parent === object.uuid)
                        .map((child, index) =>
                            objectNode({
                                ctx,
                                parentId: id,
                                path: [...path, createIndexedOutletId(outlet, outletConfig, index)],
                                object: child,
                                showProtocolDetails: true,
                                shortenLabel: true,
                            }),
                        )
                );
            } else if (ctx.showStaticOutlets || !ctx.multiSelectMode) {
                // regular outlet
                return [outletNode({ ctx, parentId: id, path: [...path, outlet.id], object, outlet, outletRefs })];
            } else {
                // with multiselect enabled, hide regular outlets
                return [];
            }
        })
        .flat();
    //.sort(sortTreeNodes); <- we need to preserve order of static refs (subobjects). TBD: we can sort other outlets

    // scriptableObject is the same object (if it is script type) or its anonymous controller (for non-script objects)
    // generally all non-script objects should have anonymous controller - they are added during import

    const anonymousController = object.impl.type === 'script' ? null : ctx.objectResolver(`${object.uuid}${ANONYMOUS_CONTROLLER_SUFFIX}`);

    const objectProtocolEvents =
        !ctx.multiSelectMode && showProtocolDetails && anonymousController
            ? eventNodes({
                  ctx,
                  parentId: id,
                  path: [anonymousController.uuid, OUTLET_OBJECT],
                  scriptableObject: anonymousController,
                  sourceObject: object,
                  protocol: anonymousController.api.outlets[OUTLET_OBJECT]!.api,
              })
            : [];

    const item: ProjectTreeItem<ProjectTreeItemObjectData, ProjectTreeItemData> = {
        highlight: true,
        id,
        icon: ctx.iconResolver(object) || 'unknown',
        label: shortenLabel ? object.label.split('.').pop()! : object.label, // strip parent label
        sortKey: object.label,
        rootClassName: 'object-node',
        data: {
            type: ProjectTreeItemType.OBJECT,
            path: path,
            objectId: object.uuid,
            rootTag: ctx.rootTag,
            assignedToRootTag: !ctx.rootTag || object.hasTag(ctx.rootTag),
        },
        children: [...objectProtocolMethods, ...objectProtocolOutlets, ...objectProtocolEvents],
    };
    return item;
}

export function createIndexedOutletId(outlet: OutletImpl, outletConfig: OutletConfigImpl, index: number) {
    return outletConfig.staticRefs.length > 1 ? `${outlet.id}[${index + 1}]` : outlet.id;
}
