import { useNavigate } from "react-router-dom";
import { LibraryContextProvider } from "../ui/library";
import { TagContextProvider } from "../ui/tags";
import { useContext, useEffect, useMemo } from "react";
import { ProjectContext } from "./ProjectContext";
import { ROUTE_ROOT } from "../layout";

/**
 * all providers/contexts that require project to be loaded should be created here.
 * it guarantees that children are rendered only when project is loaded
 */
export function ProjectServicesProvider({ children }: { children: React.ReactNode }) {

    const navigate = useNavigate()
    const project = useContext(ProjectContext);

    const projectRelatedServices = useMemo(()=>{
        if (project) {
            // here we can create project-specific services,
            // for example all command handlers to manipulate project should be initialized here
            return {}
        } else {
            return null
        }
    }, [project?.uuid])

    // navigate away if project is not loaded
    useEffect(() => {
        if (!projectRelatedServices) {
            navigate(ROUTE_ROOT)
        }
    }, [projectRelatedServices])

    return (
            projectRelatedServices && <TagContextProvider>
                <LibraryContextProvider>
                    {children}
                </LibraryContextProvider>
            </TagContextProvider>
    )
}