import {ReactElement} from "react";
import {Stack, FormControlLabel, Checkbox} from "@mui/material";
import { useForm } from "react-hook-form";
import { AddUserProps } from "./types";
import GMTextField from "../../../../../ui/controls/GMTextField";
import { ModalFullScreenMobile } from "@grenton/design-system";
import { FORM_FIELD } from '../../consts';
import { InputUser } from "../../../../../security/inputUser";

export function AddUser({ isOpen, onClose, onSave }: AddUserProps): ReactElement {
    const { register, watch, handleSubmit, reset, formState: { errors } } = useForm<InputUser>({
        defaultValues: {
            name: "",
            pwd: "",
            pwdRepeat: ""
        }
    });

    const handleCancel = () => {
        onClose();
        reset();
    };

    const onSubmit = (form: InputUser) => {
        onSave(form);
    };

    const password = watch('pwd');

    return (
        <ModalFullScreenMobile
            isOpen={isOpen}
            onClose={handleCancel}
            onSave={handleSubmit(onSubmit)}
            title="Create new user"
        >
            <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: "25rem" }}>
                <Stack spacing={2}>
                    <GMTextField
                        size="small"
                        label="Name"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        {...register('name', FORM_FIELD.name)}
                    />
                    <GMTextField
                        type="password"
                        size="small"
                        label="Password"
                        error={!!errors.pwd}
                        helperText={errors.pwd?.message}
                        {...register('pwd', FORM_FIELD.password)}
                    />
                    <GMTextField
                        type="password"
                        size="small"
                        label="Password"
                        error={!!errors.pwdRepeat}
                        helperText={errors.pwdRepeat?.message}
                        {...register('pwdRepeat', {
                            required: "Repeat password is required",
                            validate: pwdRepeat => pwdRepeat === password || "Passwords must be the same",
                        })}
                    />
                    <FormControlLabel
                        control={<Checkbox {...register('disabled')} />}
                        label="disabled"
                        labelPlacement="end"
                    />
                </Stack>
            </form>
        </ModalFullScreenMobile>
    );
}
