import { Stack, CircularProgress, Typography } from "@mui/material";

export function Scanning() {
    return <Stack direction="column" sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <CircularProgress />
            <Typography variant="caption">Scanning</Typography>
        </Stack>
}