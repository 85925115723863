import type { ProjectImpl } from '@grenton/gm-logic';
import type { ObjectsByModule } from '../types';

export function getObjectsByModule(project: ProjectImpl): ObjectsByModule[] {
    // TODO we can make it in one pass
    return project.modules.map((module) => ({
        id: module.uuid,
        ref: module.ref,
        objects: project.getAllObjectsByComponent(module.uuid).filter((o) => o.top),
    }));
}
