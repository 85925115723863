import { WorkspaceSvg } from "blockly";
import { BlocklyWorkspace } from "./react-blockly";
import { modestTheme } from "./styles/modest-theme";

/*
 * onWorkspaceChange event is not very reliable - it is fired very often
 * even on mouse click + if this handler is an anonymous function from the parent component,
 * it will trigger re-render and fire hollow event
 *
 * do we need to physically change workspace, or just its content?
 * what about variables?
 * 
 * @param props 
 * @returns 
 */
function BlocklyEditor(props:{
    style?:React.CSSProperties, toolbox:any,
      onAttachWorkspace: (workspace: WorkspaceSvg)=>void
      onDetachWorkspace: ()=>void
    }) {

  return <BlocklyWorkspace
    toolboxConfiguration={props.toolbox}
    style={props.style}
    workspaceConfiguration={{
      theme: modestTheme,
      grid: {
        spacing: 20,
        length: 3,
        colour: "#eee",
        snap: false,
      },
    }}
    onInject={(w: WorkspaceSvg) => props.onAttachWorkspace(w)}
    onDispose={() => props.onDetachWorkspace()}
  /> }

export default BlocklyEditor;