import { ProjectObjectImpl, ProjectImpl } from '@grenton/gm-logic';
import { SelectedObject, SelectedModule } from '../types';
import { CFGTreeItem, CFGTreeItemType } from './types';
import { objectIconResolver, ObjectIconResolver } from '@grenton/gm/ui/icon-resolver/objectIconResolver';
import { ModuleObjectRef } from '@grenton/gm-common/src';

function objectNodes(
    objects: ProjectObjectImpl[],
    iconResolver: ObjectIconResolver,
    selectedObject: SelectedObject | undefined,
    allDisabled: boolean,
): CFGTreeItem[] {
    const objectNode = (object: ProjectObjectImpl): CFGTreeItem => {
        const disabled = allDisabled || (!!selectedObject?.type && selectedObject.type !== object.api.name);
        const item: CFGTreeItem = {
            id: object.uuid,
            label: `${object.label}` /*<Stack direction="row" width="100%" alignItems="center"><Box flexGrow={2}>{object.label}</Box>
                <Box  sx={{color:'#666',fontSize:'0.75em'}}>{object.moduleRef?.objectId}</Box>
                </Stack>,*/,
            sortKey: object.label,
            icon: iconResolver(object),
            disabled,
            data: {
                type: CFGTreeItemType.OBJECT,
                objectId: object.uuid,
                port: object.impl.type === 'module' ? object.impl.componentRef.objectId : '',
                moving: selectedObject?.uuid === object.uuid,
            },
            children: [],
        };
        return item;
    };

    return objects
        .sort((o1, o2) => (o1.impl.componentRef as ModuleObjectRef).objectId.localeCompare((o2.impl.componentRef as ModuleObjectRef).objectId) || 0)
        .map(objectNode);
}

export function configurationTreeModel(
    project: ProjectImpl,
    hideLinked: boolean,
    selectedModule?: SelectedModule,
    allowedModuleType?: string,
    selectedObject?: SelectedObject,
): CFGTreeItem[] {
    const iconResolver = objectIconResolver(project.firmware);
    const objectsByComponent = project.topObjectsByComponent;

    const items = project.modules
        .map((mod) => {
            const disabled = !!allowedModuleType && mod.ref !== allowedModuleType;
            const sortKey = `${mod.ref} (${mod.uuid.substring(0, 8)})`;
            return {
                id: `mod:${mod.uuid}`,
                highlight: true,
                icon: null,
                label: `${mod.ref}`, // <ModuleLabel module={mod}/>,
                sortKey,
                disabled,
                data: {
                    type: CFGTreeItemType.COMPONENT,
                    component: {
                        id: mod.uuid,
                        ref: mod.ref,
                    },
                    linkedTo: project.hardware.findHardwareMappedToVirtual(mod.uuid) || null,
                    linking: selectedModule?.uuid === mod.uuid,
                },
                children: objectNodes(objectsByComponent[mod.uuid] || [], iconResolver, selectedObject, disabled),
            };
        })
        .filter((mod) => !mod.data.linkedTo || !hideLinked);

    return items as CFGTreeItem[];
}
