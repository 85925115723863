import {ReactElement, useMemo} from "react";
import {Stack, FormControlLabel, Checkbox, MenuItem} from "@mui/material";
import { useForm } from "react-hook-form";
import { AddLanguageProps } from "./types";
import GMTextField from "../../../../../ui/controls/GMTextField";
import { ModalFullScreenMobile } from "@grenton/design-system";
import { FORM_FIELD } from '../../consts';
import {I18nLangCodeEnum, I18nLang} from "@grenton/gm-common";
import {i18nLangNameMap} from '../../..';

export function AddLanguage({ isOpen, onClose, onSave }: AddLanguageProps): ReactElement {
    const { register, watch, handleSubmit, reset, formState: { errors } } = useForm<I18nLang>({
        defaultValues: {
            code: I18nLangCodeEnum.EN,
            isDefault: false,
        }
    });

    const menuItems = useMemo(() => Object.values(I18nLangCodeEnum).map(code => (
        <MenuItem key={code} value={code}>{i18nLangNameMap.get(code)}</MenuItem>
    )), []);

    const handleCancel = () => {
        onClose();
        reset();
    };

    const onSubmit = (form: I18nLang) => {
        onSave(form);
    };

    return (
        <ModalFullScreenMobile
            isOpen={isOpen}
            onClose={handleCancel}
            onSave={handleSubmit(onSubmit)}
            title="Add new language"
        >
            <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: "25rem" }}>
                <Stack spacing={2}>
                    <GMTextField
                        select
                        size="small"
                        label="Language"
                        error={!!errors.code}
                        helperText={errors.code?.message || ""}
                        {...register('code', FORM_FIELD.code)}
                        value={watch('code')}
                    >
                        {menuItems}
                    </GMTextField>
                    <FormControlLabel
                        control={<Checkbox {...register('isDefault')} />}
                        label="default"
                        labelPlacement="end"
                    />
                </Stack>
            </form>
        </ModalFullScreenMobile>
    );
}
