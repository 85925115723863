import { Button } from "@mui/material";
import { PropertyValueField } from "../../../../../ui/components/propertyValueField";
import { schema } from "@grenton/gm-common";
import { useState } from "react";
import { InlineEditPane } from "../misc/InlineEditPane";

type Props = {
    spec:schema.Feature
    onSubmit: (value: schema.PropertyValue) => void
    onClose: () => void
}

export function FeatureValuePane({spec,onSubmit,onClose}: Props) {

    const [value,setValue] = useState<schema.PropertyValue>(null)

    return  <InlineEditPane onClose={onClose}>
        <PropertyValueField type={spec.type} value={value} label="Value to set" onChange={setValue} />
        <Button variant="contained" onClick={()=>onSubmit(value)}>Set</Button>
    </InlineEditPane>
}