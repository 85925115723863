import { ArrowDropDown } from "@mui/icons-material";
import { InputAdornment, SxProps, TextField } from "@mui/material";
import { ReactNode } from "react";

type Props = {
    sx: SxProps,
    disabled?: boolean,
    value?: unknown,
    onClick: () => void
    label?: ReactNode
    required?:boolean
}

export function GPseudoSelect({ sx, required, disabled, value, label, onClick }: Props) {
    return <TextField
        label={label}
        disabled={disabled}
        required={required}
        value={value}
        size="small"
        sx={sx}
        inputProps={{ style: { cursor: 'pointer' } }}
        InputProps={{
            readOnly: true,
            style: { cursor: 'pointer' },
            endAdornment: (
                <InputAdornment position="end">
                    <ArrowDropDown />
                </InputAdornment>
            ),
        }}
        onClick={(e) => {
            e.preventDefault();
            onClick()
        }}
        onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                onClick()
            }
        }}
    />
}