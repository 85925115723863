import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { calculateProjectObjectReferences, ObjectReferences2, ReferencesHolder } from "@grenton/gm-logic";
import { useProject } from "../ui";

type ReferenceTrackerContextType = {
    references : ObjectReferences2,
    toggleTracking: (id: string, track:boolean) => void,
}

export const ReferenceTrackerContext = createContext<ReferenceTrackerContextType>({references:{},toggleTracking:()=>{}});

export const useReferenceTracker = () => {
    return useContext(ReferenceTrackerContext);
}

export function ReferenceTrackerProvider({children}: {children: React.ReactNode}) {

    const project = useProject()

    // TODO when app reloads in dev mode, the state is gone?
    const [trackedObjects, setTrackedObjects] = useState<Set<string>>(new Set())
    const refHolder = useRef(ReferencesHolder.empty)

    useEffect(() => {
        setTrackedObjects(new Set())
        refHolder.current = ReferencesHolder.empty
    }, [project.uuid])

    refHolder.current = calculateProjectObjectReferences({project, onlyForObjects:trackedObjects, previousReferences: refHolder.current})
    const toggleTracking = (id:string, track:boolean) => {
        if (track) {
            if (!trackedObjects.has(id)) {
                setTrackedObjects(new Set(trackedObjects).add(id))
            }
        } else {
            if (trackedObjects.has(id)) {
                const s = new Set(trackedObjects)
                s.delete(id)
                setTrackedObjects(s)
            }
        }
    }

    const context = useMemo(()=>{
        return {references:refHolder.current?.refs, toggleTracking}
    },[refHolder.current])

    return (
        <ReferenceTrackerContext.Provider value={context}>
            {children}
        </ReferenceTrackerContext.Provider>
    )
}