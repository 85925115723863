import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeItemObjectData, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';
import { ProjectObjectImpl } from '@grenton/gm-logic';
import { GIconName } from '@grenton/gm/ui/icons';
import { isParentOrObject } from '@grenton/gm-common/src';
import { notEmpty } from '@grenton/utils';

export function objectNodes(
    parentId: string,
    objects: ProjectObjectImpl[],
    filter: (obj: ProjectObjectImpl) => boolean,
    iconResolver: (obj: ProjectObjectImpl) => GIconName | null,
    objectResolver: (objectId: string) => ProjectObjectImpl | undefined,
): ProjectTreeItem<ProjectTreeItemData>[] {
    const objectNode = (parentId: string, object: ProjectObjectImpl): ProjectTreeItem<ProjectTreeItemObjectData> => {
        const id = `${parentId}/${object.uuid}`;
        const active = filter(object);
        const children = Object.values(object.api.outlets)
            .filter((outlet) => !isParentOrObject(outlet.id) && object.init.outlets[outlet.id]?.isStatic)
            .map((outlet) => object.init.outlets[outlet.id]?.staticRefs ?? [])
            .flat()
            .map(objectResolver)
            .filter(notEmpty);

        return {
            id,
            label: object.label,
            icon: iconResolver(object) || 'unknown',
            sortKey: object.label,
            data: {
                active,
                path: [object.uuid],
                type: ProjectTreeItemType.OBJECT,
                objectId: object.uuid,
            },
            children: children.filter(filter).map((obj) => objectNode(id, obj)),
        };
    };
    // we want to show objects with compatible api or parents that have compatible children
    return objects.map((obj) => objectNode(parentId, obj)).filter((node) => node.data.active || node.children?.length);
}
