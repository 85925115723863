import { Box, Toolbar, Button, IconButton } from "@mui/material";
import React, { memo, useMemo } from "react";
import BlocklyEditor from "./visual/BlocklyEditor";
import { TargetObjectSelector } from "./components";
import type { TargetObjectSelectorClickHandler } from "./components";
import TabPanel from "../ui/components/TabPanel";
import {toolbox} from "./visual/toolbox/toolbox";
import Mask from "../ui/components/Mask";
import LuaCodeEditor from "./code/LuaCodeEditor";
import { ObjectID, ScriptFormat } from "@grenton/gm-common";
import { ScriptEditorController } from "./backend/script-editor-controller";
import { useSubjectState } from "../utils";
import { ActionModeEditor } from "./actions/ActionModeEditor";
import { ProjectImpl } from "@grenton/gm-logic";
import { strech } from "@grenton/gm/ui";
import { GSplitter, grentonColors } from "@grenton/design-system";
import { DeleteOutline } from "@mui/icons-material";
import { EditModeSelector } from "./components/editModeSelector";

function SwitchModeMask(props:{currentMode:ScriptFormat, editorMode:ScriptFormat, onSwitch:()=>void}) {
  return props.currentMode !== props.editorMode ? 
  <Mask content={<Button sx={{p:2,backgroundColor:'#00000010'}} onClick={props.onSwitch}>Switch to {props.editorMode} mode</Button>} /> : null
}

const TargetObjectSelector_memo = memo(TargetObjectSelector)

export default function ScriptEditor(props: {
  project:ProjectImpl,
  selfObjectID?:ObjectID,
  hide?: boolean,
  controller: ScriptEditorController,
  title?: string,
  toolbar?: React.ReactNode,
  onLogicNodeClick: TargetObjectSelectorClickHandler
}) {
  const [editMode] = useSubjectState(props.controller.editMode)
  const tab = useMemo(():number=>{
      switch (editMode.viewed) {
        case 'actions' : return 0;
        case 'visual' : return 1;
        case 'lua' : return 2;
        default:
          return 1
    }
  }, [editMode.viewed])

  return (
    <Box sx={
      { visibility: props.hide ? 'hidden' : 'visible', display:'flex', flexDirection: 'column', flexGrow: 1, ...strech }
    }>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Toolbar variant="dense" sx={{ flexGrow: 2 }}>{props.toolbar}</Toolbar>
          <EditModeSelector viewed={editMode.viewed} active={editMode.edited} onChange={mode=>props.controller.onPreviewMode(mode)} />
          <IconButton disabled sx={{mr:1,ml:1}}><DeleteOutline/></IconButton>
        </Box>
      </Box>

      <GSplitter minWidths={[0,220]}
          direction={"horizontal"}
          initialSizes={[80,20]}
        >

      <Box sx={{display:'flex',height:'100%',background:grentonColors.backgrounds_workspace}}>
      <TabPanel value={tab} style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', position: 'relative', overflow:'auto' }} index={0}>
        <SwitchModeMask editorMode='actions' currentMode={editMode.edited} onSwitch={() => props.controller.onSwitchMode('actions')}/>
        <ActionModeEditor active={editMode.edited === 'actions'} controller={props.controller.actions} />
      </TabPanel>

      <TabPanel value={tab} style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', position: 'relative' }} index={1}>
      <SwitchModeMask editorMode='visual' currentMode={editMode.edited} onSwitch={() => props.controller.onSwitchMode('visual')}/>
          <BlocklyEditor
            style={{ flexGrow: 1, width: '100%' }}
            toolbox={toolbox}
            onAttachWorkspace={w=>props.controller.visual.workspaceRef.attachWorkspace(w)}
            onDetachWorkspace={()=>props.controller.visual.workspaceRef.detachWorkspace}
          />
      </TabPanel>

      <TabPanel value={tab} style={{ flexGrow: 1, flexDirection: 'column', position: 'relative' }} index={2}>
        <SwitchModeMask editorMode='lua' currentMode={editMode.edited} onSwitch={() => props.controller.onSwitchMode('lua')}/>
        <LuaCodeEditor controller={props.controller.code.editorController}/>
      </TabPanel>
      </Box>

      <TargetObjectSelector_memo
        selfObjectID={props.selfObjectID} 
        actionsMode={editMode.edited === 'actions'} 
        onNodeClick={props.onLogicNodeClick}
        project={props.project}/>
      </GSplitter>
    </Box>)
}
