import { EditableList } from "../../editableList";
import { ObjectOutletForm } from "@grenton/gm-logic";
import { ApiItemListProps, ApiListItem, ApiListItemRenderer } from "../../common";



// function OutletItemRenderer({ item, shrunk }: { item: OutletItem, shrunk: boolean }) {
//     return (
//         <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center', height: 44, overflow: 'hidden' }}>
//             <Typography variant="l" sx={{ flexGrow: 1, width: shrunk ? 'auto' : 250, minWidth: shrunk ? 0 : 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.data.spec.label||item.data.spec.id}</Typography>
//             {!shrunk && <Typography variant="s" sx={{ flexGrow: 1 }}>{item.data.spec.api?.id}</Typography>}
//         </Stack>
//     )
// }

/**
 * for virtual objects, some outlets may be readOnly (static), other editable
 * for script objects, rather all are editable?
 * @param props
 * @returns
 */
export function OutletList({items, editedItemId, onAdd, onEdit, onDelete, children }: ApiItemListProps<ObjectOutletForm> ) {

    // can be moved up
    const onEditItem = (id: string) => {
        onEdit && onEdit(id)
    }

    const listItems : ApiListItem<ObjectOutletForm>[] = Object.entries(items).map(([id,form]) => ({ 
        id, 
        selectable: form.editable || form.config.editable, 
        removeable: form.editable,
        form
    }))


    return (<EditableList
        shrunkWidth={250}
        selectedItem={editedItemId||undefined}
        onSelectItem={onEditItem}
        onDeleteItem={onDelete}
        onNewItem={onAdd}
        items={listItems}
        renderer={ApiListItemRenderer}>

        {children}

    </EditableList>

    )
}