import { Box, DialogTitle } from "@mui/material"
import { GCloseDialogButton } from "./GCloseDialogButton"
import { themeVariant } from "../../variant"
import { spacing } from "../size"

type Props = {
    onClose? : ()=>void
    children?: React.ReactNode
}

export function GDialogTitle({children, onClose}:Props) {
    return <DialogTitle sx={{display:'flex',alignItems:'center'}}>
        <Box sx={{flexGrow:1}}>{children}</Box>
        {onClose &&  <GCloseDialogButton onClick={onClose} sx={themeVariant === 'kris' ? {} : {
            marginLeft: `${spacing.S}px`,
            marginRight: `-${spacing.S}px`
        }}/>} 
    </DialogTitle>
}