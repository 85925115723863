import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeFilterFunction, ProjectTreeFilterResult } from './projectTreeItem';
import { notEmpty } from '@grenton/utils';

export function filterProjectTreeItems(
    nodes: ProjectTreeItem<ProjectTreeItemData>[],
    filterFn: ProjectTreeFilterFunction<ProjectTreeItemData>,
): ProjectTreeItem<ProjectTreeItemData & { tag?: string }>[] {
    return nodes
        .map((node) => ({ node, result: filterFn(node) }))
        .filter(({ result }) => result !== ProjectTreeFilterResult.EXCLUDE)
        .map(({ node, result }) => {
            switch (result) {
                case ProjectTreeFilterResult.INCLUDE_WITH_CHILDREN:
                    return node;
                case ProjectTreeFilterResult.INCLUDE_NOT_EMPTY: {
                    if (!node.children) return undefined;
                    const children = filterProjectTreeItems(node.children, filterFn);
                    return children.length ? { ...node, children } : undefined;
                }
                default: {
                    if (!node.children) return node;
                    const children = filterProjectTreeItems(node.children, filterFn);
                    return { ...node, children };
                }
            }
        })
        .filter(notEmpty);
}
