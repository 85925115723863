import { eventNode } from './eventNode';
import { ProjectTreeItem, ProjectTreeItemDataExpand, ProjectTreeItemEventData, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';
import { ProjectObjectImpl, ProjectObjectApi } from '@grenton/gm-logic';
import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';
import { MainTreeContext } from './treeContext';

type Props = {
    ctx: MainTreeContext;
    parentId: string;
    path: string[];
    // logical owner of event handlers (always scriptable)
    scriptableObject: ProjectObjectImpl;
    // source of events, it can be a hardware object
    sourceObject: ProjectObjectImpl;
    // which API defines this event (it can be API of sourceObject or API of an outlet)
    protocol: ProjectObjectApi;
};

export function eventNodes({
    ctx,
    parentId,
    path,
    // logical owner of event handlers (always scriptable)
    scriptableObject,
    // source of events, it can be a hardware object
    sourceObject,
    // which API defines this event (it can be API of sourceObject or API of an outlet)
    protocol,
}: Props): ProjectTreeItem<ProjectTreeItemEventData | ProjectTreeItemDataExpand>[] {
    const moreId = parentId + `.*`;

    const expandableEventList = Object.values(protocol.events).some((e) => e.spec.primary === true);
    const events = Object.values(protocol.events)
        .map((event) => ({
            id: event.id,
            name: event.name,
            primary: event.spec.primary === true,
            hasCode: scriptableObject.scripts.scripts[path.slice(1).join('.') + '.' + event.id]?.hasContent === true,
        }))
        // use expandable list only if protocol has some events marked as primary!
        .filter((e) => !expandableEventList || ctx.fullyExpandedItems[moreId] || e.primary || e.hasCode);

    const nodes: ProjectTreeItem<ProjectTreeItemEventData | ProjectTreeItemDataExpand>[] = events
        .map((event) => eventNode({ parentId, scriptableObject, sourceObject, path: [...path, event.id], event }))
        .sort(sortTreeNodes);

    if (expandableEventList) {
        if (nodes.length < Object.values(protocol.events).length) {
            nodes.push({
                id: moreId,
                label: 'more',
                icon: null,
                sortKey: '',
                data: {
                    type: ProjectTreeItemType.MORE,
                },
                children: [],
            });
        } else {
            nodes.push({
                id: moreId,
                label: 'less',
                icon: null,
                sortKey: '',
                data: {
                    type: ProjectTreeItemType.LESS,
                },
                children: [],
            });
        }
    }

    return nodes;
}
