import { TextField, MenuItem, TextFieldProps } from "@mui/material";

type Props = TextFieldProps & {
    options: { name: string, value: any, disabled?:boolean }[];
};

export function GEnumSelector({ options, ...other }: Props) {
    return (
        <TextField
            select
            {...other}
        >
            {options.map((option) => (
                <MenuItem disabled={option.disabled} key={option.value} value={option.value}>{option.name}</MenuItem>))}
        </TextField>
    )
}