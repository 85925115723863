import { useState, ReactElement } from "react";
import { Toolbar, Typography, Box, Button } from "@mui/material";
import { Sync } from "@mui/icons-material";
import { DrawerAwareAppBar, grentonColors } from "@grenton/design-system"
import type { UploadType } from "../../types";
import appEnv from "@grenton/gm/app-env";
import { GrentonLogo } from "@grenton/design-system";
import { SendMenu } from "../sendMenu";

type AppBarProps = {
    open: boolean
    toggleDrawer: () => void
    drawerWidth: number
    projectModified: boolean
    onUpload: (ch: UploadType) => void
    projectTitle?: string
    projectLoaded: boolean
}

function ProjectTitle({ title }: { title: string }): ReactElement {
    return <Box sx={{ flexGrow: 0, padding: '0 1rem' }}>
        <Typography sx={{ padding: '0.5em 1em' }} variant="l" noWrap>{title}</Typography>
    </Box>
}

export function TopAppBar({ projectLoaded, projectTitle, open, drawerWidth, onUpload }: AppBarProps): ReactElement {

    const [sendMenuAnchorEl, setSendMenuAnchorEl] = useState<null | HTMLElement>(null);
    const sendMenuOpen = Boolean(sendMenuAnchorEl);

    function closeSendMenu(): void {
        setSendMenuAnchorEl(null)
    }

    function closeAndUpload(upload: UploadType): void {
        closeSendMenu();
        onUpload(upload)
    }

    return (
        <DrawerAwareAppBar sx={{height:'48px',minHeight:'48px !important'}}  position="absolute" open={open} drawerWidth={drawerWidth}>
            <Toolbar
             variant="dense"
                sx={{
                    minHeight:'48px',
                    background: grentonColors.backgrounds_menu,
                    color: grentonColors.Font_Black,
                    boxShadow: 'none',
                    borderBottom: `1px solid ${grentonColors.Line_Menu}`,
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <GrentonLogo style={{ marginLeft:-20,height: 48 }} />
                <Typography variant="xs" mr={2} ml={2}>ver {appEnv?.APP_VERSION}</Typography>
                
                {projectLoaded && projectTitle && <ProjectTitle title={projectTitle} />}
                <Box sx={{ flexGrow: 1 }} />
                {projectLoaded &&
                    <Button color="white" size="small" onClick={(e) => setSendMenuAnchorEl(e.currentTarget)} endIcon={<Sync />}>Send</Button>
                }
            </Toolbar>
            <SendMenu 
                menuExportAnchorEl={sendMenuAnchorEl} 
                menuExportOpen={sendMenuOpen} 
                onClose={closeSendMenu} 
                onSelect={closeAndUpload} />

        </DrawerAwareAppBar>)
}
