import { useCallback, useState } from "react";
import type { ReactElement } from "react";
import {Alert, Box, IconButton, Stack} from "@mui/material";
import { Add } from "@mui/icons-material";
import {SectionContent} from '@grenton/design-system';
import {UserAccordion, AddUser, EditUser, DeleteUser,} from "./components";
import { useObservableOr } from "../../../utils";
import type {ProjectUserImpl } from "@grenton/gm-logic";
import { InputUser } from "../../../security/inputUser";
import { UserDeleteCommand, UserEditCommand } from "../../../security";
import { useService } from "@grenton/gm/providers";

export function ProjectUsers(): ReactElement {
    const { securityController, commandDispatcher } = useService();
    const [modalState, setModalState] = useState({
        add: false,
        edit: false,
        delete: false,
    });
    const [holdUser, setHoldUser] = useState<ProjectUserImpl | null>(null);
    const [users] = useObservableOr(securityController.getUsers(), []);
    const isUsers = Boolean(users.length);
    function toggleModal(modalName: keyof typeof modalState): void {
        setModalState(prevState => ({ ...prevState, [modalName]: !prevState[modalName] }));
    }


    const handleAddUser = useCallback((data: InputUser) => {
        toggleModal('add');
        const { name, pwd, pwdRepeat, disabled } = data;
        commandDispatcher.execute(
            new UserEditCommand({
                existing: false,
                name,
                disabled,
                pwd,
                pwdRepeat
            })
        );
    }, [commandDispatcher]);


    function handleEditUser(data: ProjectUserImpl): void {
        toggleModal('edit');
        setHoldUser(data);
    };

    const onSubmitEditUser = useCallback(({ user, changePassword }: { user: ProjectUserImpl, changePassword: boolean }): void => {
        toggleModal('edit');
        const { name, pwd , disabled } = user;

        commandDispatcher.execute(
            new UserEditCommand({
                existing: true,
                name,
                changePassword,
                pwd,
                pwdRepeat: pwd,
                disabled
            })
        );
    }, [holdUser]);

    function handleDeleteUser(user: ProjectUserImpl): void {
        setHoldUser(user);
        toggleModal('delete');
    }

    const confirmHandleDeleteUser = useCallback((): void => {
        if (holdUser) {
            commandDispatcher.execute(new UserDeleteCommand(holdUser.name));
            toggleModal('delete');
        }
    }, [holdUser]);

    return (
        <SectionContent>
            <Stack>
                <Box display="flex" justifyContent="flex-end" padding={1}>
                    <IconButton onClick={() => toggleModal('add')}><Add /></IconButton>
                    <AddUser isOpen={modalState.add} onClose={() => toggleModal('add')} onSave={handleAddUser} />
                    {holdUser && (
                        <EditUser isOpen={modalState.edit} onClose={() => toggleModal('edit')} user={holdUser} onSubmitEditUser={onSubmitEditUser} />
                    )}
                    <DeleteUser
                        isOpen={modalState.delete}
                        onClose={() => toggleModal('delete')}
                        onDelete={confirmHandleDeleteUser}
                    />
                </Box>
                {!isUsers && <Alert severity="warning">You need to create a new user.</Alert>}
                {isUsers && users.map((user) => (
                    <UserAccordion
                        key={user.name}
                        user={user}
                        handleEditUser={handleEditUser}
                        handleDeleteUser={handleDeleteUser}
                    />
                ))}
            </Stack>
        </SectionContent>
    );
}
