import type { ReactElement } from 'react';
import { Avatar, Box, ButtonBase, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography, styled } from '@mui/material';
import type { MenuItem } from './types';
import { menuItems } from './consts';
import { sizeRem, sizes } from '@grenton/design-system/src/theme2/size';
import { GButtonBar, grentonColors } from '@grenton/design-system';
import { GMUser } from '@grenton/gm/auth';
import { UploadType } from '../../types';
import { NotificationsOutlined, Menu, BrushOutlined } from '@mui/icons-material';
import appEnv from '@grenton/gm/app-env';

const dividerColor = '#626262';

const MenuOptions = styled(List)(({ theme }) => ({
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    gap: theme.spacing(0.5),
    '& .MuiListItemButton-root': {
        padding: theme.spacing(0.8),
        borderRadius: '2em',
        //height: '2.2em', //otherwise height is different w and w/o text
        '&.Mui-selected': {
           // backgroundColor: grentonColors.black['5%']
           backgroundColor: theme.palette.primary.main
        },
        '&.Mui-selected:hover': {
            // backgroundColor: grentonColors.black['5%']
            backgroundColor:theme.palette.primary.light
        }
    },
    '& .MuiListItemButton-root:hover': {
        backgroundColor:theme.palette.grey[600]
    },
    '& .MuiListItemText-root': {
        margin: 0,
        paddingLeft: sizeRem(8),
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        fontSize: sizeRem(sizes.XL),
        color: theme.palette.white.light,
        '& .MuiSvgIcon-root': {
            width: '1.1em',
            height: '1.1em'
        }
    }
}));

type MenuProps = {
    open: boolean
    onUpload: (ch: UploadType) => void
    user?: GMUser;
    projectLoaded: boolean;
    projectName: string;
    selected: string;
    onSelect: (option: string) => void;
    onShowNotifications: () => void;
    toggleDrawer: () => void;
    withToolbar: boolean;
};


function AvatarButton({ user, onClick }: { user: GMUser, onClick: () => void }) {
    return <ButtonBase onClick={onClick}><Avatar src={user.picture} /></ButtonBase>
}

function StyleGuideButton({onClick}: {onClick: () => void}){
    return  <IconButton color="inherit" onClick={onClick}><BrushOutlined /></IconButton>
}

function NotificationsButton({onClick}: {onClick: () => void}){
    return  <IconButton color="inherit" onClick={onClick}><NotificationsOutlined /></IconButton>
}

function MenuDivider() {
    return <Divider color={dividerColor} sx={{width:'100%', marginTop:1, marginBottom:1}}/>
}

export function MainMenu({ user, open, projectLoaded, selected, onSelect, toggleDrawer, onShowNotifications }: MenuProps): ReactElement {

    function renderListItem({ id, icon, tooltip, label }: MenuItem, i: number, open: boolean) {
        return (
            <ListItemButton
                key={i}
                selected={id === selected}
                onClick={() => onSelect(typeof id === 'string' ? id : id[0]!)}>
                <Tooltip title={tooltip}>
                    <ListItemIcon>{icon}</ListItemIcon>
                </Tooltip>
                {open && <ListItemText primaryTypographyProps={{ variant: 'l' }} primary={label} />}
            </ListItemButton>
        )
    };

    return (
        <Stack sx={{
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'start',
            backgroundColor:grentonColors.kris_blackish,
            color:grentonColors.Font_White
        }}>

            <Stack sx={{ 
                width:'100%',
                flexDirection: 'column',
                alignItems: 'start',
                padding:1.5
                }}>
                <IconButton sx={{color:'inherit'}} onClick={() => toggleDrawer()}>
                    <Menu />
                </IconButton>
            </Stack>

            <Stack sx={{flexGrow:2,flexDirection:'column',alignSelf:'stretch', alignItems:'center', padding:1.5}}>
                <MenuOptions>
                    {menuItems(projectLoaded).map((item, i) => item === '-' ? <MenuDivider key={i} /> : renderListItem(item, i, open))}
                </MenuOptions>
            
                {/* vertical spacer */}
                <Box sx={{ flexGrow: 2 }} />

                
                {!open && <StyleGuideButton onClick={() => onSelect('ui')} />}
                {!open && <NotificationsButton onClick={onShowNotifications}/>}
            </Stack>

            <GButtonBar
                fullWidth
                topBorderColor={dividerColor}
                sx={{padding: 1.5}}
                start={user && <AvatarButton user={user} onClick={() => onSelect('/user')} />}
                end={<>
                    <NotificationsButton onClick={onShowNotifications}/>
                    <StyleGuideButton onClick={() => onSelect('ui')} />
                </>}
            />
            {false && <Typography sx={{color:grentonColors.black['50%'], position:'absolute',right:2,bottom:2}} variant="xs" mr={0}>{appEnv?.APP_VERSION}</Typography> }
        </Stack>
    )
}
