import { z } from 'zod';

export const GMUserData = z.object({
    cached_at: z.string(),
    sub: z.string(),
    email: z.string(),
    picture: z.string().optional(),
    picture_base64: z.string().optional(),
});

export type GMUser = z.infer<typeof GMUserData>;
