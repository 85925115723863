import { type ReactElement, useEffect, useState } from "react";
import { getObjectsByModule } from './utils';
import { Alert, Box, Button, Stack } from "@mui/material";
import { useProject } from "@grenton/gm/ui";
import { useLibraryContext } from "@grenton/gm/ui/library";
import { NewVer } from "./NewVer";
import { GButtonBar } from "@grenton/design-system";

export function PreparationPage(): ReactElement {
    
    const project = useProject();
    const [nodeObjects, setNodeObjects] = useState(() => getObjectsByModule(project));
    const [isModules, setIsModules] = useState(false);
    const { openLibrary } = useLibraryContext();

    useEffect(() => {
        const nodeObjects = getObjectsByModule(project);
        setNodeObjects(nodeObjects);
        setIsModules(Boolean(nodeObjects?.length))
    }, [project]);

    return (
        <Stack sx={{ height: '100%', overflow: 'hidden', background: '#fff' }}>
            <Box sx={{ flexGrow: 2, overflow: 'auto', p:1 }}>
                <NewVer
                    objectsByModule={nodeObjects}
                    project={project} />

                {!isModules &&
                    <Alert severity="warning" style={{ justifyContent: "center" }}>You need add minimum one module.</Alert>}

            </Box>
            <GButtonBar start={<Button onClick={() => openLibrary()}>Add</Button>} />

        </Stack>

    )
}
