import { Box, Stack, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { grentonColors } from "../colors";

export type GButtonBarProps = {
    start?:ReactNode
    end?:ReactNode
    sx?: SxProps<Theme>
    fullWidth?:boolean
    topBorder?:boolean
    topBorderColor?:string
}

export function GButtonBar({start,end,sx,fullWidth,topBorderColor,topBorder=true}:GButtonBarProps) {
    return <Stack className="GButtonBar" sx={(_)=>({
                    width:fullWidth?'100%':'auto', 
                    minHeight: '78px',
                    //boxSizing: 'content-box'
                
            })}>
            <Box sx={{visibility:topBorder?'visible':'hidden',flexGrow:1,height:'1px',maxHeight:'1px',mx:1,background:topBorderColor||grentonColors.Line_Menu}}></Box>
            <Stack
            sx={{
                gap:1,
                padding: 1.5,
                flexDirection:'row',
                flexGrow:1,
                alignItems:'center',
                whiteSpace: 'nowrap',   // otherwise buttons inside wraps text
                overflow: 'hidden',
                ...sx
            }}>
            {start}
            <Box sx={{flexGrow:1}}/>
            {end}
        </Stack>
    </Stack>
}