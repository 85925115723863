import { ProjectImpl } from "@grenton/gm-logic";
import { Cluster } from "@grenton/gm-logic";
import { SelectedModule } from "../types";
import { ProjectUserCredentials } from "@grenton/gm-common";
import React from "react";
import { HWTreeItemType, HWTreeItem, CLU_ACTION_ADD_OR_REMOVE } from "./types";
import { HardwareTree } from "./HardwareTree";
import { hardwareTreeModel } from "./hardwareTreeModel";
import { Scanning } from "./Scanning";


// function hwNodeRenderer(node: ProjectTreeItem<HWTreeItemData>,
//     _onStartMapping: (node: ProjectTreeItem<HWTreeItemData>) => void,
//     _onDeleteMapping: (uuid: string) => void,
//     _onRemoveClu: (cluId: string) => void,
//     _onImportFromClu: (clu: HWCluImpl) => void,
//     _operation: 'import' | 'add'
// ): JSX.Element {



//     // const moduleAction = (node: ProjectTreeItem<HWModuleItemData>) => {
//     //     const projModule = node.data.projModule
//     //     if (projModule) {
//     //         return <LinkMarker label={projModule.uuid} onDelete={() => onDeleteMapping(projModule.uuid)} />
//     //     } else if (!node.disabled) {
//     //         return <LinkButton selected={node.data.linking} onClick={() => {
//     //             onStartMapping(node)
//     //         }} />
//     //     }
//     // }

//     return (<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>

//         {node.data.type === HWTreeItemType.CLU || node.data.type === HWTreeItemType.MODULE ?
//             <Box sx={{
//                 marginRight: 1,
//                 fontWeight: 'bold',
//                 fontSize: '2rem',
//                 color: (node.data.type === HWTreeItemType.CLU && node.data.clu.online) || (node.data.type === HWTreeItemType.MODULE && node.data.module.online) ? 'green' : 'red'
//             }}>{'\u2022'}</Box>
//             : null}

//         <Box sx={{ flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}>
//             {node.label || node.id}
//         </Box>
// {/* 
//         {node.data.type === HWTreeItemType.CLU ? cluAction(node.data.clu) : null}
//         {node.data.type === HWTreeItemType.MODULE ? moduleAction(node as ProjectTreeItem<HWModuleItemData>) : null} */}
//     </Box>)
// }

export function HardwarePane(props: {
    project: ProjectImpl,
    selectedModule?: SelectedModule,
    hardware: Cluster[],
    onDeleteMapping: (uuid: string) => void,
    onStartMapping: (node: HWTreeItem) => void,
    onRemoveClu: (cluId: string) => void,
    onImportFromClu: (cluId: string, credentials?: ProjectUserCredentials) => void
}) {

    const nodes = hardwareTreeModel(props.hardware, props.project,
        props.selectedModule?.belongsTo === 'hardware' ? props.selectedModule : undefined,
        props.selectedModule?.belongsTo === 'project' ? props.selectedModule.type : undefined
    )

    const hasCLUs = (projects: Cluster[]) => {
        for (const p of projects) {
            if (p.configuration?.clus?.length) return true
        }
        return false
    }

    const onItemClick = (e: React.MouseEvent<Element, MouseEvent>, item: HWTreeItem, data: any) => {
        if (data) {
            e.stopPropagation()
            switch (item.data.type) {
                case HWTreeItemType.CLU: {
                    if (data === CLU_ACTION_ADD_OR_REMOVE) {
                        if (item.data.clu.imported) {
                            props.onRemoveClu(item.data.clu.id)
                        } else {
                            props.onImportFromClu(item.data.clu.id)
                        }
                    }
                    break
                }
                case HWTreeItemType.MODULE: {
                    const projModule = item.data.projModule
                    if (projModule) {
                        props.onDeleteMapping(projModule.uuid)
                    } else if (!item.disabled) {
                        props.onStartMapping(item)
                    }
                    break
                }
            }
        }
    }


    return hasCLUs(props.hardware) ? 
            <>
            <HardwareTree
                defaultExpanded={nodes.map(n => n.id)}
                items={nodes} 
                onItemClick={onItemClick} />
                 </>
        : 
        <Scanning/>
}
