import { Sync } from "@mui/icons-material";
import { Badge, Button, ButtonProps, Link, Stack } from "@mui/material";

function SampleButton(props:{icon?:boolean,color?: ButtonProps['color'], size?: ButtonProps['size']}) {
    return <Button
                color={props.color}
                size={props.size}
                endIcon={props.icon ? <Sync />: null}>Button {props.color} {props.size}</Button>
}

export function ButtonsDemo() {
    return  <Stack gap={1} alignItems={"start"} direction={"row"}>
        {/* <Stack gap={1} alignItems={"start"}>
            <Button variant="text" color="secondary">Button variant=text secondary</Button>
            <Button variant="outlined"  color="secondary">Button variant=outlined secondary</Button>
        </Stack> */}
        <Stack gap={1} alignItems={"start"} >
            <SampleButton color="white"/>
            <SampleButton color="white" icon={true}/>
            <Badge badgeContent={'!'}>
                <SampleButton color="white" icon={true}/>
            </Badge>
            
            <SampleButton color="greyish"/>
            <SampleButton color="greyish" icon={true}/>

            <SampleButton color="primary"/>
            <SampleButton color="primary" icon={true}/>

            <SampleButton color="secondary"/>
            <SampleButton color="secondary" icon={true}/>
        </Stack>
        <Stack gap={1} alignItems={"start"}>
            <SampleButton color="white" size="small"/>
            <SampleButton color="white" size="small" icon={true}/>
            <Badge badgeContent={'!'}>
                <SampleButton color="white" size="small" icon={true}/>
            </Badge>

            <SampleButton color="greyish" size="small"/>
            <SampleButton color="greyish" size="small" icon={true}/>

            <SampleButton color="primary" size="small"/>
            <SampleButton color="primary" size="small" icon={true}/>

            <SampleButton color="secondary" size="small"/>
            <SampleButton color="secondary" size="small" icon={true}/>

        </Stack>
        <Stack gap={1} alignItems={"start"}>
            <Link href="#" variant="body1">Link</Link>
            <Link href="#" variant="body2">Link</Link>
        </Stack>
    </Stack>
}