import { useGrentonAuth } from "@grenton/gm/auth";
import { Button, Stack } from "@mui/material";

export function UserScreen() {
    const {logout, user} = useGrentonAuth();
    
    return <>{user && <Stack>
            <div>{user.email}</div>
            <div>{user.sub}</div>
            <Button onClick={logout}>Logout</Button>
    </Stack> }</>
}