import type { ReactElement } from "react";
import { Box, ToggleButton, Button } from "@mui/material";
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import { useCallback, useState } from "react";
import { Lists } from "@grenton/gm-common";
import { GButtonBar, GProfiler, ObjectTreeFilter, ScrollablePaneWithHeader, grentonColors } from "@grenton/design-system";
import { ObjectScriptRef, ProjectObjectImpl } from "@grenton/gm-logic";
import { MainObjectTree } from './components';
import type { MouseEventHandler } from 'react';
import { ProjectImpl } from '@grenton/gm-logic';
import { ObjectMultiSelection } from '../../utils';
import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeFilterResult, ProjectTreeItemType } from "@grenton/gm/ui/components/projectComponentTree2";

export type MainObjectTreePaneProps = {
    project: ProjectImpl;
    selection: ObjectMultiSelection;
    onSelectionChange: (m: ObjectMultiSelection) => void;
    onSelectionModeToggle: () => void;

    onPrimaryAction: (action:
        { type: 'event', data: ObjectScriptRef } |
        { type: 'method', data: ObjectScriptRef } |
        { type: 'object', data: { objectId: string, rootTag?: string } }) => void;

    onSecondaryAction: (action:
        { type: 'event', data: ObjectScriptRef } |
        { type: 'method', data: ObjectScriptRef } |
        { type: 'outlet', data: { objectId: string, outletId: string } } |
        { type: 'object', data: { objectId: string, rootTag?: string } }) => void;

    onOpenLibrary: MouseEventHandler;
};

export function MainObjectTreePane(props: MainObjectTreePaneProps): ReactElement {

    const { project, selection, onSelectionModeToggle, onSelectionChange, onPrimaryAction, onSecondaryAction, onOpenLibrary } = props;
    const [filter, setFilter] = useState("");
    const [selectedTagCategory, setSelectedTagCategory] = useState<string | null>(null);

    const filterFn = useCallback((node: ProjectTreeItem<ProjectTreeItemData>) => {
        if (!filter.length) return ProjectTreeFilterResult.INCLUDE_WITH_CHILDREN
        switch (node.data.type) {
            case ProjectTreeItemType.OBJECT:
                return node.sortKey.includes(filter) ? ProjectTreeFilterResult.INCLUDE_WITH_CHILDREN : ProjectTreeFilterResult.EXCLUDE
            default:
                return ProjectTreeFilterResult.INCLUDE_NOT_EMPTY
        }
    }, [filter])

    function onTreeItemClick(node: ProjectTreeItem<ProjectTreeItemData>): void {
        const data = node.data
        switch (data.type) {
            case ProjectTreeItemType.EVENT: {
                onPrimaryAction({
                    type: 'event',
                    data: {
                        objectId: data.objectId,
                        scriptRef: {
                            type: 'event',
                            path: data.path.slice(1).join('.'),
                        }
                    }
                })
                //onSelectionChange(selection.withSingleObject(data.sourceObjectId))
                break
            }
            case ProjectTreeItemType.METHOD: {
                onPrimaryAction({
                    type: 'method',
                    data: {
                        objectId: data.objectId,
                        scriptRef: {
                            type: 'method',
                            path: data.methodId,
                        }
                    }
                })
                //onSelectionChange(selection.withSingleObject(data.objectId))
                break
            }
            case ProjectTreeItemType.OBJECT: {
                onPrimaryAction({ type: 'object', data })
                //onSelectionChange(selection.withSingleObject(data.objectId))
                break
            }
        }
    }

    function onTreeItemActionClick(item: ProjectTreeItem<ProjectTreeItemData>): void {
        const data = item.data
        switch (data.type) {
            case ProjectTreeItemType.OBJECT: {
                // here objectId points to module/controller object
                onSecondaryAction({ type: 'object', data })
                break
            }
            case ProjectTreeItemType.OUTLET: {
                // here as well
                onSecondaryAction({ type: 'outlet', data })
                break
            }
            case ProjectTreeItemType.METHOD: {
                // these nodes are only rendered for non-hardware objects
                onSecondaryAction({
                    type: 'method',
                    data: {
                        objectId: data.objectId,
                        scriptRef: {
                            type: 'method',
                            path: data.methodId,
                        }
                    }
                })
                break
            }
            case ProjectTreeItemType.EVENT: {
                // these nodes are only rendered for non-hardware objects
                onSecondaryAction({
                    type: 'event',
                    data: {
                        // but here objectId may point to anonymous controller, but sourceObjectId always points to the object that defines this event
                        objectId: data.sourceObjectId,
                        scriptRef: {
                            type: 'event',
                            path: data.eventId,
                        }
                    }
                })
                break
            }
        }
    }

    const onObjectMultiSelectionToggle = (objectId: string, checked: boolean) => {
        if (!selection.mode) return

        const nodes = { ...selection.objects, [objectId]: checked }

        const allObjects: [ProjectObjectImpl | undefined, boolean][] = Object.entries(nodes)
            .filter(n => n[1] !== undefined)
            .map(n => [project.getObjectById(n[0]), n[1]])

        //first selected api
        const firstSelected = allObjects.find(n => n[1] === true)
        const api = firstSelected ? firstSelected[0]?.api : undefined

        // then, allow selection only these nodes that implement this api
        const compatibleNodes = api ?
            // narrow down selectable nodes to those that implement the same API as the first selected node
            Lists.reduce(allObjects.filter(n => n[0] && n[0].api.name === api.name), (n) => [n[0]!.uuid, !!nodes[n[0]!.uuid]]) :

            // reset to all objects, mark all as unselected
            Lists.reduce(Object.values(project.objects), (obj) => [obj.uuid, false])

        onSelectionChange(selection.withObjects(compatibleNodes))
    }

    return (
        <Box sx={{ height: '100%', position: 'relative', background: grentonColors.backgrounds_treepanes }}>
            <ScrollablePaneWithHeader header={
                <ObjectTreeFilter
                    filterPattern={filter}
                    onFilterPatternChange={txt => setFilter(txt?.trim())}
                    tagCategories={project.tags.categories}
                    onTagCategoryChange={setSelectedTagCategory}
                    selectedTagCategory={selectedTagCategory}
                />
            } footer={
                <GButtonBar end={<ToggleButton
                    sx={{ border: 'none' }}
                    value="check" selected={selection.mode}
                    onClick={onSelectionModeToggle}
                >
                    <LibraryAddCheckOutlinedIcon />
                </ToggleButton>} start={<Button disabled={selection.mode} onClick={onOpenLibrary}>Add</Button>} />

            }>
                <GProfiler id="primary-tree">
                    <MainObjectTree
                        project={project}
                        filter={filterFn}
                        multiSelection={selection}
                        tagCategory={selectedTagCategory || ""}
                        onNodeClick={onTreeItemClick}
                        onObjectSelectionToggle={onObjectMultiSelectionToggle}
                        onNodeActionClick={onTreeItemActionClick} />
                </GProfiler>
            </ScrollablePaneWithHeader>
        </Box>
    )
}
