export function currentLocationHref() {
    var u = new URL(window.location.href);
    u.hash = '';
    u.search = '';
    u.pathname = '';
    return u.toString();
}

// async function downloadImageAndConvertToBase64(url: string): Promise<string> {
//   // Fetch the image
//   const response = await fetch(url);
//   if (!response.ok) {
//       throw new Error(`Failed to fetch image: ${response.statusText}`);
//   }
//   const blob = await response.blob();

//   return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onerror = () => {
//           reader.abort();
//           reject(new DOMException("Problem parsing input file."));
//       };
//       reader.onload = () => {
//           // This is the base64 string
//           const base64String = reader.result as string;
//           resolve(base64String);
//       };
//       // Convert the blob to Base64
//       reader.readAsDataURL(blob);
//   });
// }
