import { ProjectTreeItem, ProjectTreeItemEventData, ProjectTreeItemType } from '@grenton/gm/ui/components/projectComponentTree2';
import { ProjectObjectImpl } from '@grenton/gm-logic';
import { concatTreeItemId } from './id';

type Props = {
    parentId: string;
    scriptableObject: ProjectObjectImpl;
    sourceObject: ProjectObjectImpl;
    path: string[];
    event: { id: string; name: string; primary: boolean; hasCode: boolean };
};

export function eventNode({ parentId, scriptableObject, sourceObject, path, event }: Props): ProjectTreeItem<ProjectTreeItemEventData> {
    const id = concatTreeItemId(parentId, event.id);
    return {
        id,
        label: event.name,
        icon: 'event',
        sortKey: event.name,
        data: {
            type: ProjectTreeItemType.EVENT as const,
            path,
            objectId: scriptableObject.uuid,
            sourceObjectId: sourceObject.uuid,
            eventId: event.id,
            hasCode: event.hasCode,
        },
        children: [],
    };
}
