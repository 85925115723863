import { Menu, MenuItem } from "@mui/material";
import { UploadType } from "../../types";

type Props = {
    menuExportAnchorEl: null | HTMLElement
    menuExportOpen: boolean
    onClose: () => void
    onSelect: (upload: UploadType) => void
}

export function SendMenu({menuExportAnchorEl, menuExportOpen, onClose, onSelect}:Props) {
    return <Menu
    anchorEl={menuExportAnchorEl}
    open={menuExportOpen}
    onClose={onClose}
>
    <MenuItem onClick={() => onSelect('preview')}>Preview</MenuItem>
    <MenuItem onClick={() => onSelect('file')}>Export to file</MenuItem>
    <MenuItem onClick={() => onSelect('http')}>Upload via REST</MenuItem>
    <MenuItem onClick={() => onSelect('mqtt')}>Upload via MQTT (dev only)</MenuItem>
    </Menu>
}