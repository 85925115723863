import { useEffect, useState } from "react"
import { WidgetPropsForm, WidgetPropsFormUI } from "./WidgetPropsFormUI"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from "@mui/material"
import { ScreenPropsForm, ScreenPropsFormMeta } from "./ScreenPropsFormUI"

export default function(props:{
    open:boolean,
    form?:ScreenPropsForm,
    meta?:ScreenPropsFormMeta,
    onEdit:(form:ScreenPropsForm)=>void,
    onDelete:(uuid:string)=>void,
    onClose:()=>void}) {
        if (!props.form || !props.meta) return null

        const [form,setForm] = useState<WidgetPropsForm|undefined>()
        useEffect(() => {
            setForm(props.form)
        }, [props.form])

        return <Dialog maxWidth="md" fullWidth open={props.open} onClose={props.onClose}>
            <DialogTitle>Screen {props.form.name}</DialogTitle>
            <DialogContent sx={{minHeight:300}}>
            <WidgetPropsFormUI
            meta={props.meta}
            form={props.form}
            onEdit={(form)=>setForm(form)}
            />
            </DialogContent>
            <DialogActions>
                <Button disabled={!form || !form.uuid} onClick={()=>props.onDelete(form?.uuid||'')}>Delete</Button>
                <Box sx={{flexGrow:2}}/>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button disabled={!form} onClick={()=>props.onEdit(form!)}>Apply</Button>
            </DialogActions>
        </Dialog>
}