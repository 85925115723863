import './blockly-styles.css';
import { Theme } from 'blockly';
import './customCategoryItems';

const WHITE = '#ffffff';
const GRENTON_RED = '#DB5768'; //'#DB4155';
const GRENTON_GREY = '#707070';

const LOGIC = '#6E7FF5';
const LOOPS = '#51A2E3';
const MATH = '#D48992';
const TEXT = '#D48A43';
const LISTS = '#E4B32D';
const COLORS = '#D9DA37';
const FUNCTIONS = '#359B8D';
const VARIABLES = '#78C79C';

export const modestTheme = Theme.defineTheme('modest', {
    fontStyle: {
        family: 'Manrope',
        weight: 'normal',
        size: 11,
    },

    componentStyles: {
        selectedGlowColour: '#000000',
        selectedGlowOpacity: 0.5,
        replacementGlowColour: '#ff0000',
        replacementGlowOpacity: 0.5,
        insertionMarkerColour: '#000000',
        insertionMarkerOpacity: 0.5,
        markerColour: '#000000',
        cursorColour: '#ff0000',

        // this is toolbox/drawer background
        // flyoutBackgroundColour:'#cccccc',
        // flyoutOpacity:0.8
    },
    categoryStyles: {
        grenton: {
            colour: GRENTON_RED,
        },
        logic: {
            colour: LOGIC,
        },
        loops: {
            colour: LOOPS,
        },
        math: {
            colour: MATH,
        },
        text: {
            colour: TEXT,
        },
        lists: {
            colour: LISTS,
        },
        colors: {
            colour: COLORS,
        },
        functions: {
            colour: FUNCTIONS,
        },
        variables: {
            colour: VARIABLES,
        },
    },

    blockStyles: {
        grenton_statement_blocks: {
            colourPrimary: GRENTON_RED,
            colourSecondary: GRENTON_RED,
            colourTertiary: WHITE,
        },

        grenton_value_blocks: {
            colourPrimary: GRENTON_GREY,
            colourSecondary: GRENTON_GREY,
            colourTertiary: WHITE,
        },

        logic_blocks: {
            colourPrimary: LOGIC,
            colourSecondary: LOGIC,
            colourTertiary: WHITE,
        },
        loop_blocks: {
            colourPrimary: LOOPS,
            colourSecondary: LOOPS,
            colourTertiary: WHITE,
        },
        math_blocks: {
            colourPrimary: MATH,
            colourSecondary: MATH,
            colourTertiary: WHITE,
        },
        text_blocks: {
            colourPrimary: TEXT,
            colourSecondary: TEXT,
            colourTertiary: WHITE,
        },
        list_blocks: {
            colourPrimary: LISTS,
            colourSecondary: LISTS,
            colourTertiary: WHITE,
        },
        color_blocks: {
            colourPrimary: COLORS,
            colourSecondary: COLORS,
            colourTertiary: WHITE,
        },
        variable_blocks: {
            colourPrimary: VARIABLES,
            colourSecondary: VARIABLES,
            colourTertiary: WHITE,
        },
        variable_dynamic_blocks: {
            colourPrimary: VARIABLES,
            colourSecondary: VARIABLES,
            colourTertiary: WHITE,
        },
        procedure_blocks: {
            colourPrimary: FUNCTIONS,
            colourSecondary: FUNCTIONS,
            colourTertiary: WHITE,
        },
    },
    name: 'modest',
});
