import { GSideDialog, GDialogTitle, GSideDialogProps } from "@grenton/design-system/src/theme2/components";
import { KeyMap, Maps, TagsCategory } from "@grenton/gm-common";
import { DialogContent, Stack } from "@mui/material";
import { useCallback } from "react";
import { TagSelector } from "./tagSelector";

type Props = {
    open: boolean,
    onClose(): void,
    tags: TagsCategory[],
    selected: string[],
    onChange(tags: string[]): void
    onAdd?():void
    onCreateCategory?():void
    align?: GSideDialogProps['align']
}

export function SideDialogTagSelector({ open, align, onClose, onChange, tags, selected, onAdd, onCreateCategory }: Props) {

    const __onChange = useCallback((tags: KeyMap) => {
        onChange(Maps.reduceKeyMap(tags))
    }, [onChange])

    return <GSideDialog open={open} onClose={onClose} align={align}>
        <GDialogTitle onClose={onClose}>Tags</GDialogTitle>
        <DialogContent>
            <Stack p={2}>
                <TagSelector
                    categories={tags}
                    selected={Maps.asKeyMap(selected)}
                    forceMultiple={true}
                    onChange={__onChange}
                    onAdd={onAdd}
                    onCreateCategory={onCreateCategory}
                    />
            </Stack>
        </DialogContent>
    </GSideDialog>
}