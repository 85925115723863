import { useEffect } from 'react';

export function useNetworkStatus(setOnline: (status: boolean) => void) {
    return useEffect(() => {
        const networkStatusListener = () => {
            setOnline(navigator.onLine);
        };

        window.addEventListener('offline', networkStatusListener);
        window.addEventListener('online', networkStatusListener);

        return () => {
            window.removeEventListener('offline', networkStatusListener);
            window.removeEventListener('online', networkStatusListener);
        };
    }, []);
}
