import { ButtonBase, useTheme } from "@mui/material";
import { type MouseEvent, type PropsWithChildren } from 'react';
import { grentonColors } from "../colors";
import { sizeRem, sizes } from "../size";

type PseudoInputProps = PropsWithChildren<{
    onClick: (e: MouseEvent) => void;
    disabled?: boolean;
    placeholder?: string
}>;

/**
 * this component is supposed to look like an input field but render any children inside 
 * (presumably selected via dialog)
 * 
 * TODO use fieldset to mimic shrunk label?
 * 
 * @param param0 
 * @returns 
 */
export function GPseudoInput({ placeholder, disabled, children, onClick }: PseudoInputProps) {

    const theme = useTheme();
    return (
        <ButtonBase
            disabled={disabled}
            disableRipple={true}
            sx={{
                margin: 0,
                fontSize: 16,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: grentonColors.grey[400],
                padding: 1,
                borderRadius: 0,
                justifyContent: "start",
                '&:hover': {
                    borderColor: theme.palette.secondary.main,
                },
                '&.Mui-focusVisible': {
                    borderColor: theme.palette.secondary.main,
                    boxShadow: `0 0 0 1px ${theme.palette.secondary.main}`,
                },
            }}
            onClick={onClick}>
            {!children && placeholder && <label style={{ fontSize: sizeRem(sizes.M), color: grentonColors.Font_Placeholder }}>{placeholder}</label>}
            {children}
        </ButtonBase>
    )
}
