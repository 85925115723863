import { GSideDialog, GDialogTitle } from "@grenton/design-system";
import { Box, DialogContent, Link, Typography } from "@mui/material";
import { certificateHref } from "./downloadCertificate";

type Props = {
    open: boolean
    onClose: () => void
}

export function CertificateInstallWizardDialog({open, onClose}:Props) {
    return <GSideDialog align="center" open={open} onClose={onClose}>
        <GDialogTitle onClose={onClose}>How to install CLU certificate?</GDialogTitle>
        <DialogContent>
            <Link href={certificateHref}>Download certificate</Link>
            <Box sx={{m:1}}/>
           <Typography>TODO detect OS system and show instructions how to add certificate</Typography>
        </DialogContent>
    </GSideDialog>
}