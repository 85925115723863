import { ObjectTreeFilter, ScrollablePaneWithHeader } from "@grenton/design-system";
import { OutletObjectSelectorTree } from "../outletObjectSelectorTree";
import { useCallback, useState } from "react";
import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeFilterResult, ProjectTreeItemType } from "@grenton/gm/ui/components/projectComponentTree2";
import { KeyMap } from "@grenton/gm-common";
import { ProjectImpl } from "@grenton/gm-logic";

type Props = {
    project: ProjectImpl;
    ids: KeyMap;
    allowedApis: string[];
    onItemSelectionToggle: (id: string, selected: boolean) => void;
};

export function OutletObjectSelector({ project, ids, allowedApis, onItemSelectionToggle }: Props) {

    const [filter, setFilter] = useState('')
    const [selectedTagCategory, setSelectedTagCategory] = useState<string | null>(null)

    const filterFn = useCallback((node: ProjectTreeItem<ProjectTreeItemData>) => {
        switch (node.data.type) {
            case ProjectTreeItemType.OBJECT:
                return node.sortKey.includes(filter) ? ProjectTreeFilterResult.INCLUDE_WITH_CHILDREN : ProjectTreeFilterResult.EXCLUDE
            default:
                return ProjectTreeFilterResult.INCLUDE_NOT_EMPTY
        }
    }, [filter, allowedApis])

    return <ScrollablePaneWithHeader header={<ObjectTreeFilter
        filterPattern={filter}
        onFilterPatternChange={txt => setFilter(txt?.trim())}
        tagCategories={project.tags.categories}
        onTagCategoryChange={setSelectedTagCategory}
        selectedTagCategory={selectedTagCategory}
    />}>
        <OutletObjectSelectorTree
            project={project}
            filter={filterFn}
            allowedApis={allowedApis}
            tagCategory={selectedTagCategory}
            onItemSelectionToggle={onItemSelectionToggle}
            selected={ids} />
    </ScrollablePaneWithHeader>
}