import { Box, Avatar } from "@mui/material";
import { TreeItem, SimpleTreeView } from "@mui/x-tree-view";
import { TreeDemoItem, TreeDemoProps } from "./types";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";

export function SimpleTreeViewDemo({ items, multiSelect, expanded, onItemExpansionToggle }: TreeDemoProps) {
    const renderNode = (node: TreeDemoItem) => {
        return <TreeItem key={node.id} itemId={node.id} label={<SimpleTreeItemLabel label={"todo"} />}>
            {node.children?.map(renderNode)}
        </TreeItem>
    }

    return (
        <Box sx={{ height: 300, flexGrow: 1, maxWidth: 400, border: '1px solid black', overflow: 'auto' }}>
            <SimpleTreeView
                slots={
                    {
                        expandIcon: ArrowRight,
                        collapseIcon: ArrowDropDown 
                    }
                }
                checkboxSelection={multiSelect}
                multiSelect={true}
                expandedItems={expanded}
                onItemExpansionToggle={(_e, itemId, isExpanded) => onItemExpansionToggle(itemId, isExpanded)}
            >
                {items.map(renderNode)}

            </SimpleTreeView>

        </Box>
    );
}

function SimpleTreeItemLabel({ label }: { label: string }) {
    return <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }} onClick={e => e.stopPropagation()}>
        <Avatar
            sx={(theme) => ({
                background: theme.palette.primary.main,
                width: 24,
                height: 24,
                fontSize: '0.8rem',
            })}
        >
            {(label as string)[0]}
        </Avatar>

        <div>{label}</div>
    </Box>
}