import { Box } from "@mui/material";
import React from "react";

export default function Mask(props:{content?:React.ReactNode, alpha?:number}) {
    return <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center', 
        backgroundColor: `rgba(0,0,0,${props.alpha||0.05})`,
        
        zIndex: 1001, 
        position: 'absolute', 
        top: 0, bottom: 0, left: 0, right: 0 
        }}>
        {props.content}
      </Box>
}
