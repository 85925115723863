import { Checkbox, Divider, FormControlLabel, IconButton, Stack, Toolbar } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GProfiler } from '../../components';
import { Expand, Refresh, Close } from '@mui/icons-material';
import { generateNodes } from './generateNodes';
import { RichTreeViewDemo } from './RichTreeViewDemo';
import { SimpleTreeViewDemo } from './SimpleTreeViewDemo';
import { TreeDemoItem } from './types';



export function TreeDemo() {
    const [multiSelect, setMultiSelect] = useState(false)
    const [simpleDemo, setSimpleDemo] = useState(true)
    const [richDemo, setRichDemo] = useState(true)

    const [items, setItems] = useState<TreeDemoItem[]>([])
    useEffect(()=>{
        setItems(generateNodes())
    },[])


    const [expanded,setExpanded] = useState<Set<string>>(new Set<string>())
    const expandedAsArray = useMemo(()=>[...expanded.keys()],[expanded])
    const onItemExpansionToggle = useCallback((itemId:string,isExpanded:boolean) => {
        if (isExpanded) {
            setExpanded(new Set<string>(expanded.add(itemId)))
        } else {
            expanded.delete(itemId)
            setExpanded(new Set<string>(expanded))
        }
    },[])

    const expandAll = useCallback(() => {
        const all = new Set<string>()
        const collect = (node:TreeDemoItem) => {
            all.add(node.id)
            node.children?.forEach(collect)
        }
        items.forEach(collect)
        setExpanded(all)
    },[items])

    return (
        <Stack sx={{ gap: 1 }}>
            <Toolbar>
                <FormControlLabel control={<Checkbox checked={simpleDemo} onChange={(_, value) => setSimpleDemo(value)} />} label="Simple" />
                <FormControlLabel control={<Checkbox checked={richDemo} onChange={(_, value) => setRichDemo(value)} />} label="Rich" />
                <Divider/>
                <FormControlLabel control={<Checkbox onChange={(_, value) => setMultiSelect(value)} />} label="Multiselect" />
                <IconButton onClick={() => setItems(generateNodes())}><Refresh /></IconButton>
                <IconButton onClick={() => setExpanded(new Set())}><Close /></IconButton>
                <IconButton onClick={expandAll}><Expand /></IconButton>
            </Toolbar>

            { simpleDemo && <GProfiler id="SimpleTreeViewDemo">
                <SimpleTreeViewDemo items={items} multiSelect={multiSelect} expanded={expandedAsArray} onItemExpansionToggle={onItemExpansionToggle}/>
            </GProfiler> }

            { richDemo && <GProfiler id="RichTreeViewDemo">
                <RichTreeViewDemo items={items} multiSelect={multiSelect} expanded={expandedAsArray} onItemExpansionToggle={onItemExpansionToggle}/>
            </GProfiler> }

        </Stack>
    );
}




