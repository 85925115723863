import { SxProps, ToggleButton, ToggleButtonProps } from "@mui/material";

type Props = { 
    icon: React.ReactNode,
    selected:boolean,
    disabled?:boolean,
    onClick: (e: React.MouseEvent) => void
    sx?:SxProps
    color?: ToggleButtonProps['color']
}

export function ActionIconToggleButton({ color, icon, onClick, selected, disabled,sx }: Props) {
    return <ToggleButton color={color} disabled={disabled} value={""} size="small" sx={{...sx, '& svg': { fontSize: '1.2em' } }} onClick={onClick} selected={selected}>
        {icon}
    </ToggleButton>
}