import { Alert, Box, FormControlLabel, Grid, MenuItem, Stack, Switch, TextField } from "@mui/material";
import { useState } from "react";
import { useObservable, useSubjectState } from "../utils";
import { HardwarePane } from "./hardwarePane/HardwarePane";
import { ProjectPane } from "./configurationPane/ConfigurationPane";
import { ProjectUserCredentials } from "@grenton/gm-common";
import { AuthorizationError, SelectNetworkCommand, installCertificateCommandType } from "@grenton/gm-logic";
import logger from "loglevel";
import { CluAction } from "./types";
import { useDispatcher, useProject } from "../ui";
import { useService } from "../providers";
import { HWTreeItem, HWTreeItemType } from "./hardwarePane/types";
import { PaneTopbar } from "./components/PaneTopbar";
import { EditNote, Wifi } from "@mui/icons-material";
import { GSplitter, GSwitchBox } from "@grenton/design-system";
import { CFGTreeItem, CFGTreeItemType } from "./configurationPane/types";
import { SelectionContext } from "./selectionContext";
import { PaneBackdrop } from "./components/PaneBackdrop";
import { useNetworkView } from "./NetworkContext";
import { Link } from "react-router-dom";
import { ImportStatus, ImportStatusDialog } from "./ImportStatusDialog";



export function HardwarePage() {

    const { hardwareController, certificateStatusStore } = useService()
    const dispatcher = useDispatcher()

    const project = useProject()
    const networkView = useNetworkView()
    const [availableHardware] = useSubjectState(hardwareController.visibleClusters)
    const [selectedEntity] = useSubjectState(hardwareController.selectedEntity)
    const [certificateStatus] = useObservable(certificateStatusStore.provider)
    const [unlinkedModulesCount] = useObservable(hardwareController.unlinkedModulesCount)

    const [hideLinked, setHideLinked] = useState(false)
    const [importStatus, setImportStatus] = useState<ImportStatus>(null)

    const onSelectProjectComponent = (node: CFGTreeItem) => {
        if (node.data.type === CFGTreeItemType.COMPONENT) {
            hardwareController.onSelectEntity({
                selection: 'module',
                uuid: node.data.component.id,
                type: node.data.component.ref,
                belongsTo: 'project'
            })
        } else if (node.data.type === CFGTreeItemType.OBJECT) {
            const object = project.getObjectById(node.data.objectId)
            object && hardwareController.onSelectEntity({
                selection: 'object',
                uuid: object.uuid,
                type: object.api.name
            })
        }
    }

    const onDeselectEntity = () => {
        hardwareController.onSelectEntity()
    }
    const onSelectHardwareComponent = (node: HWTreeItem) => {
        if (node.data.type === HWTreeItemType.MODULE) {
            hardwareController.onSelectEntity({
                selection: 'module',
                uuid: node.data.module.id,
                type: node.data.module.ref,
                belongsTo: 'hardware'
            })
        }
    }

    const onDeleteMapping = (uuid: string) => {
        hardwareController.onDeleteMapping(uuid)
    }

    const onRemoveClu = (cluId: string) => {
        hardwareController.onRemoveClu(cluId)
    }

    const executeImport = async (action: CluAction) => {
        setImportStatus({status:'inprogress'})
        try {
            await hardwareController.importAndMerge(action.cluId, !!action.withModules)
            setImportStatus({status:'success'})
        } catch (e) {
            logger.error('fail to import', e)
            if (e instanceof AuthorizationError) {
                setImportStatus({status:'authrequired', action, error:e.message})
                // authentication can always fail - so we should try to perform action, if it fails, try to authorize and retry it.
                //setAuthorizationRequiredDialog({action, errorMessage:e.message})
            } else {
                setImportStatus({status:'error', error:e?.toString()||'unknown error'})
                
                //setImportError({open:true, message:e?.toString()||'unknown error'})
   
            }
        }
    }

    // const showImportOptionsDialog = (cluId: string) => {
    //     setImportOptionsDialog(cluId)
    // }

    // this handler is called first when user tries to add/import CLU.
    // at this point we don't know yet if user is authorized to access this CLU
    // and if modules should be imported or not. 
    // It seems that we should collect pieces of this info step by step
    const onImportOrAddCLU = async (cluId: string) => {
        if (project.empty) {
            // execute import with default options for empty project
            await executeImport({ cluId })
        } else {
            // allow user to customise import (with or without modules?)
            //showImportOptionsDialog(cluId)
            setImportStatus({status:'selectoptions', cluId})
        }
    }

    const onAuthorizeCLU = async (creds: ProjectUserCredentials, action:CluAction) => {
        hardwareController.setCluAuthorization(action.cluId, creds)
        await executeImport(action)
    }

    const onContinueImport = async (action: CluAction) => {
        //setImportOptionsDialog(null)
        await executeImport(action)
    }

    const onAbortImport = () => {
        setImportStatus(null)
        //setAuthorizationRequiredDialog(null)
        //setImportOptionsDialog(null)
    }

    const onInstallCert = () => {
        dispatcher({type:installCertificateCommandType})
    }

    return <Grid container spacing={0} sx={{ background: '#fff', overflow: 'hidden', flexGrow: 1, position:'relative' }}>

        <ImportStatusDialog 
            open={importStatus !== null} 
            status={importStatus}
            onClose={onAbortImport} 
            onContinueImport={onContinueImport}
            onAuthorize={onAuthorizeCLU}
        />

        {/* <ImportOptionsDialog
            open={Boolean(importOptionsDialog)}
            cluId={importOptionsDialog || ''}
            onClose={onAbortImport}
            onExecuteAction={onContinueImport} />

        <AuthorizeCluDialog 
            open={Boolean(authorizationRequiredDialog)}
            errorMessage={authorizationRequiredDialog?.errorMessage}
            onClose={onAbortImport}
            onAuthorize={onAuthorizeCLU} />

        <ImportErrorDialog open={importError.open} message={importError.message} onClose={() => setImportError({open:false,message:''})} /> */}

        <SelectionContext.Provider value={selectedEntity||null}>
        <GSplitter direction={"horizontal"} initialSizes={[50,50]} minWidths={[20,20]}>
            <Stack sx={{height:'100%',flexDirection:'column',position:'relative'}}>
                
            <PaneTopbar
                icon={<Wifi />}
                label={"Network"}

                start={
                        <TextField select value={networkView.selectedNetworkId||''} 
                        onChange={(e) => {
                            dispatcher(new SelectNetworkCommand(e.target.value))
                        }} >
                            {networkView.networks.map((network) => (
                                <MenuItem key={network.id} value={network.id}>{network.id}</MenuItem>
                            ))}
                        </TextField>
                    }
                end={
                    certificateStatus ==='not-installed' && <Alert severity="warning" sx={{ paddingTop: 0, paddingBottom: 0 }}>
                        <span>CLU Certificate is not installed</span>
                        <Link to={''} style={{marginLeft:10,color:'inherit'}} onClick={()=>onInstallCert()}>Install</Link>
                    </Alert>
                }
            />

            <Box sx={{flexGrow:2, overflow:'auto' }}>
            <HardwarePane
                hardware={availableHardware}
                selectedModule={selectedEntity?.selection === 'module' ? selectedEntity : undefined}
                project={project}
                onRemoveClu={onRemoveClu}
                onImportFromClu={onImportOrAddCLU}
                onDeleteMapping={onDeleteMapping}
                onStartMapping={onSelectHardwareComponent}
            />
            </Box>
            <PaneBackdrop onClick={onDeselectEntity} belongsTo="hardware"/>
            </Stack>

            <Stack sx={{height:'100%',flexDirection:'column',position:'relative'}}>
            <PaneTopbar
                icon={<EditNote />}
                label={"Project"}
                end={
                    <>
                        {unlinkedModulesCount ?
                            <Alert severity="warning" sx={{ paddingTop: 0, paddingBottom: 0 }}>{unlinkedModulesCount} unlinked
                                module(s)</Alert> :
                            <Alert severity="success" sx={{ paddingTop: 0, paddingBottom: 0 }}>All modules linked</Alert>}

                        <FormControlLabel 
                            sx={{margin:0}}
                            labelPlacement="end"
                            control={
                                <GSwitchBox><Switch 
                                    checked={hideLinked}
                                    onChange={e => setHideLinked(e.target.checked)} /></GSwitchBox>
                            } label={"hide linked"} />
                    </>

                } />


            <Box sx={{flexGrow:2, overflow:'auto' }}>
            <ProjectPane project={project}
                selectedEntity={selectedEntity}
                hideLinked={hideLinked}
                onDeleteMapping={onDeleteMapping}
                onStartMapping={onSelectProjectComponent}
            />
            </Box>
            <PaneBackdrop onClick={onDeselectEntity} belongsTo="project"/>
            </Stack>
        </GSplitter>
        </SelectionContext.Provider>
    </Grid>
}
