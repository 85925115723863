import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

export function EditModeVisual(props: SvgIconProps): ReactElement {
    return <SvgIcon viewBox="0 0 24 24" style={{ strokeWidth: 4, strokeLinejoin: 'round', strokeMiterlimit: 2 }} {...props} fontSize="inherit">
        <g>
            <path d="M20,8V3a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1V4A2,2,0,0,1,8,4V3A1,1,0,0,0,7,2H1A1,1,0,0,0,0,3V21a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1V20a2,2,0,0,1,4,0v1a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V16a4,4,0,0,0,0-8Zm0,6H19a1,1,0,0,0-1,1v5H14a4,4,0,0,0-8,0H2V4H6a4,4,0,0,0,8,0h4V9a1,1,0,0,0,1,1h1a2,2,0,0,1,0,4Z" />
        </g>
    </SvgIcon>
}
