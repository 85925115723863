import { Drawer } from "@mui/material";
import { CSSProperties, ReactNode, useState } from "react";

/**
 * container must have 
 *      position:relative/absolute 
 *      overflow: visible  <---!!!
 * 
 * drawer component should be placed AFTER container (not INSIDE),
 * because it needs its reference
 * 
 * @param param0 
 * @returns 
 */

type Props = {
    children?: ReactNode,
    container: Element | null,     // has to be a callback, otherwise it will be null
    open: boolean,
    withHandle?: boolean,
    onClose: () => void,
    height?: string | number
}

export function GInlineDrawer({ height, open, onClose, container, children, withHandle }: Props) {

    const withHandleStyle: CSSProperties = withHandle ? { visibility: 'visible', overflow: 'visible' } : {}

    return container ? <Drawer variant="temporary" open={open} anchor="bottom"
        SlideProps={{ direction: 'up' }}
        onClose={onClose}
        PaperProps={{
            style: {
                boxShadow: 'none',      // shadow for contained drawers leaks on the edge off when drawer moves in/out
                position: 'absolute', 
                height, 
                ...withHandleStyle
            }
        }}
        slotProps={{ "backdrop": { style: { position: 'absolute' } } }}
        ModalProps={{
            keepMounted: true,
            style: { position: 'absolute' },
            container: container
        }}>
        {children}
    </Drawer> : null
}

export function drawerHandleStyles(height: number, fullWidth = true): CSSProperties {
    return {
        height,
        position: 'absolute',
        top: -height,
        left: fullWidth ? 0 : undefined,
        right: fullWidth ? 0 : undefined
    }
}

/**
 * helper hook to cause re-render when container ref is set
 * otherwise inline drawer won't initialise properly
 */
export const useContainerRef = () => useState<HTMLElement | null>(null)