import React, { useEffect, useRef, useState } from 'react';
import { BlocklyOptions, WorkspaceSvg, inject } from 'blockly';

import { ToolboxDefinition } from 'blockly/core/utils/toolbox';

type Props = {
    ref: any;
    toolboxConfiguration: ToolboxDefinition;
    workspaceConfiguration: BlocklyOptions;
    onInject: (workspace: WorkspaceSvg) => void;
    onDispose: (workspace: WorkspaceSvg) => void;
};

const useBlocklyWorkspace = ({ ref, toolboxConfiguration, workspaceConfiguration, onInject, onDispose }: Props) => {
    const [workspace, setWorkspace] = useState<WorkspaceSvg | null>(null);

    // we explicitly don't want to recreate the workspace when the configuration changes
    // so, we'll keep it in a ref and update as necessary in an effect hook
    const workspaceConfigurationRef = useRef(workspaceConfiguration);
    React.useEffect(() => {
        workspaceConfigurationRef.current = workspaceConfiguration;
    }, [workspaceConfiguration]);

    const toolboxConfigurationRef = useRef(toolboxConfiguration);
    useEffect(() => {
        toolboxConfigurationRef.current = toolboxConfiguration;
        if (toolboxConfiguration && workspace) {
            workspace.updateToolbox(toolboxConfiguration);
        }
    }, [toolboxConfiguration, workspace]);

    const onInjectRef = useRef(onInject);
    const onDisposeRef = useRef(onDispose);
    useEffect(() => {
        onInjectRef.current = onInject;
    }, [onInject]);
    useEffect(() => {
        onDisposeRef.current = onDispose;
    }, [onDispose]);

    // Workspace creation
    useEffect(() => {
        const newWorkspace = inject(ref.current, {
            ...workspaceConfigurationRef.current,
            renderer: 'thrasos',
            toolbox: toolboxConfigurationRef.current,
        });
        setWorkspace(newWorkspace);

        if (onInjectRef.current) {
            onInjectRef.current(newWorkspace);
        }

        const onDisposeFunction = onDisposeRef.current;

        // Dispose of the workspace when our div ref goes away (Equivalent to didComponentUnmount)
        return () => {
            newWorkspace.dispose();

            if (onDisposeFunction) {
                onDisposeFunction(newWorkspace);
            }
        };
    }, [ref]);

    return { workspace };
};

export default useBlocklyWorkspace;
