import {ReactElement, useCallback, useState} from "react";
import {I18nLang} from '@grenton/gm-common';
import {I18nLangDeleteCommand, I18nLangAddCommand, I18nLangEditCommand} from '..';
import {SectionContent} from '@grenton/design-system';
import {LanguageAccordion, AddLanguage, EditLanguage, DeleteLanguage} from './components';

import {Alert, Box, IconButton, Stack} from "@mui/material";
import {Add} from "@mui/icons-material";
import { useDispatcher, useProject } from "@grenton/gm/ui";

export function ProjectLanguages(): ReactElement {
    const dispatch = useDispatcher();
    const langs = useProject().i18n.langs;
    const hasLangs = Boolean(langs?.length);
    const hasMultipleLangs = langs?.length > 1;

    const [holdLanguage, setHoldLanguage] = useState<I18nLang | null>(null);
    const [modalState, setModalState] = useState({
        add: false,
        edit: false,
        delete: false,
    });

    function toggleModal(modalName: keyof typeof modalState): void {
        setModalState(prevState => ({...prevState, [modalName]: !prevState[modalName]}));
    }

    const handleAddLanguage = (data: I18nLang) => {
        toggleModal('add');
        dispatch(new I18nLangAddCommand(data))
    }

    function handleEditUser(data: I18nLang): void {
        toggleModal('edit');
        setHoldLanguage(data);
    };

    const onSubmitLanguageUser = useCallback(({updatedLanguage}: {
        updatedLanguage: I18nLang
    }): void => {
        dispatch(new I18nLangEditCommand(updatedLanguage))
        toggleModal('edit');
    }, [holdLanguage]);


    function handleDeleteLanguage(language: I18nLang): void {
        setHoldLanguage(language);
        toggleModal('delete');
    }

    const confirmHandleDeleteLanguage = useCallback((): void => {
        if (holdLanguage) {
            dispatch(new I18nLangDeleteCommand(holdLanguage.code))
            toggleModal('delete');
        }
    }, [holdLanguage]);

    return (
        <SectionContent>
            <Stack>
                <Box display="flex" justifyContent="flex-end" padding={1}>
                    <IconButton onClick={() => toggleModal('add')}><Add/></IconButton>
                    <AddLanguage isOpen={modalState.add} onClose={() => toggleModal('add')} onSave={handleAddLanguage}/>
                    {holdLanguage && (
                        <EditLanguage
                            isOpen={modalState.edit}
                            onClose={() => toggleModal('edit')}
                            language={holdLanguage}
                            onSubmitLanguageUser={onSubmitLanguageUser}
                        />
                    )}
                    <DeleteLanguage isOpen={modalState.delete} onDelete={confirmHandleDeleteLanguage}
                                    onClose={() => toggleModal('delete')}/>
                </Box>
                {!hasLangs && <Alert severity="warning">You need to add a language.</Alert>}
                {hasLangs && langs?.map(lang => (
                    <LanguageAccordion
                        key={lang.code}
                        language={lang}
                        handleDeleteLanguage={hasMultipleLangs ? handleDeleteLanguage : undefined}
                        handleEditLanguage={hasMultipleLangs ? handleEditUser : undefined}
                    />
                ))}
            </Stack>
        </SectionContent>
    )
}
