import { Alert } from "@mui/material"
import { NotificationWrapper } from "../project"

type Props = {
    notification: NotificationWrapper
    onClose?: () => void
}

export function NotificationContent({notification, onClose}:Props) {
    return <Alert
    
        onClose={onClose}
        severity={notification.data.level}
        variant="filled"
        sx={{ width: '100%' }}
    >
        {notification.data.title}
    </Alert>

}