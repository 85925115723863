export class WidgetPropsForm {

    constructor(readonly uuid : string,
        readonly name:string) {}

}

export class WidgetPropsFormMeta {
    
}

export function WidgetPropsFormUI(_props:{form:WidgetPropsForm, meta:WidgetPropsFormMeta, onEdit:(form:WidgetPropsForm)=>void}) {
    return <div/>
}
