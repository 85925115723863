import { Stack, Tab, Badge, Box, SxProps } from "@mui/material";
import { useState } from "react";
import { Subheader } from "./common";
import { grentonColors } from "../colors";
import { GTabs } from "../components/GTabs";
import { GTabLabel } from "../components";
import { GOutlinedTabs } from "../components/GOutlinedTabs";

export function TabsDemo() {
    return <Stack sx={{ flexDirection: 'column' }}>
        <Subheader>Regular</Subheader>
        <RegularTabs length={6}/>

        <Subheader>Auto scroll buttons</Subheader>
        <RegularTabs length={30}/>

        <Subheader>Outlined Horizontal</Subheader>
        <Box sx={{background:grentonColors.backgrounds_menu}}>
        <OutlinedTabs length={30} orientation="horizontal"/>
        </Box>

        <Subheader>Outlined Vertical</Subheader>
        <Stack direction="row" sx={{height:300}}>
        
            <Box sx={{minWidth:200,height:'100%',background:grentonColors.backgrounds_menu}}>
            <OutlinedTabs length={10} orientation="vertical"/>
            </Box>
            
            <Box sx={{background:'#fff',flexGrow:1}}>content</Box>
        </Stack>
    </Stack>
}

function generateTabLabel(index:number) {
    switch (index) {
        case 1: 
            return <GTabLabel>{`Tab ${index + 1} here`}</GTabLabel>
        case 2: 
            return <GTabLabel>{`Tab ${index + 1} with a very long label`}</GTabLabel>
        case 4: 
            return <Badge variant="dot"><GTabLabel>{`Tab ${index + 1} with a dot indicating unsaved changes`}</GTabLabel></Badge>
        case 5: 
            return <Badge badgeContent="23"><GTabLabel>{`Tab ${index + 1}`}</GTabLabel></Badge>
        case 6: 
            return <Badge variant="dot"><GTabLabel onClose={()=>{}}>{`Tab ${index + 1}`}</GTabLabel></Badge>
        case 7: 
            return <Badge badgeContent="23"><GTabLabel onClose={()=>{}}>{`Tab ${index + 1}`}</GTabLabel></Badge>
        case 8: 
            return <GTabLabel onClose={()=>{}}>{`Tab ${index + 1} here`}</GTabLabel>    
        default:
            return <GTabLabel>{`Tab ${index + 1}`}</GTabLabel>
    }
}

function RegularTabs({length}:{length:number}) {
    const [tab, setTab] = useState(0)
    return <GTabs variant="scrollable" scrollButtons="auto" value={tab} onChange={(_,tab)=>setTab(tab)}>
        { Array.from({ length }, (_, index) => index).map((index) => 
            <Tab wrapped={false} disabled={index==3} key={index} label={generateTabLabel(index)} 
        />) }
    </GTabs>
}

function OutlinedTabs({length, orientation}:{length:number, orientation:'vertical' | 'horizontal'}) {
    const [tab, setTab] = useState(0)

    const sx: SxProps = orientation === 'vertical' ? { height:'100%' } : {}

    return <GOutlinedTabs sx={sx} orientation={orientation} variant="scrollable" scrollButtons="auto" value={tab} onChange={(_,tab)=>setTab(tab)}>
        { Array.from({ length }, (_, index) => index).map((index) => 
            <Tab wrapped={false} disabled={index==3} key={index} label={generateTabLabel(index)} 
        />) }
    </GOutlinedTabs>
}