import React from "react";
import useBlocklyWorkspace from "./useBlocklyWorkspace";
import { ToolboxDefinition } from "blockly/core/utils/toolbox";
import { BlocklyOptions, svgResize, WorkspaceSvg } from "blockly";
import { useResizeDetector } from 'react-resize-detector';

type Props = {
  style?: React.CSSProperties,
  toolboxConfiguration: ToolboxDefinition, // eslint-disable-line react/forbid-prop-types
  workspaceConfiguration: BlocklyOptions, // eslint-disable-line react/forbid-prop-types
  onInject: (w: WorkspaceSvg)=>void,
  onDispose: ()=>void,
};

function BlocklyWorkspace({
  style,
  toolboxConfiguration,
  workspaceConfiguration,
  onInject,
  onDispose,
}: Props) {
  const editorDiv = React.useRef(null);

  const {workspace} = useBlocklyWorkspace({
    ref: editorDiv,
    toolboxConfiguration,
    workspaceConfiguration,
    onInject,
    onDispose
  });

  useResizeDetector({targetRef: editorDiv, onResize: () => {
    if (workspace) svgResize(workspace)
  }});

  return <div style={style} ref={editorDiv} />;
}

export default BlocklyWorkspace;
