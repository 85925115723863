import { DialogContent, DialogContentText, Button, Stack, MenuItem, Select, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import GMTextField from "../controls/GMTextField";
import { EditTagCategoryForm } from "@grenton/gm-logic";
import { GDialog, GDialogActions, GDialogTitle, TagLabel, toolsPalette } from "@grenton/design-system";

// this is fairly specific to GM, probably does not belong to design-system
// to avoid rainbow sickness, we can reuse the same colors as we use for blockly categories (assuming we're going with hi-contrast blockly)
const tagColors = [
  toolsPalette.PURPLE, 
  toolsPalette.BLUE,
  toolsPalette.DIRTY_PINK,
  toolsPalette.DARK_ORANGE,
  toolsPalette.ORANGE,
  toolsPalette.YELLOW,
  toolsPalette.DARK_GREEN,
  toolsPalette.LIGHT_GREEN,
  toolsPalette.GRENTON_GREY];

type Props = {
  open:boolean, 
  form:EditTagCategoryForm,
  // TODO split into onCreate and onClose
  onClose:(form?:EditTagCategoryForm)=>void
}

export function AddTagCategoryDialog({open,onClose,form:_form}:Props) {

    const [form,setForm] = useState<EditTagCategoryForm>(_form)

    useEffect(()=>{
      setForm(_form)
    }, [_form])

    return <GDialog
        open={open}
        onClose={()=>onClose()}
      >
        <GDialogTitle onClose={()=>onClose()}>Create tag category</GDialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            sx={{whiteSpace:'pre'}}
            tabIndex={-1}
          >
            <Stack spacing={2}>
            <Stack direction="row" alignItems="center">
              <Select     
                autoWidth={true}
                sx={{minWidth:'5em',marginRight:2,marginTop:2}}
                variant="standard"
                placeholder="Category"
                displayEmpty={true}
                disableUnderline={true}
                value={form.color}
                onChange={e=>{setForm({...form, color:e.target.value})}}
                label="Category"
              >
                { tagColors.map(c=>(<MenuItem key={c} value={c}><TagLabel label={form.name||'?'} color={c}/></MenuItem>))}
              </Select>
              <GMTextField multiline={true} label="Name" variant="standard" value={form.name} onChange={(e)=>setForm({...form, name:e.target.value})} />
            </Stack>
            <FormControlLabel labelPlacement="end" control={<Checkbox checked={form.multiple} onChange={(e)=>setForm({...form, multiple:e.target.checked})}/>} label="Multiselection allowed" />

            </Stack>

          </DialogContentText>
        </DialogContent>
        <GDialogActions start={
            <Button onClick={()=>onClose()}>Cancel</Button>
          } end={
            <Button color="primary" disabled={!form.name.trim().length || !form.color.trim().length} onClick={()=>onClose(form)}>Add</Button>
          }>
        </GDialogActions>
      </GDialog>
}

