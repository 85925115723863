import { Box, Stack, Typography } from "@mui/material"
import React from "react"

type Props = { 
    action? : React.ReactNode,
    label: React.ReactNode,
    hint?: React.ReactNode,
    emphasis?: boolean,
    greyedOut?: boolean
}

export function ObjectTreeItemContent({ emphasis, label, greyedOut, hint, action}: Props) {

    const opacity = greyedOut ? 0.7 : 1

    return <Box sx={(theme) => ({
        minWidth: 0,
        minHeight: 40,
        display: 'flex',
        flexGrow: 2,
        alignItems: 'center',
        ...(emphasis ? theme.typography.s_bold : theme.typography.s_info),
        opacity
    })}>
        <Box className="GTreeItem-Label" sx={{ flexGrow: 2, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', display: 'flex', alignItems:'center' }}>
            {label}
        </Box>
        <Typography className="GTreeItem-Hint" variant="s" sx={{opacity:0.5,whiteSpace:'nowrap'/*,overflow:'hidden',textOverflow:'ellipsis'*/}}>{hint}</Typography>
        <Stack className="GTreeItem-Actions" sx={{flexDirection:'row',alignItems:'center',justifyContent:'end',gap:1}}>
            {action}
        </Stack>
    </Box>
}