import type { PropsWithChildren } from "react";
import { GMiniDrawer } from "@grenton/design-system";

type Props = PropsWithChildren<{
    open: boolean,
    drawerWidth: number
}>

export function MenuDrawer({ open, drawerWidth, children }: Props) {
    return (
        <GMiniDrawer sx={{'& .MuiDrawer-paper':{border:'none'}}} variant="permanent" open={open} openWidth={drawerWidth} closeWidth={70}>
            {children}
        </GMiniDrawer>)
}