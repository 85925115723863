import { Button, Stack } from '@mui/material';

export function DebugDemo() {
    return (
        <Stack gap={1} alignItems={'start'}>
            <Button
                sx={{ minWidth: 200 }}
                onClick={() => {
                    throw new Error('test');
                }}
            >
                Crash!
            </Button>
        </Stack>
    );
}
