
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Stack, FormControlLabel, Checkbox, Tab, IconButton } from '@mui/material';
import { GInlineDrawer, GTabLabel, drawerHandleStyles } from '../components';
import { useState } from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { grentonColors } from '../colors';
import { GOutlinedTabs } from '../components/GOutlinedTabs';


function DrawerContent({ toggleDrawer }: { toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void }) {

  return <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
    <List>
      {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
        <ListItem key={text} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
    <Divider />
    <List>
      {['All mail', 'Trash', 'Spam'].map((text, index) => (
        <ListItem key={text} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </Box>
}

function FullScreenModalDrawer({ open, toggleDrawer }: { open: boolean, toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void }) {

  return <Drawer variant='temporary' sx={{ zIndex: 100000 }} elevation={0} anchor='right' open={open} onClose={toggleDrawer(false)}>
    <DrawerContent toggleDrawer={toggleDrawer} />
  </Drawer>
}


export function DrawersDemo(props: {
  toggleInlineDrawer: () => void,
  inlineDrawerHasHandle: boolean,
  setInlineDrawerHasHandle: (value: boolean) => void
}) {
  const [fullScreenDrawerOpen, setFullScreenDrawerOpen] = React.useState(false);

  const toggleFullScreenDrawer = () => {
    setFullScreenDrawerOpen(!fullScreenDrawerOpen);
  };



  return (
    <Stack gap={1} flexDirection="column" >

      <Button onClick={toggleFullScreenDrawer}>Toggle modal drawer</Button>
      <Button onClick={props.toggleInlineDrawer}>Toggle inline drawer</Button>

      <FormControlLabel
        control={<Checkbox onChange={(_, value) => props.setInlineDrawerHasHandle(value)} checked={props.inlineDrawerHasHandle} />}
        label="Handle"
        labelPlacement="end"
      />

      <FullScreenModalDrawer open={fullScreenDrawerOpen} toggleDrawer={() => toggleFullScreenDrawer} />
    </Stack>
  );
}

function CustomTabPanel({tab,selected}:{tab:number,selected:number}){
  return tab===selected? <Box sx={{padding:4}}>Item {tab+1}</Box> : null
}

export function SampleInlineDrawer(props: { container: HTMLElement|null, hasHandle: boolean, open: boolean, setOpen: (value: boolean) => void, handleHeight: number }) {
  const {open,setOpen} = props
  const [tab,setTab] = useState(0)

  const changeTab = (tab:number) => {
    //if (!open) setOpen(true)
    setTab(tab)
  }
  
  return  <GInlineDrawer height="80%"
    withHandle={props.hasHandle} container={props.container} open={open} onClose={() => setOpen(false)}>
      <Stack style={{flexDirection:'column',background:grentonColors.grey[400], ...drawerHandleStyles(props.handleHeight,true) }}>
        <Stack style={{flexDirection:'row',alignItems:'end', height:props.handleHeight}}>
          <GOutlinedTabs value={tab} onClick={()=>setOpen(true)} onChange={(_,tab)=>changeTab(tab)} >
            <Tab label={<GTabLabel>Item One</GTabLabel>}/>
            <Tab label={<GTabLabel>Item Two</GTabLabel>} />
            <Tab label={<GTabLabel>Item Three</GTabLabel>} />
          </GOutlinedTabs>
          <Box sx={{ flexGrow: 1 }}></Box>
          <IconButton onClick={(e) => {e.stopPropagation(); setOpen(!open)}}>{open ? <ArrowDownward/> : <ArrowUpward/>}</IconButton>
        </Stack>
      </Stack>

      <CustomTabPanel tab={0} selected={tab}/>
      <CustomTabPanel tab={1} selected={tab}/>
      <CustomTabPanel tab={2} selected={tab}/> 

  </GInlineDrawer>
}
