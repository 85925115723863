import { DialogContent, Button, DialogContentText } from "@mui/material"
import { CluAction } from "./types"
import { GDialog, GDialogActions, GDialogTitle } from "@grenton/design-system"

export function ImportOptionsDialog(props:{
    cluId:string,
    open:boolean,
    onClose:()=>void,
    onExecuteAction:(action:CluAction)=>void,
}) {
    return <GDialog open={props.open} onClose={props.onClose}>
        <GDialogTitle onClose={props.onClose}>Adding CLU</GDialogTitle>
        <DialogContent>
        <DialogContentText>
            Do you also want to add all CLU modules to the project?
        </DialogContentText>
        </DialogContent>
        <GDialogActions 
            start={<Button onClick={()=>props.onExecuteAction({cluId:props.cluId, withModules: false})}>No</Button>} 
            end={<Button color="primary" onClick={()=>props.onExecuteAction({cluId:props.cluId, withModules: true})}>Yes</Button>}>
        </GDialogActions>
    </GDialog>
}
