import { ObjectEditForm } from '@grenton/gm-logic';
import { ValidatorResult } from '../../../../../ui/form-validation';
import { checkLuaName } from '@grenton/gm-logic';
import { ObjectPropertiesFormMeta } from '../types';

export function objectFormValidator(hasLabel: (label: string) => boolean) {
    // @ts-ignore
    return (form: ObjectEditForm, meta: ObjectPropertiesFormMeta) => {
        let result = ValidatorResult.validResult;

        if (form.disabled.label !== true) {
            const label = form.name.trim();
            if (label.length === 0) {
                result = result.withError('label', 'name is required');
            } else {
                const luacheck = checkLuaName(label);
                if (luacheck !== 'ok') {
                    result = result.withError(
                        'label',
                        luacheck === 'reserved' ? 'this name is reserved' : 'name is invalid (only letters, numbers and underscores are allowed)',
                    );
                } else if (hasLabel(label)) {
                    result = result.withError('label', 'entity with this name already exisis in the project');
                }
            }
        }
        return result;
    };
}
