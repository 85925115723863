import {SyntheticEvent, useMemo, useState} from "react";
import {KeyMap} from "@grenton/gm-common";
import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeItemType, ProjectTreeFilterResult, ProjectTreeFilterFunction, filterProjectTreeItems } from "../../../../../ui/components/projectComponentTree2";
import { ProjectComponentTree2, useExpandedItems } from "@grenton/gm/ui/components/projectComponentTree2";
import { mainObjectTreeModel } from "./mainObjectTreeModel";
import { mainObjectTreeRenderer } from "./mainObjectTreeRenderer";
import { ErrorBoundary } from "react-error-boundary";
import { ProjectImpl } from "@grenton/gm-logic";
import { ObjectMultiSelection } from "@grenton/gm/editor/utils";
import { parseTreeItemId } from "./utils/id";
import { useReferenceTracker } from "@grenton/gm/editor/ReferenceTrackerContext";
import { itemSelection } from "./itemSelection";

export type Props = {
    project: ProjectImpl;
    filter?: ProjectTreeFilterFunction<ProjectTreeItemData>;
    tagCategory: string;
    multiSelection: ObjectMultiSelection;
    onNodeClick: (node: ProjectTreeItem<ProjectTreeItemData>) => void;
    onNodeActionClick: (node: ProjectTreeItem<ProjectTreeItemData>, e: SyntheticEvent) => void;
    onObjectSelectionToggle: (itemId:string, selected:boolean) => void;
};



export function MainObjectTree({ project, filter, tagCategory, multiSelection, onNodeClick: onNodeClickFromProps, onNodeActionClick, onObjectSelectionToggle }: Props) {
    
    const [fullyExpandedItems, setFullyExpandedItems] = useState<KeyMap>({})
    const {expandedItems, toggleExpandedItem} = useExpandedItems()

    const {references, toggleTracking} = useReferenceTracker()

    console.log('object references', references)
    const onItemExpansionToggle = (itemId:string, expanded:boolean) => {
        // due to way we encode itemIds, the 2nd item is objectId for OBJECT item
        // TODO is this right for controllers? don't think so.
        const objectId = parseTreeItemId(itemId)[1]
        if (objectId) {
            toggleTracking(objectId, expanded)
        }
        toggleExpandedItem(itemId, expanded)
    }

    // this is called for any item expansion! be careful with performance
    const onItemSelectionToggle = (itemId:string, selected:boolean) => {
        if (!multiSelection.mode) return
        // due to way we encode itemIds, in multiselect mode, the last token is always the object id for OBJECT item
        const objectId = parseTreeItemId(itemId).pop()
        if (objectId) {
            onObjectSelectionToggle(objectId, selected)
        }
    }

    const onItemClick = (e:React.MouseEvent, item: ProjectTreeItem<ProjectTreeItemData>, data:any) => {
        if (data === 'action') {
            e.stopPropagation()
            return onNodeActionClick(item, e)
        }
        switch (item.data.type) {
            case ProjectTreeItemType.MORE:
                setFullyExpandedItems({...fullyExpandedItems, [item.id]: true})
                break
            case ProjectTreeItemType.LESS:
                setFullyExpandedItems({...fullyExpandedItems, [item.id]: false})
                break
            default:
                return onNodeClickFromProps(item)
        }
    }

    // it would be better to filter nodes 'on the fly', however filter is not filled by default so perf gain is not that big 
    const items = useMemo(()=> filterProjectTreeItems(mainObjectTreeModel({
        references,
        multiSelectMode: multiSelection.mode,
        fullyExpandedItems,
        project,
        tagCategory
    }), filter || (() => (ProjectTreeFilterResult.INCLUDE_WITH_CHILDREN))), 
        [references, multiSelection.mode ? multiSelection : null, fullyExpandedItems, project, tagCategory, filter])

    const selection = useMemo(()=>{
        return itemSelection(items, multiSelection)
    }, [items, multiSelection])

    return (
        <ErrorBoundary fallbackRender={(e)=><>{e.error}</>}>
        <ProjectComponentTree2
            itemRenderer={mainObjectTreeRenderer}
            items={items}
            expandedItems={expandedItems}
            onItemExpansionToggle={onItemExpansionToggle}
            selectionEnabled={multiSelection.mode}
            onItemClick={onItemClick}
            selectableItems={selection.selectable}
            selectedItems={selection.selected}
            onItemSelectionToggle={onItemSelectionToggle}
        />
        </ErrorBoundary>
    )
}
