import GMTextField from "../../../../../../ui/controls/GMTextField";

export function UserInfoField({label, value}: { label: string, value: string }) {
    return (
        <GMTextField
            size="small"
            value={value}
            label={label}
            variant="outlined"
            disabled
        />
    );
}