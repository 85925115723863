import { Typography } from "@mui/material"

export function Header(props: { children: React.ReactNode }) {
    return <Typography variant="l" paddingBottom={2} paddingTop={4}>{props.children}</Typography>
}

export function Subheader(props: { children: React.ReactNode }) {
    return <Typography variant="xs" paddingBottom={2} paddingTop={4}>{props.children}</Typography>
}

export const LOREM_IPSUM_P = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vehicula semper congue. Nulla in tellus ac mi tempus ultricies sit amet non elit. Phasellus pulvinar aliquet metus nec tincidunt. Aenean tempor velit nec dui venenatis vulputate. Proin ac sem sed metus tristique bibendum vitae ac velit"
export const LOREM_IPSUM_SHORT = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vehicula semper congue. "
