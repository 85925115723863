import { useState, useMemo } from "react";
import {
    Chip,
    DialogContent,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { Lists, Maps, ObjectSelector, OWNER_TAG_MASK, TAG_SEPARATOR, TAG_WILDCARD_SUFFIX } from "@grenton/gm-common";
import { OutletTypeSelector, OutletObjectSelector } from "./components";
import { GFieldset, GPseudoInput, GPseudoSelect, TagLabel } from "@grenton/design-system";
import { ProjectObjectImpl } from "@grenton/gm-logic";
import { parseIntOrUndefined } from './utils';
import type { ObjectOutletFormMeta } from './types';
import { fontSizedIcon } from "@grenton/gm/ui/icons";
import { objectIconResolver } from "@grenton/gm/ui/icon-resolver/objectIconResolver";
import { GSideDialog } from "@grenton/design-system/src/theme2/components/GSideDialog";
import { GDialogTitle } from "@grenton/design-system/src/theme2/components/GDialogTitle";
import { ObjectOutletForm } from "@grenton/gm-logic";
import { InlineEditPane } from "../../misc/InlineEditPane";
import { ValidatorResult } from "@grenton/gm/ui/form-validation";
import { SideDialogProtocolSelection } from "@grenton/gm/ui/components/SideDialogProtocolSelector";
import { FieldErrorMessages } from "@grenton/gm/ui/components/FieldErrorMessages";
import { SideDialogTagSelector } from "@grenton/gm/ui/components/SideDialogTagSelector";
import { WrapElements } from "../../misc/WrapElements";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { notEmpty } from "@grenton/utils";


type Props = {
    meta: ObjectOutletFormMeta;
    form: ObjectOutletForm;
    errors: ValidatorResult;
    onChange: (form: ObjectOutletForm) => void;
    onClose: () => void;
};

export function OutletEdit({ meta, form, onChange: onEdit, onClose, errors }: Props) {

    const [objectApiSelectorOpen, setObjectApiSelectorOpen] = useState(false)

    const applyChanges = (changes: Partial<ObjectOutletForm>) => {
        const updated = { ...form, ...changes }
        onEdit(updated)
    }

    // TODO it should also react to project changes!
    const selectedObjects = useMemo(() => meta.selectionResolver(form), [meta.selectionResolver, form])

    const allTags = useMemo(() => {
        let all = meta.project.tags
        all.categories.forEach(c => {
            all = all.withTag(c.name + TAG_SEPARATOR + OWNER_TAG_MASK).withTag(c.name + TAG_SEPARATOR + OWNER_TAG_MASK + TAG_WILDCARD_SUFFIX)

            c.values.all.forEach(tag => {
                all = all.withTag(c.name + TAG_SEPARATOR + tag + TAG_WILDCARD_SUFFIX)
            })
        })

        return all
    }, [form]);

    const allowedTypes = useMemo(() => form.apiRef ? meta.project.firmware.resolveAllowedTypes(form.apiRef) : [], [form.apiRef])
    const allowedApis = useMemo(() => form.apiRef ? meta.project.firmware.resolveAllowedApis(form.apiRef) : [], [form.apiRef])

    const onDeleteSelector = (sel: 'id' | 'tag' | 'type' | 'exclude', value: string) => {
        const selector = form.config.selector
        let updated: ObjectSelector = {}
        switch (sel) {
            case 'id':
                updated = { ...selector, ids: Lists.remove(selector.ids, value) }
                break;
            case 'tag':
                updated = { ...selector, tags: Lists.remove(selector.tags, value) }
                break;
            case 'type':
                updated = { ...selector, types: Lists.remove(selector.types, value) }
                break;
            case 'exclude':
                updated = { ...selector, types: Lists.remove(selector.exclude, value) }
                break;
        }
        applyChanges({ config: { ...form.config, selector: updated } })
    }


    // edit selectors
    const [outletSelectorType, setOutletSelectorType] = useState<'tags' | 'types' | 'ids' | null>(null)

    const closeOutletSelector = () => {
        setOutletSelectorType(null)
    }

    const iconResolver = objectIconResolver(meta.project.firmware)

    return (
        <InlineEditPane onClose={onClose}>
            <Stack sx={{ flexDirection: 'row', flexGrow: 1, gap: 2, width: '100%', height:'100%' }}>
                <Stack sx={{ flexDirection: 'column', flexGrow: 2, gap: 2 }}>
                    <TextField
                        error={!errors.field('label')}
                        disabled={!form.editable}
                        helperText={<FieldErrorMessages errors={errors.field('label')} />}
                        value={form.spec.label}
                        label="Name"
                        required
                        onChange={(e) => applyChanges({ spec: { ...form.spec, label: e.target.value } })} />

                    <TextField
                        fullWidth
                        error={!errors.field('description')}
                        helperText={<FieldErrorMessages errors={errors.field('description')} />}
                        value={form.spec.description || ""}
                        label="Description"
                        onChange={(e) => applyChanges({ spec: { ...form.spec, description: e.target.value } })} />

                    <Stack sx={{ flexDirection: 'row', gap: 1, justifyContent: 'space-between' }}>
              
                        <GPseudoSelect
                            label="Protocol"
                            required
                            disabled={!form.editable} 
                            value={form.apiRef ?? ''} 
                            sx = {{ maxWidth: 350, flexGrow: 1 }}
                            onClick={()=> setObjectApiSelectorOpen(true)}/>

                        <TextField
                            sx={{ width: 100 }}
                            error={!errors.field('maxItems')}
                            disabled={!form.editable}
                            type="number"
                            helperText={<FieldErrorMessages errors={errors.field('maxItems')} />}
                            value={form.spec.maxItems || ''} 
                            label="Max items"
                            onChange={(e) => applyChanges({ spec: { ...form.spec, maxItems: parseIntOrUndefined(e.target.value) } })} />
                    </Stack>

                    <Typography variant="m">Selection filter</Typography>

                    <GPseudoInput 
                        disabled={!form.config.editable || !form.apiRef} 
                        placeholder="Tags" 
                        onClick={_ => setOutletSelectorType('tags')}>
                        {(() => {
                            const tags = form.config.selector.tags || []
                            return tags.length ? <WrapElements>{tags.map(tag => {
                                return <Stack key={tag} direction="row" sx={{ alignItems: 'center' }}>
                                    <TagLabel
                                        label={tag}
                                        color={meta.project.tags.getColor(tag.split(':')[0])}
                                    />

                                </Stack>
                            })}</WrapElements> : null
                        })()
                        }
                    </GPseudoInput>

                    <GPseudoInput 
                            disabled={!form.config.editable || !form.apiRef}
                            placeholder="Functional type"
                            onClick={_ => setOutletSelectorType('types')}>
                        {
                            (() => {
                                const types = form.config.selector.types || []
                                return types.length ? <WrapElements>{types.map(type => (<Chip key={type} sx={{
                                    margin: '1px',
                                    background: '#aaa',
                                    color: '#fff',
                                    borderRadius: 1
                                }} size="small" label={`${type}`}
                                    onDelete={() => onDeleteSelector('type', type)} />))}</WrapElements>
                                    : null
                            })()
                        }
                    </GPseudoInput>

                    <GPseudoInput 
                            disabled={!form.config.editable || !form.apiRef} 
                            placeholder="Objects" 
                            onClick={_ => setOutletSelectorType('ids')}>
                        {
                            (() => {
                                const objects = (form.config.selector.ids || [])
                                    .map(id => meta.project.getObjectById(id)).filter((obj): obj is ProjectObjectImpl => !!obj)
                                return objects.length ? <WrapElements>{objects.map(obj => (<Chip key={obj.uuid} sx={{
                                    margin: '1px',
                                    background: '#aaa',
                                    color: '#fff',
                                    borderRadius: 1
                                }} size="small" label={obj.label}
                                    onDelete={() => onDeleteSelector('id', obj.uuid)} />))}</WrapElements>
                                    : null
                            })()
                        }
                    </GPseudoInput>
                </Stack>
                <Stack sx={{gap:1, minWidth: 300}}>
                <GFieldset styles={{ width:'100%', overflow: 'hidden', height:'100%', marginTop:-5 }} label="Selected">
                    <List dense={true} 
                        disablePadding={true}
                        sx={{ overflow: 'auto', height: '100%', width: '100%', padding: 0 }}>
                        {selectedObjects.map((obj, i) => (
                            <ListItem key={obj.uuid} alignItems="center" 
                                sx={ {px:0.5, opacity: i < (form.spec.maxItems || 10000) ? 1 : 0.3 }}
                                secondaryAction={
                                    <IconButton size="small" edge="end" aria-label="delete" onClick={()=>applyChanges({
                                        config: {
                                            ...form.config,
                                            selector: {
                                                ...form.config.selector,
                                                exclude: Lists.toggle(form.config.selector.exclude||[],obj.uuid,true)
                                            }
                                        }
                                    })}>
                                      <RemoveCircleOutline />
                                    </IconButton>
                                  }
                                
                                >
                                <ListItemIcon sx={{ margin: 0, padding: 0, minWidth: 20 }}>
                                    {fontSizedIcon(iconResolver(obj))}
                                </ListItemIcon>
                                <ListItemText
                                    primary={obj.label}
                                />
                            </ListItem>
                        ))}
                    </List>
                </GFieldset>
                <GFieldset label="Excluded" styles={{width:'100%', maxHeight:'25%'}}>
                <List dense={true} 
                        disablePadding={true}
                        sx={{ overflow: 'auto', height: '100%', width: '100%', padding: 0 }}>
                        {form.config.selector.exclude?.map(id=>(meta.objectResolver(id))).filter(notEmpty).map(obj => (
                            <ListItem key={obj.uuid} alignItems="center" 
                                sx={ {px:0.5} }
                                secondaryAction={
                                    <IconButton size="small" edge="end" aria-label="delete" onClick={
                                        ()=>applyChanges({
                                            config: {
                                                ...form.config,
                                                selector: {
                                                    ...form.config.selector,
                                                    exclude: Lists.toggle(form.config.selector.exclude||[],obj.uuid,false)
                                                }
                                            }
                                        })}>
                                      <AddCircleOutline />
                                    </IconButton>
                                  }
                                
                                >
                                <ListItemIcon sx={{ margin: 0, padding: 0, minWidth: 20 }}>
                                    {fontSizedIcon(iconResolver(obj))}
                                </ListItemIcon>
                                <ListItemText
                                    primary={obj.label}
                                />
                            </ListItem>
                        ))}
                        </List>
                </GFieldset>
                </Stack>
            </Stack>

            <SideDialogProtocolSelection
                title="Outlet protocol"
                selected={form.apiRef}
                protocols={meta.types}
                open={objectApiSelectorOpen}
                onClose={() => setObjectApiSelectorOpen(false)}
                onSelect={(protocol) => { applyChanges({ apiRef: protocol }); setObjectApiSelectorOpen(false) }} />

            <SideDialogTagSelector
                open={outletSelectorType === 'tags'}
                onClose={closeOutletSelector}
                tags={allTags.export()}
                selected={form.config.selector.tags??[]}
                onChange={tags => applyChanges({
                    config: {
                        ...form.config,
                        selector: {
                            ...form.config.selector,
                            tags
                        }
                    }
                })}
            />

            <GSideDialog open={outletSelectorType === 'types'} onClose={closeOutletSelector}>
                <GDialogTitle onClose={closeOutletSelector}>Outlet functional types</GDialogTitle>
                <DialogContent>
                    <OutletTypeSelector allowedTypes={allowedTypes}
                        allowedApis={allowedApis}
                        // these selectors should accept/return arrays !
                        selected={Maps.asKeyMap(form.config.selector?.types)}
                        onChange={types => applyChanges({
                            config: {
                                ...form.config,
                                selector: {
                                    ...form.config.selector,
                                    types: Maps.reduceKeyMap(types)
                                }
                            }
                        })} />
                </DialogContent>
            </GSideDialog>

            <GSideDialog open={outletSelectorType === 'ids'} width='50vw' onClose={closeOutletSelector}>
                <GDialogTitle onClose={closeOutletSelector}>Outlet Objects</GDialogTitle>
                <DialogContent>
                    <OutletObjectSelector
                        allowedApis={allowedApis}
                        project={meta.project}
                        ids={Maps.asKeyMap(form.config.selector?.ids)}
                        onItemSelectionToggle={(objectId,selected) => {
                            applyChanges({
                                config: {
                                    ...form.config,
                                    selector: {
                                        ...form.config.selector,
                                        ids: Lists.toggle(form.config.selector?.ids||[],objectId,selected)
                                    }
                                }
                            })
                        }}
                    />
                </DialogContent>
            </GSideDialog>
        </InlineEditPane>
    )
}


