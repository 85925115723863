import { IdMap, Maps } from '@grenton/gm-common';

export type ObjectMultiSelectionType = {
    mode: boolean;
    selected: string[];
};

export class ObjectMultiSelection implements ObjectMultiSelectionType {
    constructor(
        readonly mode: boolean,

        // non-empty value means that object is selectable
        // true means it is selected
        readonly objects: IdMap<boolean>,
    ) {}

    toggleMode() {
        return this.withMode(!this.mode);
    }

    withMode(mode: boolean) {
        return mode == this.mode ? this : new ObjectMultiSelection(mode, {});
    }

    withObjects(nodes: IdMap<boolean>) {
        return new ObjectMultiSelection(this.mode, nodes);
    }

    get empty() {
        return !Boolean(Maps.values(this.objects).find((val) => val === true));
    }

    get selected() {
        return this.mode
            ? Object.entries(this.objects)
                  .filter(([_, status]) => status === true)
                  .map(([objectId]) => objectId)
            : [];
    }
}
