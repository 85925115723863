import { Dialog, DialogContent, Stack, DialogActions, Button, Alert, Box } from "@mui/material";
import { useObservable } from "../../../../utils";
import { useEffect } from "react";
import { notEmpty } from "@grenton/gm-common";
import { useService } from "@grenton/gm/providers";

export function ExportViaRESTDialog(props: { open: boolean, onClose: () => void, onApply:()=>void }) {

    const {hardwareController, configurationUploader} = useService()
    const [cluster] = useObservable(hardwareController.editedCluster)
    const [pushConfigurationStatus] = useObservable(configurationUploader.pushConfigurationStatus)

    useEffect(()=>{
        configurationUploader.resetPushConfigurationStatus()
    },[props.open])

    return <Dialog open={props.open} onClose={props.onClose}>
        { cluster?.health.info.map((info, idx) => <Alert key={idx} severity={info.severity}>{info.message}</Alert>)}

        { pushConfigurationStatus?.status === 'error' && <Alert severity={'error'}>
                    Upload failed due to following errors:
                    {Object.entries(pushConfigurationStatus?.clus).filter(clu=>clu[1].status==='error').map(clu=>clu[1].message).filter(notEmpty).join('; ')}</Alert> 
                }

        { pushConfigurationStatus?.status === 'ok' && <Alert severity={'success'}>
                    Upload completed successfully</Alert>
                }

        { pushConfigurationStatus?.status === 'pending' && <Alert severity={'info'}>
                    Upload in progress...</Alert>
                }

        { cluster && <DialogContent>
            Configuration 
            {pushConfigurationStatus?.status !== 'ok' ? ' will be ' : ' was '} 
            uploaded to following CLUs
            <Stack p={1} spacing={1}>
                {
                     cluster.cluster.configuration.clus.map((clu, i) => 
                        <Stack key={i} direction={"row"} alignItems={"center"}>
                        <Box sx={{marginRight:1,fontWeight:'bold', fontSize:'2rem',color: clu.online  ? 'green' : 'red'}} >{'\u2022'}</Box>
                        {clu.id}
                        </Stack>)
                }
            </Stack>
        </DialogContent> }
        <DialogActions>
            <Button disabled={!cluster || !cluster.health.pushReady || pushConfigurationStatus?.status === 'pending' || pushConfigurationStatus?.status === 'ok'} onClick={props.onApply}>Push</Button>
            <Button disabled={!pushConfigurationStatus || pushConfigurationStatus.status !== 'ok'} onClick={props.onClose}>OK</Button>
        </DialogActions>
    </Dialog>
}
