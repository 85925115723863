import { GIconName } from '@grenton/gm/ui/icons';
import { ObjectResolver, ProjectObjectImpl } from '@grenton/gm-logic';
import { objectNode } from './objectNode';

export function objectNodes(
    parentId: string,
    path: string[],
    objects: ProjectObjectImpl[],
    actionsMode: boolean,
    iconResolver: (object: ProjectObjectImpl) => GIconName | null,
    objectResolver: ObjectResolver,
    tag?: string,
) {
    return objects.map((o) => objectNode(parentId, [...path, o.uuid], o, actionsMode, iconResolver, objectResolver, tag));
}
